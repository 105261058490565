import { baseAPI } from "@/plugins/axios";
import {
  defineComponent,
  onMounted,
  ref,
  toRaw,
  reactive,
  computed,
} from "vue";
import { plainToClass } from "class-transformer";
import vselect from "../../components/v-select/index.vue";
import AutoComplete from "@/components/AutoComplete.vue";
import action from "../../components/base/action/index.vue";
import StoreAutocomplete from "../../components/shared/store/autocomplete/index.vue";
import { Helpers } from "@/helper";
import { config } from "../../plugins/config";
import { StoreStatus, UserRoleWithOwner } from "@/globals/enums";
import {
  StoreEntity,
  StoreFilterEntity,
  storeservice,
  PaginationEntity,
  StoreUserEntity,
  userservice,
  UserFilterEntity,
  CategoryEntity,
  categoryservice,
  CategoryFilterEntity,
} from "@/utils";
import { store } from "@/store";
import { Menu, MenuItems, MenuButton } from "@headlessui/vue";
import PaginationView from "@/components/shared/pagination/PaginationView.vue";
import Loader from "@/components/shared/loading/Loader.vue";
import { getSignedUrl } from "@/services/shared/image.service";
export default defineComponent({
  name: "StoreView",
  components: {
    vselect,
    action,
    StoreAutocomplete,
    AutoComplete,
    Menu,
    MenuItems,
    MenuButton,
    PaginationView,
    Loader,
  },
  data: () => {
    return {
      Pagination: {} as PaginationEntity,
      SaveStore: new StoreEntity() as any,
      stores: [] as Array<StoreEntity>,
      SaveStoreMode: false as any,
      helpers: Helpers,
      configMedia: config,
      isSelected: false as any,
      totalHeight: null as any,
      // Stores: [] as Array<StoreEntity>,
      // StoreFilter: StoreFilterEntity,
      selectedStoreFilter: "" as any,
      selectedCategoryFilter: "" as any,
      selectedOwnerFilter: "" as any,
      selectedWorkingFilter: "" as any,
      selectedStatusStoreFilter: "" as any,
      selectedPendingFilter: "" as any,
      mediaUrl: process.env.VUE_APP_API_URL,
    };
  },
  props: {
    checked: {
      type: Boolean,
    },
    Id: {
      type: String,
    },
  },
  setup(props) {
    const showActionTab = ref();
    const datas = ref([]);
    const query = [] as any;
    const Stores = ref() as any;
    const Categories = ref() as any;
    const Owners = ref() as any;
    const stores = ref();
    const StoreFilter = new StoreFilterEntity(query);
    const statusQuery = ref("");
    const selectedStatus = ref<any[]>([]);
    const storeDetails = ref<any>();

    const loading = ref(false);

    const IsActive = ref();
    (async () => {
      const [{ data: storeData }, { data: categories }, { data: owner }] =
        await Promise.all([
          storeservice.getAll(StoreFilter),
          categoryservice.getAll(new CategoryFilterEntity({ Limit: 100000 })),
          userservice.getAll(
            new UserFilterEntity({
              Limit: 100000,
              Role: UserRoleWithOwner.OWNER,
            })
          ),
        ]);
      console.log("data in setup", storeData?.data);
      console.log("categories in setup", categories.data);
      console.log("owner in setup", owner.data);
      Stores.value = storeData?.data;
      Stores.value.forEach(async (store: StoreEntity) => {
        if (store.logoImage !== undefined || store.logoImage !== null) {
          store.logoImage = await getSignedUrl(store.logoImage || null);
        }

        if (store.coverImage?.length !== 0) {
          store.coverImage?.map(async (image: any) => {
            image = await getSignedUrl(image);
          });
        }
      });
      Categories.value = categories.Data;
      Owners.value = owner?.data
        ?.filter((data) => data !== null)
        .map((data: any) => {
          return { ...data, name: data.firstName + " " + data.lastName };
        });

      store.state.stores = [
        ...storeData?.data,
        { categories: categories.Data, owners: owner?.data },
      ];
    })();

    stores.value = datas;
    const StoreStatuses = computed(() => {
      return Helpers.enumToItems(StoreStatus);
    });

    const fetchImages = () => {
      console.log("fetchImages");
    };

    const filterStores = async (filter: any, type: string) => {
      console.log("filter", filter, "type", type);
      const filteredStores = await storeservice.getAll();
      console.log("filteredStores", filteredStores);
      Stores.value = filteredStores.data.data.filter((store: any) => {
        return (
          store?.name?.toLowerCase().includes(filter.name.toLowerCase()) ||
          store?.category?.name
            .toLowerCase()
            .includes(filter?.name?.toLowerCase()) ||
          // store.owner.firstName
          //   .toLowerCase()
          //   .includes(filter?.firstName?.toLowerCase()) ||
          // store.owner.lastName
          //   .toLowerCase()
          //   .includes(filter?.lastName?.toLowerCase()) ||
          store?.status?.toLowerCase().includes(filter?.value?.toLowerCase()) ||
          (store?.status?.toLowerCase() !== "disabled"
            ? "not working"
            : "working") === filter?.value?.toLowerCase() ||
          store?.pending?.toLowerCase().includes(filter?.value?.toLowerCase())
        );
      });
    };

    const showAction = () => {
      showActionTab.value = !showActionTab.value;
    };
    const filteredStatus: any = computed(() => {
      return statusQuery.value === ""
        ? StoreStatuses.value
        : StoreStatuses.value.filter((status: any) => {
            return status.Value.toLowerCase()
              .replace(/-|\s+/g, "")
              .includes(statusQuery.value.toLowerCase().replace(/-|\s+/g, ""));
          });
    });

    const fetchAllCategories = async () => {
      loading.value = true;
      await categoryservice
        .getAll(new CategoryFilterEntity({ Limit: 100000 }))
        .then((res) => {
          Categories.value = res.data.data;
          console.log("Categories", Categories.value);
        });
      loading.value = false;
    };

    const closeStatusOption = (id: number) => {
      selectedStatus.value[id] = { Id: null, Value: "" };
      statusQuery.value = "";
    };
    return {
      Stores,
      storeDetails,
      filteredStatus,
      statusQuery,
      selectedStatus,
      StoreFilter,
      query,
      IsActive,
      StoreStatuses,
      showActionTab,
      Categories,
      Owners,
      loading,
      closeStatusOption,
      showAction,
      fetchAllCategories,
      filterStores,
    };
  },
  beforeMount() {
    const res = this.getStores(this.StoreFilter);
    res.then((result: any) => {
      this.Pagination.TotalItems = result.Total as any;
      this.Pagination.CurrentPage = this.query.Page ? +this.query.Page : 1;
      this.stores = result.data;
      console.log("Result data", result, this.stores);
      this.stores?.forEach((store: StoreEntity, idx: number) => {
        this.getOwners(store, idx);
      });
    });

    this.Pagination = plainToClass(PaginationEntity, this.Pagination);
  },
  async mounted() {
    // await this.getStores();
    this.getUser();
    this.fetchAllCategories();
  },
  methods: {
    async getStores(filter?: StoreFilterEntity) {
      this.loading = true;
      const response = await storeservice.getAll(new StoreFilterEntity(filter));
      this.stores = response.data.data;
      this.loading = false;

      // join store by id
      console.log("stores Response", response.data.data);
      this.selectedStatus = this.stores.map((str: StoreEntity) => {
        return { id: str.status, value: str.status };
      });

      return response.data;
    },
    async getOwners(store: StoreEntity, idx: number) {
      const storeOwners = ref([]) as any;
      console.log("Index of store", idx);
      console.log("The store", store);
      // this.stores.forEach((store, index) => {
      store.member?.forEach((member) => {
        console.log("Not Owner", member);

        if (
          member.role?.toLowerCase() === "owner" &&
          member.storeId === store.id
        ) {
          console.log("Owner", member);
          storeOwners?.value.push(member);
          this.Stores[idx] = {
            ...this.Stores[idx],
            storeOwners: storeOwners.value,
          };
        }
      });
      // });
      console.log("Store owners", this.Stores);
    },
    async getUser() {
      this.loading = true;
      const response: any = await userservice.getAll(
        new UserFilterEntity({
          Limit: 10000,
          Role: UserRoleWithOwner.CUSTOMER,
        })
      );
      this.loading = false;
      console.log("get user Response", response.data.data);
      // join user by id

      this.Owners = response.data.data;
      return response.data;
    },
    updateData(value: any, type: any) {
      this.StoreFilter.Page = this.Pagination.CurrentPage;
      if (type === "store") {
        this.StoreFilter.Name = this.selectedStoreFilter?.Name || null;
      } else if (type === "category") {
        this.StoreFilter.CategoryId = this.selectedCategoryFilter?.Id || null;
      } else if (type === "owner") {
        this.StoreFilter.StoreUserId = this.selectedOwnerFilter?.Id || null;
      } else if (type === "working") {
        this.StoreFilter.IsActive = this.selectedWorkingFilter?.Id || null;
      } else if (type === "pending") {
        this.StoreFilter.HasPendingItems =
          this.selectedPendingFilter?.Id || null;
      } else if (type === "status") {
        this.StoreFilter.Status = this.selectedStatusStoreFilter?.Id || null;
      }
      this.$router.replace({
        query: this.helpers.filterToQueryObj(
          this.Pagination as any,
          this.StoreFilter
        ),
      });
      this.getStores(this.StoreFilter);
    },
    async updateStatus(
      index: string,
      status: { id: string; value: string },
      val: any
    ) {
      try {
        console.log("Status update", status, " value ", val, " index ", index);
        const res = await baseAPI.put(`/admin/store/status/${index}`, {
          status: val.id,
        });

        const id = Date.now();
        this.$notify({
          id,
          type: "success",
          duration: 3000,
          text: "updated",
        });
      } catch (err: any) {
        console.log("Error updating status", err, val);
        const id = Date.now();
        err?.response?.data?.data.forEach((err: any) => {
          this.$notify({
            id,
            type: "error",
            duration: 3000,
            text: err.msg,
          });
        });
      }
    },
    async updateWorking(index: string, IsActive: boolean) {
      console.log("update Store status", this.Stores);
      const idx = this.Stores.findIndex(({ id }: any) => id === index);
      console.log("Found store", idx);
      try {
        await baseAPI.put(`/admin/store/status/${index}`, { status: IsActive });
        if (idx > -1) {
          this.Stores[idx].IsActive = IsActive;
        }
        const id = Date.now();
        this.$notify({
          id,
          type: "success",
          duration: 2000,
          text: "updated",
        });
      } catch (err) {
        console.log("Error updating status", err);
        const id = Date.now();
        this.$notify({
          id,
          type: "error",
          duration: 2000,
          text: err as any,
        });
      }
    },
    async updateAutoOpenClose(index: string, AutoOpenClose: boolean) {
      const idx = this.Stores.findIndex(({ Id }: any) => Id === index);
      try {
        // await baseAPI.patch(`/store/${index}/update-auto-open-close`, {
        //   AutoOpenClose,
        // });
        await storeservice.updateStore({ autoOpenClose: AutoOpenClose }, index);
        if (idx > -1) {
          this.Stores[idx].AutoOpenClose = AutoOpenClose;
        }
        const id = Date.now();
        this.$notify({
          id,
          type: "success",
          duration: 2000,
          text: "updated",
        });
      } catch (err) {
        console.log("Error updating autoopenclose", err);
        const id = Date.now();
        this.$notify({
          id,
          type: "error",
          duration: 2000,
          text: err as any,
        });
      }
    },
    toggleSelect() {
      this.isSelected = !this.isSelected;
    },
    close(e: any) {
      if (!this.$el.contains(e.target)) {
        this.showActionTab = false;
      }
    },
    async editStore(index: string) {
      try {
        this.SaveStore = await baseAPI.get<StoreEntity>(`/store/${index}`);
        this.SaveStore.StoreCategories = this.SaveStore.StoreCategories.map(
          ({ CategoryId }: any) => CategoryId
        );
        this.SaveStoreMode = true;
        const id = Date.now();
        this.$notify({
          id,
          type: "success",
          duration: 3000,
          text: "successfuly edited",
        });
      } catch (err) {
        const id = Date.now();
        this.$notify({
          id,
          type: "error",
          duration: 3000,
          text: err as any,
        });
      }
    },
  },
});
