import { IdValue } from "./types";

export const COUNTRIES: Array<IdValue> = [
  {
    id: "kosovo",
    value: "Kosovë",
    extra: {
      isDefault: false,
    },
  },
  {
    id: "ethiopia",
    value: "Ethiopia",
    extra: {
      isDefault: true,
    },
  },
  {
    id: "afghanistan",
    value: "Afghanistan",
    extra: {
      isDefault: false,
    },
  },
];
