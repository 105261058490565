
import { defineComponent } from "vue";
import tokenService from "@/plugins/token.service";
import { AuthService } from "@/utils/auth/auth.service";

const authservice = new AuthService();

export default defineComponent({
  name: "MobileMenu",
  methods: {
    hide() {
      this.$emit("closeMobileMenu");
    },
    async logout() {
      try {
        const respons = await authservice.logout();
        tokenService.removeUser();
        this.$router.push({ name: "login" });
      } catch (e: any) {
        const id = Date.now();
        this.$notify({
          id,
          type: "error",
          duration: 10000,
          text: e.response.data.message,
        });
      }
    },
  },
  // mounted () {
  //     document.addEventListener("click", this.hide);
  // }
});
