import { baseAPI } from '@/plugins';
import { PaginationResEntity } from '../shared';
import { TableFilterEntity } from './table-filter.entity';
import { TableEntity } from './table.entity';
import { Helpers } from "@/helper";

class TableApi {
    getAll (storeId: string, filter?: TableFilterEntity) {
        return baseAPI.get<PaginationResEntity<TableEntity>>(
            `/table/${storeId}?${Helpers.filterToQueryString<TableEntity, TableFilterEntity>(filter)}`
        )
    }

    getById (id: string) {
        return baseAPI.get(
            `/table/${id}`
        )
    }
    add (table: TableEntity) {
        return baseAPI.post(
            '/table',
            table
        )
    }
    update (table: TableEntity) {
        return baseAPI.put(
            '/table',
            table
        )
    }
    delete (id: string) {
        return baseAPI.delete(
            `/table/${id}`
        )
    }
}

export const tableservice = new TableApi()