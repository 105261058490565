import { defineComponent, ref } from "vue";
import { baseAPI } from "@/plugins";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { store } from "@/store";
import { storeservice } from "@/utils";
import AutoComplete from "@/components/AutoComplete.vue";
export default defineComponent({
  name: "action",
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    TransitionRoot,
    AutoComplete,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
  },
  data: () => {
    return {
      totalHeight: null as any,
      deleteModal: false,
      editModal: false,
      selectedRole: "" as any,
    };
  },
  props: {
    index: {
      type: String,
    },
    Id: {
      type: String,
    },
    data: { type: Object },
    member: { type: Array },
  },
  setup(props) {
    const Store = ref() as any;
    const showActionTab = ref();
    const showAction = () => {
      showActionTab.value = !showActionTab.value;
    };
    const roles: Array<any> = [
      { id: "owner", Value: "owner" },
      { id: "manager", Value: "manager" },
      { id: "driver", Value: "driver" },
      { id: "cook", Value: "cook" },
      { id: "sales", Value: "sales" },
      { id: "waiter", Value: "waiter" },
    ];

    return {
      showAction,
      showActionTab,
      Store,
      roles,
    };
  },
  mounted() {
    this.selectedRole = { Id: this.data?.Role, Value: this.data?.Role };
    this.getStore(this.Id as any);
  },
  methods: {
    close(e: any) {
      if (!this.$el.contains(e.target)) {
        this.showActionTab = false;
      }
    },
    async updateStore() {
      try {
        await baseAPI.patch(
          `/admin/store/member/update/${this.$route.params.id}`,
          {
            id: this.Id,
            email: this.data?.email,
            storeId: this.$route.params.id,
            role: this.selectedRole.Id,
          }
        );
        const id = Date.now();
        this.$notify({
          id,
          type: "success",
          duration: 3000,
          text: "Updated",
        });
        this.editModal = false;
        this.$emit("updated", { Id: this.Id, Role: this.selectedRole.Value });
      } catch (error) {
        console.log(error);
      }
    },
    async deleteStore(index: string) {
      console.log("Delete confirmed", index);
      try {
        const res = await baseAPI.delete(`/admin/store/delete/${index}`);
        console.log("Delete", index, "response", res);
        const id = Date.now();
        this.$notify({
          id,
          type: "success",
          duration: 3000,
          text: res.data?.message,
        });
        this.deleteModal = false;
        this.$emit("deleted", index);
      } catch (e) {
        const id = Date.now();
        this.$notify({
          id,
          type: "errorr",
          duration: 3000,
          text: e as any,
        });
      }
    },
    async getStore(id: string) {
      const respons = await storeservice.getAll();
      this.Store = respons.data;
      store.state._store = this.Store;
      return this.Store;
    },
  },
});
