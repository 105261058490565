import { AnyObject } from '../../helper/types';
import { BaseEntity } from '../base.entity';

export class FilterEntity<T = AnyObject> extends BaseEntity {
  Page = 1;
  Limit = 25;
  OrderBy!: keyof T;
  OrderDir: 'ASC' | 'DESC' = 'ASC';

  constructor(data?: Partial<FilterEntity<T>>) {
    super();
    Object.assign(this, data);
  }
}
