import { StoreStatus, StoreUserRole } from "@/globals/enums";
import { UserEntity } from "../user";
import { StoreEntity } from "./store.entity";

export class StoreUserEntity {
  id: string | null = null;
  role: StoreUserRole | null = null;
  status?: StoreStatus;
  storeId: string | null = null;
  userId: string | null = null;
  store = new StoreEntity();
  user = new UserEntity();

  constructor(data?: Partial<StoreUserEntity>) {
    Object.assign(this, data);
  }
}
