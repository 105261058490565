import { Component, Prop, Vue } from 'vue-property-decorator';
import {defineComponent, computed, PropType} from 'vue'

export default defineComponent({
    inheritAttrs: false,
    data: () => {
        return {
            //
        }
    },
    props: {
        rules: {
            type: String as PropType<string>,
            default: ''
        },
        vid: {
            type: String as PropType<string>
        },
        value: {
            type: String,
            defult: null
        },
        large: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        color: {
            type: String as PropType<string>,
            default: 'accent'
        },
        margin: {
            type: String as PropType<string>,
            default: 'mb-5'
        },
        center: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        noClear: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        noStar: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        cols: {
            type: String as PropType<string>,
            default: '3 9'
        },
        mobileCols: {
            type: String as PropType<string>,
            default: '12 12'
        },
    },

    setup(props) {
        const LabelCol = computed(() => {
            return +props.cols.split(' ')[0]
        })

        const FieldCol = computed(() => {
            return +props.cols.split(' ')[1]
        })

        const MobileLabelCol = computed(() => {
            return +props.mobileCols.split(' ')[0]
        })

        const MobileFieldCol = computed(() => {
            return +props.mobileCols.split(' ')[1]
        })

        return {
            LabelCol,
            FieldCol,
            MobileLabelCol,
            MobileFieldCol
        }
    }
})
