import { DeliveryType, OrderStatus } from '@/globals/enums';
import {
  TableEntity,
  StoreEntity,
  OrderItemEntity,

} from '../index'
import { UserEntity } from '..';

export class OrderEntity {
  Id!: string;
  UniqueId!: string;
  CustomerId!: string;
  Customer = new UserEntity();
  StoreId!: string;
  Store = new StoreEntity();
  PlacedAt = new Date();
  AcceptedAt!: Date;
  PreparedAt!: Date;
  OnTheWayAt!: Date;
  DeliveredAt = new Date();
  Status!: OrderStatus;
  DeliveryType!: DeliveryType;
  CancelReason!: string;
  DeliveryCost!: number;
  DriverId!: string;
  Driver = new UserEntity();
  CookId!: string;
  Cook = new UserEntity();
  CouponId!: string;
  TotalWithDeliveryCost = 0;
  DiscountedAmount = 0;
  DiscountedTotal = 0;
  Items: Array<OrderItemEntity> = [];
  CreatedAt!: string;
  Count!: number;
  MonthCount!: number;
  YearCount!: number;
  Total!: number;
  MonthTotal!: number;
  YearTotal!: number;
  Comment?: string;
  PickupTime?: Date;
  DeliveryTime?: Date;
  TotalSales: number | null = null;
  StoreOwes: number | null = null;
  TederaOwes: number | null = null;
  Table: TableEntity | null = null;
  TableId: string | null = null;

  constructor(data?: Partial<OrderEntity>) {
    Object.assign(this, data);
  }
}
