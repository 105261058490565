import { dashboard } from './dashboard';
import { password } from './password';
import shared from './shared';
import { user } from './user';

export default {
  ...shared,
  'i-dashboard': dashboard,
  'i-password': password,
  'i-user': user
};
