export const dashboard = {
    'view-all': 'Shiko te gjitha',
    month: 'MUAJ',
    year: 'VITI',
    this: {
      month: 'KËTË MUAJ',
      year: 'KETE VIT'
    },
    'all-time': 'GJITHE KOHES',
    'open-orders': 'Porositë e hapura',
    'no-orders': 'Nuk ka porosi të hapura',
    charts: 'DISA GRATE'
  };
  