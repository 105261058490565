import { singleton } from '@/helper';

export type Success = {
  open(title: string, msg: string): void;
};

@singleton
export class SuccessService implements Success {
  private _Mode = false;
  Message = '';
  Title = '';

  get Mode() {
    return this._Mode;
  }

  open(title: string, msg: string) {
    this.Title = title;
    this.Message = msg;
    this._Mode = true;
  }

  close() {
    this._Mode = false;
  }
}
