export const dashboard = {
    'view-all': 'View All',
    month: 'MONTH',
    year: 'YEAR',
    'all-time': 'ALL TIME',
    this: {
      month: 'THIS MONTH',
      year: 'THIS YEAR'
    },
    'open-orders': 'Open Orders',
    'no-orders': 'No open orders',
    charts: 'SOME CHARTS'
  };