export enum UserRole {
  ADMIN = "admin",
  CUSTOMER = "customer",
  INFLUENCER = "influencer",
}

export enum UserRoleWithOwner {
  ADMIN = "admin",
  CUSTOMER = "customer",
  INFLUENCER = "influencer",
  OWNER = "owner",
}

export enum StoreUserRole {
  OWNER = "owner",
  MANAGER = "manager",
  DRIVER = "driver",
  // COOK = 'cook',
  SALES = "sales",
  WAITER = "waiter",
}

export enum StoreUserRoleWithCustomer {
  OWNER = "owner",
  MANAGER = "manager",
  DRIVER = "driver",
  COOK = "cook",
  CUSTOMER = "customer",
}

export enum NotiStatus {
  SENT = "sent",
  PENDING = "pending",
}

export enum CategoryStatus {
  ACTIVE = "active",
  DISABLED = "disabled",
}

export enum NotiActionType {
  URL = "url",
  INTERNAL = "internal",
}

export enum StoreStatus {
  VERIFIED = "verified",
  PENDING = "pending",
  DISABLED = "disabled",
}

export enum OrderStatus {
  PLACED = "placed",
  ACCEPTED = "accepted",
  PREPARED = "prepared",
  ON_THE_WAY = "on-the-way",
  DELIVERED = "delivered",
  CANCELED = "canceled",
  DECLINED = "declined",
  CANCELED_AUTOMATICALLY = "canceled-automatically",
}

export enum Ingredients {
  CHICKEN = "chicken",
  BEEF = "beef",
  PORK = "pork",
  FISH = "fish",
  VEGETABLES = "vegetables",
  FRUITS = "fruits",
  DAIRY = "dairy",
  EGGS = "eggs",
  NUTS = "nuts",
  GLUTEN = "gluten",
  SOY = "soy",
  SHELLFISH = "shellfish",
  SULFITES = "sulfites",
  PEANUTS = "peanuts",
  SESAME_SEEDS = "sesame-seeds",
  MUSTARD = "mustard",
  LUPIN = "lupin",
  MOLLUSCS = "molluscs",
  CRUSTACEANS = "crustaceans",
  CELERY = "celery",
  SUGAR = "sugar",
  SALT = "salt",
  SPICES = "spices",
  OIL = "oil",
  VINEGAR = "vinegar",
  HERBS = "herbs",
  FLOUR = "flour",
  YEAST = "yeast",
  BAKING_POWDER = "baking-powder",
  BAKING_SODA = "baking-soda",
  MILK = "milk",
  BUTTER = "butter",
  CHEESE = "cheese",
  CREAM = "cream",
  YOGURT = "yogurt",
  BREAD = "bread",
  PASTA = "pasta",
  RICE = "rice",
  BEANS = "beans",
  LENTILS = "lentils",
  POTATOES = "potatoes",
  TOMATOES = "tomatoes",
  ONIONS = "onions",
  GARLIC = "garlic",
  CARROTS = "carrots",
  BANANAS = "bananas",
  APPLES = "apples",
  PEARS = "pears",
  ORANGES = "oranges",
  LEMONS = "lemons",
  LIMES = "limes",
  GRAPES = "grapes",
  STRAWBERRIES = "strawberries",
  BLUEBERRIES = "blueberries",
  RASPBERRIES = "raspberries",
  BLACKBERRIES = "blackberries",
  WATERMELON = "watermelon",
  MELON = "melon",
  PINEAPPLE = "pineapple",
}

export enum CouponType {
  PERCENTAGE = "percentage",
}

export enum Status {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export enum AddressType {
  USER = "user",
  STORE = "store",
}

export enum OrderReviewType {
  STORE = "store",
  DRIVER = "driver",
}

export enum CategoryType {
  STORE = "store",
  STORE_CATEGORY = "store-category",
  STORE_ITEM = "store-item",
  STORE_TABLE = "store-table",
}

export enum SliderAssignment {
  ONE_STORE = "one-store",
  ALL_STORES = "all-stores",
  CUSTOM = "custom",
}

export enum CouponAssignment {
  ONE_STORE = "one-store",
  ALL_STORES = "all-stores",
}

export enum DeliveryType {
  DELIVERY = "delivery",
  PICKUP = "pickup",
  IN_STORE = "in-store",
}

export enum MediaType {
  STORE = "store",
  ITEM = "item",
  SLIDER = "slider",
  NOTIFICATION = "notification",
  CATEGORY = "category",
}

export enum CouponUserType {
  UNLIMITED_TIMES_FOR_ALL_USERS = "unlimited-times-for-all-users",
  ONCE_FOR_NEW_USER_FOR_FIRST_ORDER = "once-for-new-user-for-first-order",
  ONCE_PER_USER = "once-per-user",
}

export enum VerificationTokenType {
  VERIFY_EMAIL = "verify-email",
  ACCEPT_STORE_INVITATION = "accept-store-invitation",
}

export enum VerificationTokenRelationType {
  USER = "user",
  STORE_USER = "store-user",
}

export enum WsEvent {
  JOINED = "joined",
  LEFT = "left",
  UPDATED = "updated",
  UPDATED_ORDER_STATUS = "updated-order-status",
  USER_BLOCKED = "user-blocked",
  MESSAGE_RECEIVED = "message-received",
  MESSAGE_SENT = "message-sent",
}

export enum EarningType {
  INFLUENCER = "influencer",
  STORE = "store",
}

export enum EarningStatus {
  PAID = "paid",
  UNPAID = "unpaid",
}

export enum ItemStatus {
  PENDING = "pending",
  VERIFIED = "verified",
  REVISION = "revision",
  DISABLED = "disabled",
}

export enum QrType {
  STORE = "store",
  TABLE = "table",
}

export enum Client {
  ADMIN = "admin",
  CUSTOMER = "customer",
}
export interface Tab {
  id?: number;
  title?: string;
  icon?: string;
  active?: boolean;
  checked?: boolean;
}
export interface ButtonOption {
  text: string;
  icon: string;
  hideText: boolean;
  hideIcon: boolean;
}

export interface Props {
  id: string;
  customTabs: any[];
  nextButton: Partial<ButtonOption>;
  backButton: Partial<ButtonOption>;
  doneButton: Partial<ButtonOption>;
  hideButtons: boolean;
  startIndex: number;
  verticalTabs: boolean;
  scrollableTabs: boolean;
  cardBackground: boolean;
  navigableTabs: boolean;
  beforeChange: any;
  beforeMount: any;
  squaredTabs: boolean;
}
export interface ComponentProps {
  debounce: number;
  inputClass: Array<string>;
  results: Array<any>;
  resultsContainerClass: Array<string>;
  resultsItemClass: Array<string>;
  max: number;
  placeholder: string;
  useHtmlForResults: boolean;

  displayItem(item: any): string;
}
