import { DeliveryType, StoreStatus } from "@/globals/enums";
import { BaseEntity } from "../base.entity";
import { ItemEntity } from "../item";
import { AddressEntity } from "../common";
import { StoreUserEntity } from "./store-user.entity";
import { StoreCategoryEntity } from "./store-category.entity";
import { CategorySequenceEntity } from "./categories-sequence.entity";

export class StoreCommissionEntity extends BaseEntity {
  delivery: number | null = null;
  pickup: number | null = null;
  inStore: number | null = null;
}

export class StoreEntity extends BaseEntity {
  id: string | null = null;
  uniqueId: string | null = null;
  categoryId: string | null = null;
  name: string | null = null;
  logoImage: File | null = null;
  coverImage: Array<File> | null = null;
  email: string | null = null;
  phone: string | null = null;
  imPrint: string | null = null;
  vatNumber: string | null = null;
  phoneCode: string | null = null;
  description: string | null = null;
  status: StoreStatus | null = null;
  commission = new StoreCommissionEntity();
  isActive = false;
  member?: Array<StoreUserEntity> = [];
  address = new AddressEntity();
  minOrder?: number | null = "0" as unknown as number;
  storeCategories: Array<StoreCategoryEntity> = [];
  storeCategoryId: Array<string> | null = null;
  deliveryRadius?: number | null = null;
  deliveryTime: number | null = null;
  deliveryCost?: number | null = "0" as unknown as number;
  deliveryType: Array<DeliveryType> = [];
  nearbyDelivery: Array<any> | null = null;
  items?: Array<ItemEntity> = [];
  itemCategoriesSequence: Array<CategorySequenceEntity> = [];
  tableCategoriesSequence: Array<CategorySequenceEntity> = [];
  blockedStores = [];
  autoOpenClose = true;
  utc = "+01:00";
  activateTables = false;
  totalRatings = 0;
  totalReviews = 0;
  workingHours: Array<object> = [{} as any];
}
