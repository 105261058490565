import { defineComponent } from "vue";
import storeAutocomplete from "../autocomplete/index.vue";
import { Helpers } from "@/helper";
import { store } from "@/store";
import { userservice, storeUser } from "@/utils";
import { baseAPI } from "@/plugins";
import AutoComplete from "@/components/AutoComplete.vue";

export default defineComponent({
  name: "memberModal",
  components: { storeAutocomplete, AutoComplete },
  props: {
    items: {
      type: Object,
    },
    useritems: {
      type: Array,
    },
  },
  data: () => {
    return {
      helpers: Helpers,
      isClose: false as any,
      Email: "" as any,
      totalHeight: null as any,
      isSelected: false as any,
      inputes: [] as any,
      selectedRole: "" as any,
    };
  },
  setup(props) {
    console.log("items", props.items);
  },
  methods: {
    updateRole(val?: any) {
      console.log(val);
    },
    save() {
      this.checkEmail();
    },
    async checkEmail() {
      try {
        const user = await userservice.getCustomerByEmail(this.Email!);
        if (user.data) {
          const { data } = await baseAPI.post(
            `/admin/store-user/member/add/${this.$route.params.id}`,
            {
              email: this.Email,
              storeId: this.$route.params.id,
              role: this.selectedRole?.id,
            }
          );
          const id = Date.now();
          this.$notify({
            id,
            type: "success",
            duration: 3000,
            text: `Invitation sent`,
          });
          const result = [data].map((d: any) => ({
            ...d,
            User: user.data,
          }));
          this.$emit("submit", result[0]);
          this.$emit("close");
        }
      } catch (e) {
        console.log(e);
        const error = `Either user doesn't exist or <b>"${this.Email}"</b> not a customer.`;
        const id = Date.now();
        this.$notify({
          id,
          type: "error",
          duration: 3000,
          text: error,
        });
      }
    },
    close() {
      this.$emit("close");
    },
  },
});
