<template>
  <div class="flex h-[66px] justify-between p-5 pb-3">
    <div>
      <h1 class="h1">Finance</h1>
    </div>
  </div>
  <div class="flex gap-2 justify-between overflow-x-auto px-5 py-3">
    <div></div>
    <div class="flex gap-2">
      <select class="form-filter w-48">
        <option selected="" class="w-96">Date</option>
        <option value="US">United States</option>
        <option value="CA">Canada</option>
        <option value="FR">France</option>
        <option value="DE">Germany</option>
      </select>
      <select class="form-filter w-48">
        <option selected="" class="w-96">Store/User</option>
        <option value="US">United States</option>
        <option value="CA">Canada</option>
        <option value="FR">France</option>
        <option value="DE">Germany</option>
      </select>
      <select class="form-filter w-48">
        <option selected="" class="w-96">Status</option>
        <option value="US">United States</option>
        <option value="CA">Canada</option>
        <option value="FR">France</option>
        <option value="DE">Germany</option>
      </select>
    </div>
  </div>
  <div class="flex flex-col gap-2 overflow-x-auto p-5">
    <div class="box-head-blank justify-between">
      <div class="flex gap-4 items-center">
        <div class="w-44"><span class="text-order">Store/User</span></div>
        <div class="w-28"><span class="text-order">Date</span></div>
        <div class="flex justify-center w-24">
          <span class="text-order">Tedera</span>
        </div>
        <div class="flex justify-center w-24">
          <span class="text-order">Store/User</span>
        </div>
      </div>
      <div class="flex gap-4 justify-end">
        <div class="flex justify-center w-48">
          <span class="text-order">Paymend Reference</span>
        </div>
        <div class="flex justify-center w-36">
          <span class="text-order">Payment Amount</span>
        </div>
        <div class="flex justify-center w-36">
          <span class="text-order">Status</span>
        </div>
        <div class="flex justify-end w-16">
          <span class="text-order">Total</span>
        </div>
      </div>
    </div>
    <div class="box-blank justify-between min-w-max">
      <div class="flex gap-4 items-center">
        <div class="w-44">
          <p class="text-title">London Store</p>
        </div>
        <div class="w-28">
          <p class="text">August 2022</p>
        </div>
        <div class="flex justify-center w-24">
          <span class="text">0,35 &euro;</span>
        </div>
        <div class="flex justify-center w-24">
          <span class="text">3,15 &euro;</span>
        </div>
      </div>
      <div class="flex gap-4 items-center">
        <div class="flex justify-center w-48">
          <span class="text">TD-2022-AUG-WMDHFGSD</span>
        </div>
        <div class="flex justify-center w-36">
          <div class="button-finance-red w-24"><span>0,35 &euro;</span></div>
        </div>
        <div class="flex justify-center w-36">
          <div class="button-status w-28">
            <div><span>Unpaid</span></div>
            <div>
              <svg
                width="12"
                viewBox="0 0 13 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                height="8"
              >
                <path
                  d="M12 1L6.5 7L1 1"
                  stroke="#515151"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="flex justify-end w-16">
          <span class="text-title">3,21&nbsp;&euro;</span>
        </div>
      </div>
    </div>
    <div class="box-blank justify-between min-w-max">
      <div class="flex gap-4 items-center">
        <div class="w-44">
          <p class="text-title">Influ Encer</p>
        </div>
        <div class="w-28">
          <p class="text">August 2022</p>
        </div>
        <div class="flex justify-center w-24">
          <span class="text">0,00 &euro;</span>
        </div>
        <div class="flex justify-center w-24">
          <span class="text">4,36 &euro;</span>
        </div>
      </div>
      <div class="flex gap-4 items-center">
        <div class="flex justify-center w-48">
          <span class="text">TD-2022-AUG-WMDHFGSD</span>
        </div>
        <div class="flex justify-center w-36">
          <div class="button-finance-green w-24"><span>4,36 &euro;</span></div>
        </div>
        <div class="flex justify-center w-36">
          <div class="button-status w-28">
            <div><span>Unpaid</span></div>
            <div>
              <svg
                width="12"
                viewBox="0 0 13 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                height="8"
              >
                <path
                  d="M12 1L6.5 7L1 1"
                  stroke="#515151"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="flex justify-end w-16">
          <span class="text-title">3,21&nbsp;&euro;</span>
        </div>
      </div>
    </div>
    <div class="box-blank flex flex-col gap-2 min-w-max">
      <div class="flex justify-between w-full">
        <div class="flex gap-4 items-center">
          <div class="w-44">
            <p class="text-order">Order ID</p>
          </div>
          <div class="w-28">
            <p class="text-order">Date</p>
          </div>
          <div class="flex hidden justify-center w-24 2xl:flex">
            <span class="text-order">Tedera</span>
          </div>
          <div class="flex hidden justify-center w-24 2xl:flex">
            <span class="text-order">Store/User</span>
          </div>
          <div class="flex hidden justify-center w-24 2xl:flex">
            <span class="text-order">Type</span>
          </div>
          <div class="hidden justify-center w-24 2xl:flex">
            <span class="text-order">Payment</span>
          </div>
          <div class="flex justify-center w-24">
            <span class="text-order">Coupon Code</span>
          </div>
          <div class="hidden justify-center w-28 2xl:flex">
            <span class="text-order">Coupon Owner</span>
          </div>
          <div class="hidden justify-center w-24 2xl:flex">
            <span class="text-order">Coupon %</span>
          </div>
          <div class="flex justify-center w-24">
            <span class="text-order">Subtotal</span>
          </div>
          <div class="flex justify-center w-24">
            <span class="text-order">Discount</span>
          </div>
        </div>
        <div class="flex gap-4 items-center">
          <div class="flex justify-end w-24">
            <span class="text-title">Total Sales</span>
          </div>
        </div>
      </div>
      <div class="flex justify-between w-full">
        <div class="flex gap-4 items-center">
          <div class="w-44">
            <p class="text">OD-08-00-ZSHDGSFF</p>
          </div>
          <div class="w-28">
            <p class="text">14.08.2022</p>
          </div>
          <div class="flex hidden justify-center w-24 2xl:flex">
            <span class="text">0,00 &euro;</span>
          </div>
          <div class="flex hidden justify-center w-24 2xl:flex">
            <span class="text">3,50 &euro;</span>
          </div>
          <div class="flex hidden justify-center w-24 2xl:flex">
            <span class="text">Delivery</span>
          </div>
          <div class="hidden justify-center w-24 2xl:flex">
            <span class="text">Cash</span>
          </div>
          <div class="flex justify-center w-24">
            <span class="text">234322</span>
          </div>
          <div class="hidden justify-center w-28 2xl:flex">
            <span class="text">Influ Encer</span>
          </div>
          <div class="hidden justify-center w-24 2xl:flex">
            <span class="text">10%</span>
          </div>
          <div class="flex justify-center w-24">
            <span class="text">71,00 &euro;</span>
          </div>
          <div class="flex justify-center w-24">
            <span class="text">-7,10 &euro;</span>
          </div>
        </div>
        <div class="flex gap-4 items-center">
          <div class="flex justify-end w-20">
            <span class="text">63,90 &euro;</span>
          </div>
        </div>
      </div>
      <div class="flex justify-between w-full">
        <div class="flex gap-4 items-center">
          <div class="w-44">
            <p class="text">OD-08-00-ZSHDGSFF</p>
          </div>
          <div class="w-28">
            <p class="text">14.08.2022</p>
          </div>
          <div class="flex hidden justify-center w-24 2xl:flex">
            <span class="text">0,00 &euro;</span>
          </div>
          <div class="flex hidden justify-center w-24 2xl:flex">
            <span class="text">2,10 &euro;</span>
          </div>
          <div class="flex hidden justify-center w-24 2xl:flex">
            <span class="text">Delivery</span>
          </div>
          <div class="hidden justify-center w-24 2xl:flex">
            <span class="text">Cash</span>
          </div>
          <div class="flex justify-center w-24">
            <span class="text">234322</span>
          </div>
          <div class="hidden justify-center w-28 2xl:flex">
            <span class="text">Influ Encer</span>
          </div>
          <div class="hidden justify-center w-24 2xl:flex">
            <span class="text">10%</span>
          </div>
          <div class="flex justify-center w-24">
            <span class="text">71,00 &euro;</span>
          </div>
          <div class="flex justify-center w-24">
            <span class="text">-7,10 &euro;</span>
          </div>
        </div>
        <div class="flex gap-4 items-center">
          <div class="flex justify-end w-20">
            <span class="text">63,90 &euro;</span>
          </div>
        </div>
      </div>
      <div class="flex justify-between w-full">
        <div class="flex gap-4 items-center">
          <div class="w-44">
            <p class="text">OD-08-00-ZSHDGSFF</p>
          </div>
          <div class="w-28">
            <p class="text">14.08.2022</p>
          </div>
          <div class="flex hidden justify-center w-24 2xl:flex">
            <span class="text">0,00 &euro;</span>
          </div>
          <div class="flex hidden justify-center w-24 2xl:flex">
            <span class="text">0,00 &euro;</span>
          </div>
          <div class="flex hidden justify-center w-24 2xl:flex">
            <span class="text">Delivery</span>
          </div>
          <div class="hidden justify-center w-24 2xl:flex">
            <span class="text">Cash</span>
          </div>
          <div class="flex justify-center w-24">
            <span class="text">234322</span>
          </div>
          <div class="hidden justify-center w-28 2xl:flex">
            <span class="text">Influ Encer</span>
          </div>
          <div class="hidden justify-center w-24 2xl:flex">
            <span class="text">10%</span>
          </div>
          <div class="flex justify-center w-24">
            <span class="text">71,00 &euro;</span>
          </div>
          <div class="flex justify-center w-24">
            <span class="text">-7,10 &euro;</span>
          </div>
        </div>
        <div class="flex gap-4 items-center">
          <div class="flex justify-end w-20">
            <span class="text">63,90 &euro;</span>
          </div>
        </div>
      </div>
      <div class="flex justify-between w-full">
        <div class="flex gap-4 items-center">
          <div class="w-44">
            <p class="text">OD-08-00-ZSHDGSFF</p>
          </div>
          <div class="w-28">
            <p class="text">14.08.2022</p>
          </div>
          <div class="flex hidden justify-center w-24 2xl:flex">
            <span class="text">0,00 &euro;</span>
          </div>
          <div class="flex hidden justify-center w-24 2xl:flex">
            <span class="text">0,00 &euro;</span>
          </div>
          <div class="flex hidden justify-center w-24 2xl:flex">
            <span class="text">Delivery</span>
          </div>
          <div class="hidden justify-center w-24 2xl:flex">
            <span class="text">Cash</span>
          </div>
          <div class="flex justify-center w-24">
            <span class="text">234322</span>
          </div>
          <div class="hidden justify-center w-28 2xl:flex">
            <span class="text">Influ Encer</span>
          </div>
          <div class="hidden justify-center w-24 2xl:flex">
            <span class="text">10%</span>
          </div>
          <div class="flex justify-center w-24">
            <span class="text">71,00 &euro;</span>
          </div>
          <div class="flex justify-center w-24">
            <span class="text">-7,10 &euro;</span>
          </div>
        </div>
        <div class="flex gap-4 items-center">
          <div class="flex justify-end w-20">
            <span class="text">63,90 &euro;</span>
          </div>
        </div>
      </div>
    </div>
    <div class="box-blank justify-between min-w-max">
      <div class="flex gap-4 items-center">
        <div class="w-44">
          <p class="text-title">Jane Doe</p>
        </div>
        <div class="w-28">
          <p class="text">August 2022</p>
        </div>
        <div class="flex justify-center w-24">
          <span class="text">0,00 &euro;</span>
        </div>
        <div class="flex justify-center w-24">
          <span class="text">0,21 &euro;</span>
        </div>
      </div>
      <div class="flex gap-4 items-center">
        <div class="flex justify-center w-48">
          <span class="text">TD-2022-AUG-WMDHFGSD</span>
        </div>
        <div class="flex justify-center w-36">
          <div class="button-finance-green w-24"><span>0,21 &euro;</span></div>
        </div>
        <div class="flex justify-center w-36">
          <div class="button-status w-28">
            <div><span>Unpaid</span></div>
            <div>
              <svg
                width="12"
                viewBox="0 0 13 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                height="8"
              >
                <path
                  d="M12 1L6.5 7L1 1"
                  stroke="#515151"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="flex justify-end w-16">
          <span class="text-title">3,21&nbsp;&euro;</span>
        </div>
      </div>
    </div>
    <div class="box-blank justify-between min-w-max">
      <div class="flex gap-4 items-center">
        <div class="w-44">
          <p class="text-title">New York Store</p>
        </div>
        <div class="w-28">
          <p class="text">July 2022</p>
        </div>
        <div class="flex justify-center w-24">
          <span class="text">11,78 &euro;</span>
        </div>
        <div class="flex justify-center w-24">
          <span class="text">122,15&nbsp;&euro;</span>
        </div>
      </div>
      <div class="flex gap-4 items-center">
        <div class="flex justify-center w-48">
          <span class="text">TD-2022-JUL-WMDHFGSD</span>
        </div>
        <div class="flex justify-center w-36">
          <div class="button-finance-red w-24">
            <span>11,78 &euro;</span>
          </div>
        </div>
        <div class="flex justify-center w-36">
          <div class="button-status w-28">
            <div><span>Paid</span></div>
            <div>
              <svg
                width="12"
                viewBox="0 0 13 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                height="8"
              >
                <path
                  d="M12 1L6.5 7L1 1"
                  stroke="#515151"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="flex justify-end w-16">
          <span class="text-title">3,21&nbsp;&euro;</span>
        </div>
      </div>
    </div>
    <div class="box-blank justify-between min-w-max">
      <div class="flex gap-4 items-center">
        <div class="w-44">
          <p class="text-title">Prishtina Store</p>
        </div>
        <div class="w-28">
          <p class="text">July 2022</p>
        </div>
        <div class="flex justify-center w-24">
          <span class="text">9,82&nbsp;&euro;</span>
        </div>
        <div class="flex justify-center w-24">
          <span class="text">94,31&nbsp;&euro;</span>
        </div>
      </div>
      <div class="flex gap-4 items-center">
        <div class="flex justify-center w-48">
          <span class="text">TD-2022-JUL-WMDHFGSD</span>
        </div>
        <div class="flex justify-center w-36">
          <div class="button-finance-red w-24">
            <span>9,82 &euro;</span>
          </div>
        </div>
        <div class="flex justify-center w-36">
          <div class="button-status w-28">
            <div><span>Paid</span></div>
            <div>
              <svg
                width="12"
                viewBox="0 0 13 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                height="8"
              >
                <path
                  d="M12 1L6.5 7L1 1"
                  stroke="#515151"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="flex justify-end w-16">
          <span class="text-title">3,21&nbsp;&euro;</span>
        </div>
      </div>
    </div>
    <div class="flex gap-4 items-center justify-end mt-4">
      <span class="text-order">Payment</span>
      <div class="button-finance-red w-44">
        <span>Store/User has to pay</span>
      </div>
      <div class="button-finance-green w-40">
        <span>Tedera pays</span>
      </div>
    </div>
    <div class="flex gap-2 items-center justify-end p-3">
      <div class="button-pagination">
        <span>Prev</span>
      </div>
      <div class="button-pagination-page-inactive">
        <span>1</span>
      </div>
      <div class="button-pagination-page-inactive">
        <span>2</span>
      </div>
      <div class="button-pagination-page-active">
        <span>3</span>
      </div>
      <div class="button-pagination-page-inactive">
        <span>4</span>
      </div>
      <div class="button-pagination-page-inactive">
        <span>5</span>
      </div>
      <div class="button-pagination-page-inactive">
        <span>...</span>
      </div>
      <div class="button-pagination-page-inactive"><span>20</span></div>
      <div class="button-pagination">
        <span>Next</span>
      </div>
    </div>
  </div>
</template>
