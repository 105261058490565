
import { UserRoleWithOwner } from "@/globals/enums";
import AutoComplete from "@/components/AutoComplete.vue";
import StatusFilter from "./StatusFilter.vue";
import { Menu, MenuItems, MenuButton } from "@headlessui/vue";
import { Helpers } from "@/helper";
import { store } from "@/store";
import {
  OrderFilterEntity,
  orderservice,
  StoreFilterEntity,
  storeservice,
  UserFilterEntity,
  userservice,
} from "@/utils";
import { defineComponent, ref, computed, useAttrs } from "vue";

export default defineComponent({
  name: "BaseAutocomplete",
  components: {
    StatusFilter,
    Menu,
    MenuItems,
    MenuButton,
    AutoComplete,
  },
  data: () => {
    return {
      orders: {} as any,
      inputes: [] as any,
      filterItems: {
        DeliveryType: null,
        Status: null,
        StoreId: null as any,
        IsOpen: null as any,
        CreatedAfter: null,
        CreatedBefore: null,
        DriverId: null,
        CustomerId: null,
      },
      helpers: Helpers,
      totalHeight: null as any,
      selectedStoreFilter: "" as any,
      selectedCustomerFilter: "" as any,
      selectedDeliveryTypeFilter: "" as any,
    };
  },
  props: [
    "itemsOrder",
    "itemsStore",
    "storeItemText",
    "userItemText",
    "filterOrder",
    "filterCustomerOrders",
  ],
  setup(props) {
    const attrs = useAttrs();
    const Users = ref();
    const filter = ref();
    const storeData = ref();

    const joinedByStatus = ref();
    const joinedByStoreId = ref([]);
    const joinedByDeliveryType = ref([]);
    const joinedByCustomerId = ref([]);

    return {
      filter,
      storeData,
      joinedByStatus,
      joinedByCustomerId,
      joinedByStoreId,
      joinedByDeliveryType,
      Users,
    };
  },

  methods: {
    async getOrders() {
      this.filterItems.IsOpen = true;
      let response;
      let customers;
      let stores;
      customers = await userservice.getAll(
        new UserFilterEntity({
          Limit: 1000,
          Role: UserRoleWithOwner.CUSTOMER,
        })
      );
      if (this.filterOrder.IsOpen === true) {
        response = await orderservice.getAllPlaced(
          new OrderFilterEntity(this.filterItems)
        );
      } else {
        response = await orderservice.getAll(
          new OrderFilterEntity(this.filterOrder)
        );
      }
      const store = await storeservice.getAll(
        new StoreFilterEntity({
          Limit: 1000,
        })
      );
      // joined for status
      const joinedByStatus = response.data.Data.reduce(
        (group: any, da: any) => {
          const { Status } = da;
          group[Status] = group[Status] ?? [];
          group[Status].push(da);

          return group;
        },
        {}
      );
      const r = Object.keys(joinedByStatus);
      this.joinedByStatus = r;
      // joined for Delivery Type
      const joinedByDeliveryType = response.data.Data.map(
        (order: any) => order.DeliveryType
      ).filter(
        (data: any, index: number, self: any) => self.indexOf(data) === index
      );
      this.joinedByDeliveryType = joinedByDeliveryType;
      // joined for Customer
      const joinedByCustomerId = response.data.Data.map((order: any) => ({
        ...order.Customer,
        Name: order.Customer.FirstName + " " + order.Customer.LastName,
      }));
      // filtering the duplicate data
      this.joinedByCustomerId = joinedByCustomerId.filter(
        (data: any, index: number) =>
          !joinedByCustomerId.map((d: any) => d.Id).includes(data.Id, index + 1)
      );
      // joined for store Id
      const joinedByStoreId = response.data.Data.map(
        (order: any) => order.Store
      );
      // filtering the duplicate data
      this.joinedByStoreId = joinedByStoreId.filter(
        (data: any, index: number) =>
          !joinedByStoreId.map((d: any) => d.Id).includes(data.Id, index + 1)
      );

      this.filter = response.data.Data;
      this.storeData = store.data.Data;
    },
    findStore(val?: any): void {
      this.filterItems.StoreId = this.selectedStoreFilter?.Id || null;
      this.$emit("filter", this.filterItems);
    },
    findCustomer(val?: any) {
      this.filterItems.CustomerId = this.selectedCustomerFilter?.Id || null;
      this.$emit("filter", this.filterItems);
    },
    findDeliveryType(val?: any) {
      this.filterItems.DeliveryType = this.selectedDeliveryTypeFilter || null;
      this.$emit("filter", this.filterItems);
    },
    findStatus(val: any) {
      // console.log('status', val)
      this.filterItems.Status = val;
      this.$emit("filter", this.filterItems);
    },
  },
  mounted() {
    this.Users = this.userItemText;
    // this.getOrders()
    this.filter = this.itemsOrder;
    this.storeData = this.itemsStore;
    store.state.orders = this.filter;
  },
  emits: ["filter", "close"],
});
