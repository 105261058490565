import { StoreStatus } from '@/globals/enums';
import { FilterEntity } from '../shared/filter.entity';
import { StoreEntity } from './store.entity';

export class StoreFilterEntity extends FilterEntity<StoreEntity> {
  Name: string | null = null;
  IsActive: boolean | null = null;
  Status: StoreStatus | null = null;
  CategoryId: string | null = null;
  HasPendingItems: boolean | null = null;
  StoreUserId: string | null = null;

  constructor(data?: Partial<StoreFilterEntity>) {
    super(data);
    Object.assign(this, data);
  }
}
