import {createApp} from 'vue'
import Vue from 'vue'
import { createI18n } from 'vue-i18n';
import en from '../locales/en-US'
import alb from '../locales/alb'

function loadLocaleMessages() {
    const locales = require.context(
        "../locales",
        true,
        /[A-Za-z0-9-_,\s]+\.ts$/i
    );
    const messages = {};
    locales.keys().forEach(key => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i);
      if (matched && matched.length > 1) {
        const locale = matched[1];
        messages[locale] = locales(key);
        // console.log('locale',messages[locale].dashboard)
      }
    });
    return messages;
  }
export default createI18n({
    legacy: false,
    locale: process.env.VUE_APP_I18N_LOCALE || "en",
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
    messages: {
        en, alb
    }
    
})
