import { BaseEntity } from '../base.entity';

export class PaginationEntity extends BaseEntity {
  private _CurrentPage = 1;
  private _TotalItems = 0;
  PerPage = 25;

  get CurrentPage() {
    return this._CurrentPage;
  }
  set CurrentPage(val: number) {
    if (val <= this.TotalPages) {
      this._CurrentPage = val;
    }
  }

  set TotalItems(val: number) {
    this._TotalItems = val;
  }

  get TotalPages() {
      return Math.ceil(this._TotalItems / this.PerPage);
  }
  get IsNextDisabled() {
    return (
      !this.TotalPages ||
      (this.TotalPages && this.CurrentPage === this.TotalPages) ||
      this.CurrentPage > this.TotalPages
    );
  }
  get IsPreviousDisabled() {
    return this.CurrentPage <= 1 || this.CurrentPage > this.TotalPages;
  }

  public next() {
    if (!this.IsNextDisabled) {
      this.CurrentPage++;
    }
  }
  public previous() {
    if (!this.IsPreviousDisabled) {
      this.CurrentPage--;
    }
  }

  public toQueryObj() {
    return {
      Page: this.CurrentPage,
      Limit: this.PerPage
    };
  }

  constructor(data?: Partial<PaginationEntity>) {
    super();
    Object.assign(this, data);
  }
}
