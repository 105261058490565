import { BaseEntity } from '../base.entity';

export class AssignOrderEntity extends BaseEntity {
  Id!: string;
  CookId: string | null = null;
  DriverId: string | null = null;

  constructor(data?: Partial<AssignOrderEntity>) {
    super();
    Object.assign(this, data);
  }
}
