import VueI18n from 'vue-i18n';
import { singleton } from '@/helper';

export type Alert = {
  show(
    msg: string | VueI18n.TranslateResult,
    type?: 'error' | 'success' | 'warning',
    closeable?: boolean,
    timer?: number
  ): void;
};

@singleton
export class AlertService implements Alert {
  private _Mode = false;
  public Message = '';
  public Type: 'error' | 'success' | 'warning' = 'success';
  public Closeable = true;
  public Timer = 6;
  public TimerValue = 100;

  get Mode() {
    return this._Mode;
  }

  set Mode(mode: boolean) {
    clearInterval(this.TimerFunc);
    this.TimerValue = 100;
    this._Mode = mode;
  }

  private TimerFunc!: ReturnType<typeof setInterval>;

  public show(msg: string, type: 'error' | 'success' | 'warning' = 'success', closeable = true, timer = 6) {
    this.Type = type;
    this.Mode = true;
    this.Message = msg;
    this.Closeable = closeable;
    this.Timer = timer;
    this.TimerValue = 100;

    this.TimerFunc = setInterval(() => {
      if (this.TimerValue <= 0) {
        clearInterval(this.TimerFunc);
        this.TimerValue = 100;
      } else {
        this.TimerValue -= 1;
      }
    }, 10 * this.Timer);
  }
}
