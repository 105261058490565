import { baseAPI } from "@/plugins/axios";
import { defineComponent, ref, onMounted } from "vue";
import EmailModal from "../../components/EmailModal.vue";
import PhoneModal from "../../components/change-phone/PhoneModal.vue";
import PasswordModal from "../../components/PasswordModal.vue";
import PhoneOtp from "../../components/PhoneOtp.vue";
import { OtpService } from "@/services";
import { UserEntity } from "../../utils/user/user.entity";
import { apiProfile } from "@/utils/profile/profile.service";
import { store } from "../../store";

const result = [] as any;
const srv = new OtpService();
export default defineComponent({
  name: "ProfileView",
  components: { EmailModal, PhoneModal, PasswordModal, PhoneOtp },
  props: ["showOtp"],
  data: () => {
    return {
      showEmailModal: false,
      showPassModal: false,
      showPhoneModal: false,
      openOtp: false,
      result,
      res: {} as any,
      user: {
        firstName: "",
        lastName: "",
        street: null,
        no: null,
        address: [
          {
            zip: null,
            description: null,
            street: null,
            country: null,
            city: null,
            lat: null,
            lng: null,
            number: null,
          },
        ],
      } as any,
      OptSrv: new OtpService(),
    };
  },
  setup(props) {
    let otp = ref() as any;
    setTimeout(() => {
      otp = srv.Loading;
      console.log("iorheiohrioere", otp);
    }, 5000);
    return { otp };
  },
  mounted() {
    this.otp = this.showOtp;
    console.log(this.otp);
    this.getProfile();
  },
  methods: {
    closeModal() {
      this.showEmailModal = false;
      this.showPassModal = false;
      this.showPhoneModal = false;
      this.openOtp = false;
      this.getProfile();
    },
    async getProfile() {
      const response = await apiProfile.getProfile();
      console.log("response", response);
      this.result = response.data.data;
      this.user = this.result.data;
      console.log("userrr", this.user);
    },
    async update() {
      this.showPhoneModal = false;
      const response = await apiProfile.updateProfile(this.user);
      this.result = response;
      this.getProfile();
      console.log(this.result);
    },
    show() {
      this.otp = !this.otp;
    },
    close() {
      this.showPhoneModal = false;
      this.openOtp = false;
    },
  },
});
