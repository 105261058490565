
import { defineComponent, isReactive } from "vue";
import { authAPI } from "../../plugins/axios";
import { store } from "../../store";
import tokenService from "@/plugins/token.service";

export default defineComponent({
  name: "LoginView",
  data: () => {
    return {
      isFormValid: false,
      isActive: false,
      disabled: true,
      valid: true,
      loading: false,
      hidden: "vissible",
      email: "",
      password: "",
      phone: "",
      msg: [],
      errors: [],
      passwordFieldType: "password",
      selected: 1,
      options: [{ code: "+383", id: 1 }],
      passwordRules: [(v: any) => !!v || "Password is required"],
      emailRules: [(v: any) => !!v || "E-mail is required"],
      phoneRules: [(v: any) => !!v || "Phone is required"],
    };
  },
  computed: {
    loginApiURL() {
      return this.$route.name == "adminlogin" ? "admin/login" : "user/login";
    },
    sortHidden() {
      return this.hidden === "vissible" ? ["fas", "eye"] : ["fas", "eye-slash"];
    },
  },
  watch: {
    Email(value) {
      this.email = value;
      this.validateEmail(value);
    },
    Password(value) {
      this.password = value;
      this.validatePassword(value);
    },
  },
  methods: {
    //implement login service with email
    async logIn() {
      this.loading = !this.loading;
      this.errors = [];
      console.log("Login", this.email, this.password, this.loginApiURL);
      try {
        let response = await authAPI.post(this.loginApiURL, {
          email: this.email,
          password: this.password,
        });
        console.log("Login Response", response);
        if (response.data.data.accessToken) {
          console.log("Committed");
          store.commit("login", response.data);
        }
        // tokenService.setUser(response.data);
        console.log("made it ", store.state.user);
        this.$router.push({ name: "dashboard" });
        tokenService.updateLocalAccessToken(response.data.data.accessToken);
        console.log("Should work");
        this.loading = !this.loading;
      } catch (e: any) {
        console.log("e", e);
        const id = Date.now();
        console.log("e.response.data.data", e);
        e.response.data.data.forEach((data: any) => {
          this.$notify({
            id,
            type: "error",
            duration: 10000,
            text: data.msg,
          });
        });
      }
    },
    // login with email
    async loginWithPhone() {
      this.errors = [];
      try {
        const response = await authAPI.post(
          this.loginApiURL,
          {
            Phone: this.options[0].code + " " + this.phone,
            Password: this.password,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        if (response.data.AccessToken) {
          store.commit("login", response.data);
        }
        this.$router.push({ name: "dashboard" });
        this.loading = !this.loading;
      } catch (e: any) {
        const id = Date.now();
        this.$notify({
          id,
          type: "error",
          duration: 10000,
          text: e.response.data.message,
        });
      }
    },
    //toggle show and hide password
    switchVisibility() {
      this.hidden = this.hidden === "vissible" ? "hidden" : "vissible";
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    //validate email
    validateEmail(value: any) {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (pattern.test(value)) {
        this.msg["email"] = "";
      } else {
        this.msg["email"] = "Invalid Email Address";
      }
    },
    //validate password
    validatePassword(value: any) {
      let difference = 8 - value.length;
      if (value.length < 8) {
        this.msg["password"] =
          "Must be 8 characters! " + difference + " characters left";
      } else {
        this.msg["password"] = "";
      }
    },
    // toggle email and phone login page
    toggleIsActive() {
      this.isActive = !this.isActive;
    },
  },
});
