import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("select", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$i18n) = $event)),
    name: "",
    id: ""
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locales, (locale, i) => {
      return (_openBlock(), _createElementBlock("option", {
        key: `locale-${i}`,
        value: locale
      }, _toDisplayString(locale), 9, _hoisted_1))
    }), 128))
  ], 512)), [
    [_vModelSelect, _ctx.$i18n]
  ])
}