
    import { defineComponent } from 'vue';
    import i18n from '@/plugins/i18n';

    export default defineComponent({
        name: 'LocaleSwitcher',
        data: () => {
            return {
                locales: [
                    "alb",
                    "English"
                ]
            }
        }
    })
