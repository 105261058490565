import { Status, UserRoleWithOwner } from '@/globals/enums';
import { FilterEntity } from '../shared/filter.entity';
import { UserEntity } from './user.entity';

export class UserFilterEntity extends FilterEntity<UserEntity> {
  Status: Status | null = null;
  Role: UserRoleWithOwner | null = null;
  FirstName: string | null = null;

  constructor(data?: Partial<UserFilterEntity>) {
    super();
    Object.assign(this, data);
  }
}
