import { format, formatDistanceToNow } from "date-fns";
import { HelperPlugin, AnyObject, COUNTRIES } from "@/helper";
import { UserEntity } from "@/utils";
import { store } from "../store";
import { OrderStatus, DeliveryType, UserRole } from "../globals/enums";

export const Helpers: HelperPlugin = {
  $context: null,
  formatDate: format,

  toCurrency(text: any) {
    return `${new Intl.NumberFormat(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(+text)} €`.replace(/[,.]/g, (m) => (m === "," ? "," : "."));
  },
  numToHourMinSec(num: any) {
    const hrs = ~~(num / 3600);
    const mins = ~~((num % 3600) / 60);
    const secs = ~~num % 60;

    return `${hrs < 10 ? "0" : ""}${hrs}:${mins < 10 ? "0" : ""}${mins}:${
      secs < 10 ? "0" : ""
    }${secs} min`;
  },
  upperFirst(text) {
    return text && text.length
      ? text.charAt(0).toUpperCase() + text.slice(1)
      : "";
  },
  smallFirst(text) {
    return text.charAt(0).toLowerCase() + text.slice(1);
  },
  slugify(text) {
    return text
      ?.toString()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "")
      .replace(/--+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
  },
  unslugify(text) {
    return text
      .replace(/-/g, " ")
      .replace(/\w\S*/g, (str) => Helpers.upperFirst(str));
  },
  titleize(text) {
    return Helpers.unslugify(text)
      .toLowerCase()
      .replace(/(?:^|\s|-)\S/g, (x) => x.toUpperCase());
  },
  getOrderStatusColor(Status: any) {
    if (Status === OrderStatus.PLACED) {
      return "#D5BE45";
    } else if (Status === OrderStatus.ACCEPTED) {
      return "#2CB2CF";
    } else if (Status === OrderStatus.PREPARED) {
      return "#FF8900";
    } else if (Status === OrderStatus.ON_THE_WAY) {
      return "#974FCF";
    } else if (Status === OrderStatus.DELIVERED) {
      return "#80B63B";
    } else if (Status === OrderStatus.CANCELED_AUTOMATICALLY) {
      return "#AFAFAF";
    } else if (Status === OrderStatus.DECLINED) {
      return "#ED5151";
    } else {
      return "#D1D3D6";
    }
  },
  getOrderStatusBackgroundColor(Status: any) {
    if (Status === OrderStatus.PLACED) {
      return "#fdfbf4";
    } else if (Status === OrderStatus.ACCEPTED) {
      return "#f6feff";
    } else if (Status === OrderStatus.PREPARED) {
      return "#fffbf6";
    } else if (Status === OrderStatus.ON_THE_WAY) {
      return "#fbf6ff";
    } else if (Status === OrderStatus.DELIVERED) {
      return "#f7fbf3";
    } else if (Status === OrderStatus.CANCELED_AUTOMATICALLY) {
      return "#f9fcff";
    } else if (Status === OrderStatus.DECLINED) {
      return "#fff7f5";
    } else {
      return "#e0e0e0";
    }
  },
  getDeliveryColor(Status) {
    if (Status === DeliveryType.DELIVERY) {
      return "#D5BE45";
    } else if (Status === DeliveryType.PICKUP) {
      return "#39B7D2";
    } else {
      return "#974fcf";
    }
  },
  getAddress({ street, city, number: no, zip, country }) {
    return `${zip}, ${city}, ${
      COUNTRIES.find(({ id }) => id === country)?.value
    }`;
  },
  getAllAddress({ street, city, number: no, zip, country }) {
    return `${street} ${no} ${zip}, ${city}, ${
      COUNTRIES.find(({ id }) => id === country)?.value
    }`;
  },
  getStreet({ street, number: no }) {
    return `${street} ${no}`;
  },
  // getUserProp(prop: number) {
  //   const user: UserEntity | null = store?.state?.auth?.user;

  //   // return user ? user[prop] : null;
  //   return user || null;
  // },
  // hasRole(role: any) {
  //   const userRole: any = Helpers.getUserProp("role");
  //   const hasRole = userRole === role;

  //   if (!hasRole && userRole === UserRole.CUSTOMER) {
  //     return !!Helpers.getUserProp("storeUsers")?.find(
  //       ({ role }) => role === role
  //     );
  //   }

  //   return hasRole;
  // },
  enumToItems(data) {
    return Object.values(data).map((id) => ({
      id,
      value: id.toString().charAt(0).toUpperCase() + id.toString().slice(1)!,
    }));
  },
  filterToQueryObj(pagination, filter) {
    let obj: AnyObject = {};

    Object.entries(filter).forEach(([key, val]) => {
      if (val != null) {
        obj[key] = val;
      }
    });

    obj = { ...obj, ...pagination.toQueryObj() };

    return obj;
  },
  resetTreeViewNodes(ref) {
    const nodes = ref.nodes;
    for (const nodeKey in nodes) {
      if (!nodes[nodeKey].parent) {
        nodes[nodeKey].vnode.hasLoaded = false;
        ref.updateVnodeState(nodeKey);
      }
    }
  },
  resolvePath(obj, path) {
    return path.split(".").reduce((p, c) => p?.[c], obj);
  },
  filterToQueryString<OrderEntity, OrderFilterEntity>(filter: any) {
    if (!filter) {
      return "";
    }

    let str = Object.entries(filter).reduce((acc, [key, val]) => {
      if (val) {
        if (Array.isArray(val)) {
          val.forEach((v, i) => {
            acc += `${key}[${[i]}]=${v}&`;
          });
        } else {
          acc += `${key}=${val}&`;
        }
      }
      return acc;
    }, "");

    str = str.slice(0, -1);
    return str;
  },
  openQr(url) {
    const contentType = "image/png";

    const byteCharacters = window.atob(
      url.slice(`data:${contentType};base64,`.length)
    );
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    const blobUrl = URL.createObjectURL(blob);

    window.open(blobUrl, "_blank");
  },
};
