
import Vue, { PropType, defineComponent, ref, computed } from "vue";
import { PaginationEntity } from "@/utils";

export default defineComponent({
  name: "PaginationView",
  // props: ['pagination'],
  props: {
    pagination: {
      type: Object,
      default: null,
    },
    rangeSize: {
      type: Number,
      default: 1,
    },
    modelValue: {
      type: Number,
      default: 0,
    },
  },
  data: () => {
    return {
      filterItems: {
        DeliveryType: null,
        Status: null,
        StoreId: null,
        IsOpen: null as any,
        CreatedAfter: null,
        CreatedBefore: null,
        DriverId: null,
        CustomerId: null,
      },
    };
  },
  setup(props, { emit }) {
    const pages = computed((): (number | null)[] => {
      const res = [];
      const minPaginationElems = 5 + props.rangeSize * 2;

      let rangeStart =
        props.pagination.TotalPages <= minPaginationElems
          ? 1
          : props.pagination.CurrentPage - props.rangeSize;
      let rangeEnd =
        props.pagination.TotalPages <= minPaginationElems
          ? props.pagination.TotalPages
          : props.pagination.CurrentPage + props.rangeSize;

      rangeEnd =
        rangeEnd > props.pagination.TotalPages
          ? props.pagination.TotalPages
          : rangeEnd;
      rangeStart = rangeStart < 1 ? 1 : rangeStart;

      if (props.pagination.TotalPages > minPaginationElems) {
        const isStartBoundaryReached = rangeStart - 1 < 3;
        const isEndBoundaryReached = props.pagination.TotalPages - rangeEnd < 3;

        if (isStartBoundaryReached) {
          rangeEnd = minPaginationElems - 2;
          for (let i = 1; i < rangeStart; i++) {
            res.push(i);
          }
        } else {
          res.push(1);
          res.push("•••");
        }

        if (isEndBoundaryReached) {
          rangeStart = props.pagination.TotalPages - (minPaginationElems - 3);
          for (let i = rangeStart; i <= props.pagination.TotalPages; i++) {
            res.push(i);
          }
        } else {
          for (let i = rangeStart; i <= rangeEnd; i++) {
            res.push(i);
          }
          res.push("•••");
          res.push(props.pagination.TotalPages);
        }
      } else {
        for (let i = rangeStart; i <= rangeEnd; i++) {
          res.push(i);
        }
      }
      return res;
    });
    return {
      pages,
    };
  },
  methods: {
    updatePage(n: number) {
      if (n !== this.pagination.CurrentPage) {
        // eslint-disable-next-line vue/no-mutating-props
        this.pagination.CurrentPage = n;
        this.$emit("update-page", this.filterItems);
      }
    },
  },
});
