<!-- <template>
  <div aria-label="Loading..." role="status" class="loader">
    <svg class="icon" viewBox="0 0 256 256">
      <line
        x1="128"
        y1="32"
        x2="128"
        y2="64"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="24"
      ></line>
      <line
        x1="195.9"
        y1="60.1"
        x2="173.3"
        y2="82.7"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="24"
      ></line>
      <line
        x1="224"
        y1="128"
        x2="192"
        y2="128"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="24"
      ></line>
      <line
        x1="195.9"
        y1="195.9"
        x2="173.3"
        y2="173.3"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="24"
      ></line>
      <line
        x1="128"
        y1="224"
        x2="128"
        y2="192"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="24"
      ></line>
      <line
        x1="60.1"
        y1="195.9"
        x2="82.7"
        y2="173.3"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="24"
      ></line>
      <line
        x1="32"
        y1="128"
        x2="64"
        y2="128"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="24"
      ></line>
      <line
        x1="60.1"
        y1="60.1"
        x2="82.7"
        y2="82.7"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="24"
      ></line>
    </svg>
    <span class="loading-text ml-1">Loading...</span>
  </div>
</template> -->
<!-- 
<style scoped>
.loader {
  display: flex;
  align-items: center;
}

.icon {
  height: 2rem;
  width: 2rem;
  animation: spin 1s linear infinite;
  stroke: #202020;
}

.loading-text {
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 500;
  color: #202020;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style> -->

<template>
  <div class="loader">
    <div class="justify-content-center jimu-primary-loading"></div>
  </div>
</template>

<style scoped>
.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.jimu-primary-loading:before,
.jimu-primary-loading:after {
  position: absolute;
  top: 0;
  content: "";
}

.jimu-primary-loading:before {
  left: -19.992px;
}

.jimu-primary-loading:after {
  left: 19.992px;
  -webkit-animation-delay: 0.32s !important;
  animation-delay: 0.32s !important;
}

.jimu-primary-loading:before,
.jimu-primary-loading:after,
.jimu-primary-loading {
  background: #ed5151;
  -webkit-animation: loading-keys-app-loading 0.8s infinite ease-in-out;
  animation: loading-keys-app-loading 0.8s infinite ease-in-out;
  width: 13.6px;
  height: 32px;
}

.jimu-primary-loading {
  text-indent: -9999em;
  margin: auto;
  position: absolute;
  right: calc(50% - 6.8px);
  top: calc(50% - 16px);
  -webkit-animation-delay: 0.16s !important;
  animation-delay: 0.16s !important;
}

@-webkit-keyframes loading-keys-app-loading {
  0%,
  80%,
  100% {
    opacity: 0.75;
    box-shadow: 0 0 #ed5151;
    height: 32px;
  }

  40% {
    opacity: 1;
    box-shadow: 0 -8px #ed5151;
    height: 40px;
  }
}

@keyframes loading-keys-app-loading {
  0%,
  80%,
  100% {
    opacity: 0.75;
    box-shadow: 0 0 #ed5151;
    height: 32px;
  }

  40% {
    opacity: 1;
    box-shadow: 0 -8px #ed5151;
    height: 40px;
  }
}
</style>
