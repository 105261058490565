import { MediaType } from '@/globals/enums';
import { BaseEntity } from '../base.entity';

export class MediaEntity extends BaseEntity {
  Id!: string;
  Type!: MediaType;

  constructor(data: Partial<MediaEntity>) {
    super();
    Object.assign(this, data);
  }
}

export class DeleteMediaEntity<T extends BaseEntity> extends MediaEntity {
  FieldName!: keyof T;

  constructor(data: Partial<DeleteMediaEntity<T>>) {
    super(data);
    Object.assign(this, data);
  }
}
