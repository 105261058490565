export const toFormData = (data: any) => {
  const formData = new FormData();
  for (const key in data) {
    const element = data[key];

    console.log("key", key);
    console.log(element, " is array", element instanceof Array);
    console.log(element, " is Object", element instanceof Object);
    console.log(element, " is Object 2", typeof element === "object");
    console.log(element, " is string", element instanceof String);
    console.log(element, " is file", element instanceof File);

    if (element instanceof File) {
      formData.append(key, element);
    }
    // if the data field is an array
    else if (element instanceof Array) {
      element.forEach((obj: any, index: number) => {
        // if the data field is an array of objects
        if (obj instanceof Object && key != "storeCategoryId") {
          Object.keys(obj).forEach((objectKey: any) => {
            formData.append(`${key}[${index}][${objectKey}]`, obj[objectKey]);
          });
        }
        // if the data field is a simple string array
        else {
          formData.append(key, element[index]);
        }
      });
    }
    // if the data is a simple object and not a File
    else if (element instanceof Object && !(element instanceof File)) {
      for (const entry in element) {
        // formData.append(`${key}[${entry}]`, element[entry]);
        if (element[entry] instanceof Object) {
          const obj = element[entry];
          Object.keys(obj).forEach((objectKey: any) => {
            console.log(`${key}[${entry}][${objectKey}]`, obj[objectKey]);
            formData.append(`${key}[${entry}][${objectKey}]`, obj[objectKey]);
          });
        } else {
          formData.append(`${key}[${entry}]`, element[entry]);
        }
      }
    } else {
      formData.append(key, element);
    }
  }
  return formData;
};
