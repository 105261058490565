import { baseAPI } from "@/plugins";
import { FilterEntity, PaginationResEntity } from "../shared";
import { CategoryEntity } from "./category.entity";
import { StoreEntity } from "../store";
import { CategoryFilterEntity } from "./category-filter.entity";
import { Helpers } from "@/helper";
import { store } from "@/store";

class CategoryApi {
  async getAll(filter?: FilterEntity) {
    return await baseAPI.get(`/admin/category/all`);
  }
  async sequence(value: any) {
    return baseAPI.patch("/store/" + value.Id + "/categories-sequence", {
      TableCategoriesSequence: value.Categories,
    });
  }
  async getById(id: string) {
    return await baseAPI.get<CategoryEntity>(`/category?${id}`);
  }

  async getTableCategories(CategoryId: string, StoreId: string) {
    return baseAPI.get<Array<CategoryEntity>>(
      `/category/table/${CategoryId}/${StoreId}`
    );
  }
  async getAllByParentId(categories: CategoryEntity[], categoryId: string) {
    // return baseAPI.get<PaginationResEntity<CategoryEntity>>(
    //   `/category/by-parent-id/${ParentId}?${Helpers.filterToQueryString<CategoryEntity>(
    //     filter
    //   )}`
    // );
    console.log("get all by parent id", categories, "categoryId", categoryId);
    const filteredCategory = categories.find(
      (category: CategoryEntity) => category.id === categoryId
    )?.productCategory;
    console.log("filteredCategory", filteredCategory);
    return filteredCategory;
  }
  async add(cat: any) {
    return baseAPI.post("/admin/category/create", cat);
  }

  async addSub(cat: any, type: any) {
    console.log("Submitted category in add sub category", cat);
    console.log("Type", type);
    return baseAPI.post(`/admin/category/${type}/add/${cat.categoryId}`, cat);
  }
  async update(cat: CategoryEntity) {
    return baseAPI.put(`/admin/category/update/${cat.id}`, cat);
  }
  async addTableCategory(cat: CategoryEntity) {
    return baseAPI.post("/admin/category/table", cat);
  }
  async updateTableCategory(cat: CategoryEntity) {
    return baseAPI.put(`/category/table`, cat);
  }
  async delete(id: string) {
    return baseAPI.delete(`/admin/category/delete/${id}`);
  }
}

export const categoryservice = new CategoryApi();
