import { baseAPI } from "@/plugins";
import { Helpers } from "@/helper";
import { ItemFilterEntity } from "./item-filter.entity";
import { PaginationEntity, PaginationResEntity } from "../shared";
import { ItemEntity } from "./item.entity";
import { store } from "@/store";

class ItemApi {
  async getAll(storeId: string, filter?: ItemFilterEntity) {
    return await baseAPI.get<PaginationResEntity<ItemEntity>>(
      `/admin/store/all/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.user.AccessToken}`,
          "access-token": store.state.user.AccessToken,
        },
      }
    );
  }

  async getById(id: string) {
    return baseAPI.get<ItemEntity>(`/item/get-by-id/${id}`);
  }
  add(item: ItemEntity | FormData, storeId: string) {
    console.log("Add new product", item);
    try {
      return baseAPI.post(`/admin/store/product/add/${storeId}`, item);
    } catch (err) {
      console.log("Error adding product", err);
    }
  }
  update(item: ItemEntity | FormData, storeId: string, productId: string) {
    console.log("Update product", item);
    return baseAPI.put(
      `/admin/store/product/update/${storeId}/${productId}`,
      item
    );
  }
}

export const itemservice = new ItemApi();
