import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, mergeProps as _mergeProps, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["v-model", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      id: _ctx.id,
      class: "flex flex-row form-filter",
      style: {'align-items': 'center'}
    }, [
      (_ctx.isSelected)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "absolute rounded-md bg-white p-2 shadow-lg w-48 z-20 custom-select",
            style: _normalizeStyle({'top': `${_ctx.totalHeight}px`})
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, ({
                    Name,
                    FirstName,
                    LastName,
                    Value,
                    }, idx) => {
              return (_openBlock(), _createElementBlock("option", _mergeProps({
                id: "store",
                class: "min-height list-link",
                key: idx
              }, _ctx.$attrs, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleSelect(_ctx.Type), _ctx.filterItems(_ctx.Type, $event), _ctx.toggleIcon()))
              }), _toDisplayString(Value || Name || (FirstName +' '+ LastName)), 17))
            }), 128))
          ], 4))
        : _createCommentVNode("", true),
      _createElementVNode("input", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleSelect(_ctx.Type))),
        "v-model": _ctx.c,
        type: "text",
        class: "input-style",
        placeholder: _ctx.placeholder,
        style: _normalizeStyle({'width': `${_ctx.maxWidth}`})
      }, null, 12, _hoisted_2),
      (_ctx.closeIcon)
        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 1,
            id: "Status",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.close(_ctx.Type, null))),
            icon: "fa-xmark",
            size: "lg",
            style: {'position': 'relative', 'right': 50, 'cursor':'pointer'}
          }))
        : _createCommentVNode("", true)
    ], 8, _hoisted_1)
  ]))
}