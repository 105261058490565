import { baseAPI } from '@/plugins';
import { PaginationResEntity } from '../shared';
import { StoreUserFilterEntity } from './store-user-filter.entity';
import { StoreUserEntity } from './store-user.entity';
import {Helpers} from '@/helper'

class StoreUserApi {
  async getAll(filter: StoreUserFilterEntity) {
    return await baseAPI.get<PaginationResEntity<StoreUserEntity>>(
      `/store-user?${Helpers.filterToQueryString<StoreUserEntity>(filter)}`
    );
  }

  async getOne(id: string) {
    return await baseAPI.get<StoreUserEntity>(`/store-user/get-one/${id}`);
  }

  async inviteCustomer(storeUser: StoreUserEntity) {
    return await baseAPI.post('/store-user/invite-customer', storeUser);
  }

  async sendInvitationAgain(id: string) {
    return await baseAPI.get(`/store-user/send-invitation-again/${id}`);
  }

  async updateRole(storeUser: StoreUserEntity) {
    return await baseAPI.patch('/store-user/update-role', storeUser);
  }

  async delete(id: string) {
    return await baseAPI.delete(`/store-user/${id}`);
  }
}

export const storeUser = new StoreUserApi();
