import { baseAPI } from "@/plugins/axios";
import { UserFilterEntity, UserEntity, PaginationResEntity } from "@/utils";
import { Helpers } from "@/helper";
import { store } from "@/store";

class UserApi {
  async getAll(filter?: UserFilterEntity) {
    const respons = await baseAPI.get<PaginationResEntity<UserEntity>>(
      `/admin/user/?${Helpers.filterToQueryString<UserEntity, UserFilterEntity>(
        filter
      )}`
    );
    return respons;
  }

  async getById(id: string) {
    const respons = await baseAPI.get<UserEntity>(`/admin/search/${id}`);
    return respons;
  }

  async getCustomerByEmail(Email: string) {
    return await baseAPI.post<UserEntity>(`/user/get-customer-by-email`, {
      Email,
    });
  }
}

export const userservice = new UserApi();
