import { ItemEntity } from '../item';
import { OrderItemExtraEntity } from './order-item-extra.entity';
import { OrderItemOptionEntity } from './order-item-option.entity';

export class OrderItemEntity {
  OrderId: string | null = null;
  ItemId: string | null = null;
  OrderItemOption = new OrderItemOptionEntity();
  Item = new ItemEntity();
  OrderItemExtras: Array<OrderItemExtraEntity> = [];
}
