import { COUNTRIES } from "../../helper/constants";
import { BaseEntity } from "../base.entity";

export class AddressEntity extends BaseEntity {
  name: string | null = null;
  street: string | null = null;
  number: string | null = null;
  city: string | null = null;
  zip: string | null = null;
  // country: string | null = COUNTRIES.find(({ Extra }) => Extra?.IsDefault)!
  //   .Value;
  country: string | null = COUNTRIES.find(({ extra }) => extra?.isDefault)!
    .value;
  additionalInformation: string | null = "";
  isDefault = false;

  constructor(data?: Partial<AddressEntity>) {
    super();
    Object.assign(this, data);
  }
}
