
import { defineComponent, ref, PropType } from "vue";
import { useRoute } from "vue-router";
import FileInputView from "../../../../../components/base/file-input/FileInputView.vue";
import { store } from "@/store";
import {
  CategoryEntity,
  ItemEntity,
  itemservice,
  MediaEntity,
  mediaservice,
  ItemOptionExtraEntity,
  categoryservice,
  CategoryFilterEntity,
  ItemFilterEntity,
} from "@/utils";
import AutoComplete from "@/components/AutoComplete.vue";
import { CategoryType, MediaType, Ingredients } from "@/globals/enums";
import { Helpers } from "@/helper";
import { toFormData } from "@/services/shared/formdata.service";

export default defineComponent({
  name: "AddProduct",
  components: { AutoComplete, FileInputView },
  props: {
    categoryId: {
      type: String,
    },
    data: {
      type: String,
    },
  },
  data: () => {
    return {
      MediaType,
    };
  },
  setup(props, ctx) {
    const route = useRoute();
    const Store = ref({}) as any;
    const selectedCategory = ref({}) as any;
    // const item = ref();
    const id = route.params.id as any;
    const itemId = route.params.itemId as any;
    const query = [] as any;
    const ItemFilter = new ItemFilterEntity({ ...query, Limit: 100000 });
    const ingredients = Helpers.enumToItems(Ingredients);
    const Categories = ref([] as CategoryEntity[]);
    const Items = [
      {
        id: null as any,
      },
    ] as Array<ItemEntity>;
    const item = new ItemEntity();
    const product = ref([] as any);
    const value = [] as any;
    const inputs = [] as any;
    const productCategory = ref({}) as any;
    const productImage = ref(null as File | null | any);
    const itemOptions = ref([] as ItemOptionExtraEntity[]);
    const itemExtras = ref([] as ItemOptionExtraEntity[]);
    const editOn = ref(false);

    return {
      Store,
      selectedCategory,
      id,
      ItemFilter,
      itemId,
      ingredients,
      Categories,
      Items,
      item,
      value,
      inputs,
      productCategory,
      product,
      submittedIngredients: ref([] as any),
      itemOptions,
      itemExtras,
      productImage,
      editOn,
    };
  },

  async mounted() {
    console.log("Store Deatils in products", store.state.storeDetails);
    console.log("ItemEntity", this.item);
    this.Store.value = store.state.storeDetails;
    // check if route has product id param
    this.$route.params.productId ? (this.editOn = true) : (this.editOn = false);
    // if product id is present then fetch product details
    await this.loadCats();

    if (this.$route.params.productId) {
      this.editOn = true;
      this.itemId = this.$route.params.productId;
      this.item = this.Store.value.product.find(
        (i: any) => i.id === this.$route.params.productId
      );
      this.item.ingredients.forEach((ingredient: string) => {
        const ingr = this.ingredients.find((i: any) => i.id === ingredient);
        if (ingr) {
          this.submittedIngredients.push(ingr);
        }
      });
      this.productImage = this.item.image;
      this.product = this.productCategory.find(
        (i: any) => i.id === this.item.categoryId
      );
      this.itemExtras = this.item.extra || [];
      this.itemOptions = this.item.options || [];
      console.log("this.item", this.item);
    } else {
      this.editOn = false;
    }
    this.Categories = store.state.storeDetails.productCategories;
    console.log("ingredients", this.ingredients);
    console.log("this.Categories", this.Categories);

    this.selectedCategory = this.inputs[0];
  },
  methods: {
    async getItemAll(id: string, filter: any) {
      const respons = await itemservice.getAll(
        this.id,
        new ItemFilterEntity(filter)
      );
      this.Items = respons.data.Data as any;
      console.log("items", this.Items);
      return respons.data;
    },

    async loadCats() {
      console.log("In store Storedetails", store.state.storeDetails);
      this.Categories = await categoryservice
        .getAll()
        .then((cat: any) => cat.data.data);
      console.log("categories", this.Categories);

      if (this.Categories.length) {
        console.log("Categories not found", this.Categories);
        this.productCategory = await categoryservice.getAllByParentId(
          this.Categories,
          store.state?.storeDetails?.categoryId
        );

        console.log("productCategory", this.productCategory);
      }
    },
    async save() {
      try {
        const item: ItemEntity = JSON.parse(JSON.stringify(this.item));
        item.storeId = this.$route.params.id as any;
        item.options = this.itemOptions;
        item.extra = this.itemExtras;
        item.productCategoryId = this.product.id;
        item.ingredients = this.submittedIngredients.map((i: any) => i.id);
        console.log("item", item);

        console.log("product image is", this.productImage);

        const newProduct = ref({} as any);

        newProduct.value.name = item.name;
        newProduct.value.from = item.from || "07:00";
        newProduct.value.to = item.to || "23:00";
        newProduct.value.storeId = item.storeId;
        newProduct.value.productCategoryId = item.productCategoryId;
        newProduct.value.image = this.productImage;
        newProduct.value.ingredients = item.ingredients;
        newProduct.value.options = item.options;
        newProduct.value.extra = item.extra;

        console.log("new product", newProduct.value);

        // const formData = new FormData();

        // for (const key in newProduct.value) {
        //   const element = newProduct.value[key];
        //   console.log("element", element);
        //   console.log("key", key);
        //   console.log("Element is array", element instanceof Array);

        //   if (element instanceof Array) {
        //     element.forEach((obj: any, index: number) => {
        //       if (obj instanceof Object) {
        //         Object.keys(obj).forEach((objectKey: any) => {
        //           formData.append(
        //             `${key}[${index}][${objectKey}]`,
        //             obj[objectKey]
        //           );
        //         });
        //       } else {
        //         formData.append(key, element[index]);
        //       }
        //     });
        //   } else {
        //     formData.append(key, element);
        //   }
        // }

        const formData = toFormData(newProduct.value);

        console.log("Save new product", item);
        // formData.forEach((key, value) =>
        //   console.log("form data element", key, value)
        // );
        // show contents of formData
        try {
          if (!item?.id) {
            // await itemservice.add(item, item.storeId!);
            // await itemservice.add(formData, item.storeId!);
            const res = await itemservice.add(formData, item.storeId!);
            console.log("Add product response", res);
          } else {
            formData.append(
              "removeImage",
              (newProduct.value.image instanceof File).toString()
            );
            const res = await itemservice.update(
              formData,
              item.storeId!,
              this.itemId
            );
            console.log("Update product response", res);
          }
        } catch (error) {
          console.log("Error adding product", error);
        }

        console.log("Photo", item.photo);

        const id = Date.now();
        this.$notify({
          id,
          type: "success",
          duration: 3000,
          text: `${!item?.id ? "added" : "updated"}`,
        });
        // this.$router.push("/stores");
        this.$router.push(`/store/${this.$route.params.id}/product`);
      } catch (e) {
        const id = Date.now();
        this.$notify({
          id,
          type: "error",
          duration: 3000,
          text: e as any,
        });
      }
    },
    productImageSubmitted(file: any) {
      this.item.photo = file;
      this.productImage = file;
      console.log("product image PRoductImage", this.productImage);
      console.log("product image file", file);
    },
    addIngredient(ingredient: any) {
      console.log("Submitted ingredients", this.submittedIngredients);
      console.log("item.ingredients", this.item.ingredients);
    },
    addOption() {
      this.itemOptions.push(new ItemOptionExtraEntity());
      console.log("itemOptions", this.itemOptions);
    },
    deleteOption(idx: number) {
      this.itemOptions.splice(idx, 1);
    },

    addExtra() {
      this.itemExtras.push(new ItemOptionExtraEntity());
      console.log("itemExtras", this.itemExtras);
    },
    deleteExtra(idx: number) {
      this.itemExtras.splice(idx, 1);
    },

    onSelect(option: any) {
      this.inputs = [];
      this.inputs.push(option.Id);
      this.item.productCategoryId = option.Id;
    },
  },
});
