import { CategoryEntity, categoryservice } from '@/utils'
import {defineComponent} from 'vue'


export default defineComponent({
    name: 'tableCategory',
    props: {
        items: {
            type: Object
        }
    },
    data: () => {
        return {
            totalHeight: null as any,
            isSelected: false as any,
            inputes: [] as any,
            category: CategoryEntity
        }
    },
    methods: {
        async addCategory() {
            try {
                const respons = await (await categoryservice.addTableCategory(this.items as any)).data
                const id = Date.now()
                this.$notify({
                    id,
                    type: 'success',
                    duration: 3000,
                    text: 'succesfully created'
                })
                this.$emit('submit', [respons].map((r:any) => ({ ...r, Children: []}))[0])
                this.close()
            } catch (e) {
                const id = Date.now()
                this.$notify({
                    id,
                    type: 'error',
                    duration: 3000,
                    text: e as any
                })
            }
        },
        close() {
            this.$emit('close')
        },
        getPosition() {
            const store = document.getElementById('role')
            this.totalHeight = store?.offsetHeight as any + store?.offsetTop as any
        },
        addInputes (value:any) {
            this.inputes = []
            this.inputes.push(value)
            console.log('Input',this.inputes)
        },
        toggleSelect() {
            this.isSelected = !this.isSelected
            console.log('selec',this.isSelected)
        },
    }
})