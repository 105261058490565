import router from "@/router";
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { createApp } from "vue";
import { createStore, Store } from "vuex";
import App from "./App.vue";
import { UserEntity } from "@/utils";

// const user = JSON.parse(localStorage.getItem('user') || '{}');
// const initialState = user
//   ? { status: { loggedIn: true }, user }
//   : { status: { loggedIn: false }, user: null };
export const store = createStore({
  state: {
    undefined,
    user: ({} as UserEntity) || null,
    Store: {} as any,
    storeDetails: {} as any,
  },
  getters: {
    loggedIn(state: any) {
      return state.user !== undefined && state.user !== null;
    },
  },
  mutations: {
    login(state: any, user: any) {
      console.log("Login mutation");
      console.log("user in login mutation", user);
      state.user = user;
      localStorage.setItem("user", JSON.stringify(user));
      console.log("user in login mutation", user);
      state.AccessToken = user.data.accessToken;
    },
    setAccessToken(state: any, token: any) {
      state.AccessToken = token;
      console.log("Access token updated", token);
    },
    setStore(state: any, store: any) {
      state.Store = store;
      console.log("Store updated", store);
    },
    setStoreDetails(state: any, storeDets: any) {
      const stringForm = JSON.stringify(storeDets);
      console.log("String form", stringForm);
      state.storeDetails = JSON.parse(stringForm);
      console.log("Store Details updated", storeDets);
    },
    logout(state: any) {
      state.user = undefined;
      localStorage.removeItem("user");
      localStorage.removeItem("vuex");
      router.push({ name: "login" });
    },
    RefreshToken(state, AccessToken) {
      state.user.loggedIn = true;
      state.user = { ...state.user, AccessToken: AccessToken };
    },
  },
  actions: {
    RefreshToken({ commit }, AccessToken) {
      commit("RefreshToken", AccessToken);
    },
  },
  modules: {},
  plugins: [createPersistedState()],
});
