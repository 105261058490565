import { baseAPI } from "@/plugins/axios";
import { store } from "../../store";
import { UserEntity } from "../user/user.entity";

class AuthService {
  async getProfile() {
    const response = await baseAPI.get("/admin/dashboard", {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.state.AccessToken}`,
        "access-token": store.state.AccessToken,
      },
    });
    return response;
  }

  async updateProfile(data: UserEntity) {
    const response = await baseAPI.put("/admin/update/", data);
    return response;
  }
}

export const apiProfile = new AuthService();
