import { config } from "@/plugins";
import { AxiosResponse } from "axios";
import { baseAPI } from "@/plugins/axios";

export async function getSignedUrl(key: any) {
  console.log("Image key", key);
  if (key === null || key === "" || key === undefined) return null;
  try {
    // const response: AxiosResponse = await baseAPI.get(
    //   `https://${config.apiUrl}/getSignedUrl?key=${key}`
    // );
    console.log("Image key", key);
    console.log("Image url", `${config.apiUrl}/${key}`);
    const response: AxiosResponse = await baseAPI.get(
      `${config.apiUrl}/${key}`
    );
    console.log("Response from getSignedUrl: ", response);
    return response.data.data;
    // return response.data.data.split("?")[0];
  } catch (e) {
    console.log("Error in getSignedUrl: ", e);
  }
}
