import { baseAPI } from "@/plugins/axios";
import { defineComponent, ref, onMounted } from "vue";
import { UserEntity } from "../../utils/user/user.entity";
import { userservice } from "@/utils";
import { store } from "../../store";
import { StoreStatus } from "@/globals/enums";
import AutoComplete from "@/components/AutoComplete.vue";

const result = [] as any;
export default defineComponent({
  name: "ProfileView",
  props: ["showOtp"],
  components: { AutoComplete, UserEntity },
  data: () => {
    return {
      res: {} as any,
      result,
      statusOptions: ref([] as any[]),
      filter: {
        name: "",
        email: "",
        phone: "",
        role: "",
        status: "",
      },
      user: {
        firstName: "",
        lastName: "",
        street: null,
        no: null,
        address: [
          {
            zip: null,
            description: null,
            street: null,
            country: null,
            city: null,
            lat: null,
            lng: null,
            number: null,
          },
        ],
      } as UserEntity | any,
      users: [] as UserEntity[],
      UserEntity,
    };
  },
  setup(props) {
    const otp = ref("");
    return { otp };
  },
  mounted() {
    this.getUsers();
    this.getStatus();
  },
  methods: {
    async getUsers() {
      const response = await userservice.getAll();
      console.log("fetch all users response", response);
      this.result = response.data;
      // this.users = this.result.data.forEach((user: UserEntity) => {
      //   user;
      //   // if(user.status?.toLowerCase() === )
      // });
      this.users = this.result.data;

      console.log("userrr", this.users);
    },
    async getStatus() {
      console.log("get status");
      this.statusOptions = [
        { name: "Active", value: "active" },
        { name: "Disabled", value: "disabled" },
      ];
      console.log("status options", this.statusOptions);
    },
    async changeStatus(id: string, status: string) {
      // const response = await userservice.changeStatus(id, status);
      // console.log("change status response", response);
      // this.getUsers();
    },
    convertDate(date: string) {
      return new Date(date).toLocaleDateString().split("/").join(".");
    },
  },
});
