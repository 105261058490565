import {defineComponent} from 'vue'
import { Helpers } from '@/helper'
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, TransitionRoot } from '@headlessui/vue';

export default defineComponent({
    name: 'v-select',
    components: { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, TransitionRoot },
    data: () => {
        return {
            helpers: Helpers,
            totalHeight: null as any,
            offsetLeft: null as any,
            isSelected: false as any,
            storeStatus: { Id: '', Value: ''},
            statusQuery: '',
            Id: null as any,
        }
    },
    computed: {
        filteredItems: function(): any {
            return this.statusQuery === '' ?
                this.items :
                this.items.filter((status: any) => {
                    return status.Value.toLowerCase()
                        .replace(/-|\s+/g, '')
                        .includes(this.statusQuery.toLowerCase().replace(/-|\s+/g, ''))
                })
        }
    },
    props: {
        id: {
            type: String
        },
        items: {
            type: Array,
            default: () => []
        },
        item: {
            type: String
        }
    },
    mounted() {
        this.storeStatus = {
            Id: (this.item as string),
            Value: (this.item as string)
        }
    },
    methods: {
        closeOption(){
            this.storeStatus = { Id: '', Value: ''}
            this.statusQuery = ''
        },
        findStatus() {
            console.log(this.storeStatus)
            this.Id = this.storeStatus.Id
            this.$emit('submit', this.storeStatus.Value, this.Id)
        },
    }
})