<template>
  <div class="flex h-[66px] justify-between p-5 pb-3">
    <div>
      <h1 class="h1">Coupons</h1>
    </div>
  </div>
  <div class="flex gap-2 justify-between overflow-x-auto px-5 py-3">
    <div class="flex gap-2">
      <router-link to="/coupon/add" class="button">
        <span>+ Add Coupon</span>
      </router-link>
    </div>
    <div class="flex gap-2">
      <select class="form-filter w-48">
        <option selected="" class="w-96">Assigned</option>
        <option value="US">United States</option>
        <option value="CA">Canada</option>
        <option value="FR">France</option>
        <option value="DE">Germany</option>
      </select>
      <select class="form-filter w-48">
        <option selected="" class="w-96">Creator</option>
        <option value="US">United States</option>
        <option value="CA">Canada</option>
        <option value="FR">France</option>
        <option value="DE">Germany</option>
      </select>
      <select class="form-filter w-48">
        <option selected="" class="w-96">Coupon Type</option>
        <option value="US">United States</option>
        <option value="CA">Canada</option>
        <option value="FR">France</option>
        <option value="DE">Germany</option>
      </select>
      <select class="form-filter w-48">
        <option selected="" class="w-96">Status</option>
        <option value="US">United States</option>
        <option value="CA">Canada</option>
        <option value="FR">France</option>
        <option value="DE">Germany</option>
      </select>
    </div>
  </div>
  <div class="flex flex-col gap-2 overflow-x-auto p-5">
    <div class="box-head-blank justify-between">
      <div class="flex gap-4 items-center">
        <div class="w-36"><span class="text-order">Coupon Code</span></div>
        <div class="flex justify-center w-40">
          <span class="text-order">Creator</span>
        </div>
        <div class="flex justify-center w-36">
          <span class="text-order">Assign</span>
        </div>
        <div class="flex justify-center w-24">
          <span class="text-order">Min Subtotal</span>
        </div>
        <div class="flex justify-center w-24">
          <span class="text-order">Coupon Type</span>
        </div>
        <div class="flex justify-center w-24">
          <span class="text-order">Discount</span>
        </div>
        <div class="flex justify-center w-24">
          <span class="text-order">Expire</span>
        </div>
        <div class="flex justify-center w-24">
          <span class="text-order">Usage Limit</span>
        </div>
        <div class="flex justify-center w-24">
          <span class="text-order">Used</span>
        </div>
      </div>
      <div class="flex gap-4 justify-end">
        <div class="flex justify-center w-36">
          <span class="text-order">Status</span>
        </div>
        <div class="flex justify-end w-9"></div>
      </div>
    </div>
    <div class="box-blank justify-between min-w-max">
      <div class="flex gap-4 items-center">
        <div class="w-36">
          <p class="text-title">1212331</p>
        </div>
        <div class="flex justify-center w-40">
          <p class="text truncate">Influ Encer</p>
        </div>
        <div class="flex flex-col items-center justify-items-center w-36">
          <div class="button-coupons"><span>New York Store</span></div>
          <span class="text-mini">(+ 4 others)</span>
        </div>
        <div class="flex justify-center w-24">
          <span class="text">1,11 &euro;</span>
        </div>
        <div class="flex justify-center w-24">
          <div class="button-coupons uppercase"><span>Percentage</span></div>
        </div>
        <div class="flex justify-center w-24">
          <span class="text">10%</span>
        </div>
        <div class="flex justify-center w-24">
          <span class="text">Never</span>
        </div>
        <div class="flex justify-center w-24">
          <span class="text">Unlimited</span>
        </div>
        <div class="flex justify-center w-24">
          <div class="button-coupons"><span>8</span></div>
        </div>
      </div>
      <div class="flex gap-4 items-center">
        <div class="flex justify-center w-36">
          <div class="button-status w-28">
            <div><span>Active</span></div>
            <div>
              <svg
                width="12"
                viewBox="0 0 13 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                height="8"
              >
                <path
                  d="M12 1L6.5 7L1 1"
                  stroke="#515151"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="flex justify-end w-9">
          <a href="#" class="button-nav">
            <svg
              width="14"
              height="14"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.0636 11.1392H11.4782"
                stroke="#515151"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.47478 1.00552C6.94691 0.441251 7.79563 0.35851 8.3716 0.821044C8.40345 0.846138 9.42663 1.64099 9.42663 1.64099C10.0594 2.0235 10.256 2.83666 9.86484 3.45722C9.84407 3.49045 4.05943 10.7262 4.05943 10.7262C3.86698 10.9663 3.57484 11.108 3.26263 11.1114L1.04735 11.1392L0.548225 9.02662C0.478306 8.72956 0.548225 8.41759 0.740677 8.17751L6.47478 1.00552Z"
                stroke="#515151"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.40393 2.34833L8.72269 4.89701"
                stroke="#515151"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
    <div class="box-blank justify-between min-w-max">
      <div class="flex gap-4 items-center">
        <div class="w-36">
          <p class="text-title">MYCODE2022</p>
        </div>
        <div class="flex justify-center w-40">
          <p class="text truncate">Somename Somerurename</p>
        </div>
        <div class="flex flex-col items-center justify-items-center w-36">
          <div class="button-coupons">
            <span>Prishtina Store</span>
          </div>
          <span class="text-mini">(+ 4 others)</span>
        </div>
        <div class="flex justify-center w-24">
          <span class="text">10,00 &euro;</span>
        </div>
        <div class="flex justify-center w-24">
          <div class="button-coupons uppercase"><span>Percentage</span></div>
        </div>
        <div class="flex justify-center w-24">
          <span class="text">10%</span>
        </div>
        <div class="flex justify-center w-24">
          <span class="text">12.12.2022</span>
        </div>
        <div class="flex justify-center w-24">
          <span class="text">Once per user</span>
        </div>
        <div class="flex justify-center w-24">
          <div class="button-coupons"><span>201</span></div>
        </div>
      </div>
      <div class="flex gap-4 items-center">
        <div class="flex justify-center w-36">
          <div class="button-status w-28">
            <div><span>Inactive</span></div>
            <div>
              <svg
                width="12"
                viewBox="0 0 13 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                height="8"
              >
                <path
                  d="M12 1L6.5 7L1 1"
                  stroke="#515151"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="flex justify-end w-9">
          <a href="#" class="button-nav">
            <svg
              width="14"
              height="14"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.0636 11.1392H11.4782"
                stroke="#515151"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.47478 1.00552C6.94691 0.441251 7.79563 0.35851 8.3716 0.821044C8.40345 0.846138 9.42663 1.64099 9.42663 1.64099C10.0594 2.0235 10.256 2.83666 9.86484 3.45722C9.84407 3.49045 4.05943 10.7262 4.05943 10.7262C3.86698 10.9663 3.57484 11.108 3.26263 11.1114L1.04735 11.1392L0.548225 9.02662C0.478306 8.72956 0.548225 8.41759 0.740677 8.17751L6.47478 1.00552Z"
                stroke="#515151"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.40393 2.34833L8.72269 4.89701"
                stroke="#515151"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
    <div class="flex gap-2 items-center justify-end p-3">
      <div class="button-pagination">
        <span>Prev</span>
      </div>
      <div class="button-pagination-page-inactive">
        <span>1</span>
      </div>
      <div class="button-pagination-page-inactive">
        <span>2</span>
      </div>
      <div class="button-pagination-page-active">
        <span>3</span>
      </div>
      <div class="button-pagination-page-inactive">
        <span>4</span>
      </div>
      <div class="button-pagination-page-inactive">
        <span>5</span>
      </div>
      <div class="button-pagination-page-inactive">
        <span>...</span>
      </div>
      <div class="button-pagination-page-inactive"><span>20</span></div>
      <div class="button-pagination">
        <span>Next</span>
      </div>
    </div>
  </div>
</template>
