import { CategoryType } from "@/globals/enums";
import { BaseEntity, TableEntity } from "..";
import { StoreCategoryEntity } from "../store/store-category.entity";

// export class CategoryEntity extends BaseEntity {
//   Id: string | null = null;
//   Name: string | null = null;
//   Type: CategoryType | null = null;
//   StoreId?: string | null = null;
//   ParentId?: string | null = null;
//   Parent?: CategoryEntity | null = null;
//   Children: Array<CategoryEntity | TableEntity> = [];
//   EnableTables = false;
//   Photo: string | null = null;

//   constructor(data?: Partial<CategoryEntity>) {
//     super();
//     Object.assign(this, data);
//   }
// }

export class CategoryEntity extends BaseEntity {
  id: string | null = null;
  name: string | null = null;
  image: string | null = null;
  count: number | null = 0;
  categoryType: CategoryType | null = null;
  StoreId?: string | null = null;
  ParentId?: string | null = null;
  disabled: boolean | null = false;
  Parent?: CategoryEntity | null = null;
  productCategory: Array<CategoryEntity | TableEntity> = [];
  storeCategory: Array<StoreCategoryEntity | TableEntity> = [];
  enableTables = false;
  status: string | null = null;
  updatedAt: string | null = null;
  constructor(data?: Partial<CategoryEntity>) {
    super();
    Object.assign(this, data);
  }
}
