import { store } from "@/store";
import dashboard from "@/views/admin/dashboard/DashboardView.vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import login from "../views/auth/LoginView.vue";
import appview from "../components/AppView.vue";
import coupon from "@/views/coupons/CouponView.vue";
import StoresView from "@/views/stores/StoresView.vue";
import orders from "@/views/orders/OrderView.vue";
import orderDetail from "@/views/orders/_id/OrderDetailView.vue";
import storeDetail from "@/views/stores/_id/store/StoreDetailView.vue";
import AddProduct from "@/views/stores/_id/product/_add/index.vue";
import EditProduct from "@/views/stores/_id/product/_id/index.vue";
import TableView from "@/views/stores/_id/table/index.vue";
import member from "@/views/stores/_id/member/index.vue";
import _add from "@/views/stores/_id/member/_add/index.vue";
import ProductView from "@/views/stores/_id/product/index.vue";
import editStore from "@/views/stores/_id/store/EditStoreView.vue";
import AddStore from "@/views/stores/_id/store/AddStoreView.vue";
import finance from "@/views/finance/FinanceView.vue";
import profile from "@/views/profile/ProfileView.vue";
import UsersView from "@/views/users/UsersView.vue";

const routes = [
  {
    path: "/",
    name: "appview",
    component: appview,
    beforeEnter: (to: any, from: any, next: any) => {
      if (!store.state.AccessToken) {
        console.log("No user");
        next({ name: "login" });
      } else next();
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: dashboard,
      },
      {
        path: "/stores",
        name: "stores",
        component: StoresView,
      },
      {
        path: "/order",
        name: "orders",
        component: orders,
      },
      {
        path: "/order/:id",
        name: "OrderDetailView",
        component: orderDetail,
      },
      {
        path: "/users",
        name: "UsersView",
        component: UsersView,
      },
      {
        path: "/users/add",
        name: "AddUser",
        component: () => import("../views/users/_add/index.vue"),
      },
      {
        path: "/store/:id",
        name: "StoreDetailView",
        // component: () =>
        //   import("../views/stores/_id/store/StoreDetailView.vue"),
        component: storeDetail,
      },
      {
        path: "/store/:id/product/",
        name: "Product",
        component: ProductView,
        props: true,
      },

      {
        path: "/store/:id/product/:productId",
        name: "EditProduct",
        component: AddProduct,
        props: true,
      },
      {
        path: "/store/:id/product/add-product",
        name: "AddProduct",
        component: AddProduct,
        props: true,
      },
      {
        path: "/store/:id/product/:productId/edit",
        name: "EditProduct",
        component: AddProduct,
        props: true,
      },
      {
        path: "/store/:id/table",
        name: "Table",
        component: TableView,
        props: true,
      },
      {
        path: "/store/:id/member",
        name: "member",
        component: member,
      },
      {
        path: "/store/:id/member/add-member",
        name: "addMember",
        component: _add,
      },
      {
        path: "/store/:id/member/:memberId/edit",
        name: "editMember",
        component: () => import("../views/stores/_id/member/_id/index.vue"),
      },
      {
        path: "/stores/add",
        name: "StoreAdd",
        component: AddStore,
      },
      {
        path: "/store/:id/edit",
        name: "EditStoreView",
        component: AddStore,
      },
      {
        path: "/finance",
        name: "finance",
        component: finance,
      },
      {
        path: "/coupon",
        name: "coupon",
        component: coupon,
      },
      {
        path: "/coupon/add",
        name: "add-coupon",
        component: () => import("../views/coupons/_add/index.vue"),
      },
      {
        path: "/sliders",
        name: "sliders",
        component: () => import("../views/sliders/SlidersView.vue"),
      },
      {
        path: "/sliders/add",
        name: "AddSliders",
        component: () => import("../views/sliders/_add/index.vue"),
      },
      {
        path: "/profile",
        name: "profile",
        component: profile,
      },
      {
        path: "/categories",
        name: "Categories",
        component: () => import("../views/categories/CategoriesView.vue"),
      },
      {
        path: "/categories/:id/sub",
        name: "SubCategories",
        component: () => import("../views/categories/SubCategories.vue"),
      },
      {
        path: "/category/add",
        name: "CategoryAdd",
        component: () => import("../views/categories/_add/index.vue"),
      },
      {
        path: "/category/add-sub",
        name: "CategorySubAdd",
        component: () => import("../views/categories/_add_sub/index.vue"),
      },
    ],
  },
  {
    path: "/",
    name: "login",
    component: login,
  },
  {
    path: "/adminlogin",
    name: "adminlogin",
    component: login,
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "forgotPassword" */ "../views/auth/ForgotPasswordView.vue"
      ),
  },
  {
    path: "/forgot-password-otp",
    name: "forgotPasswordOtp",
    component: () =>
      import(
        /* webpackChunkName: "forgotPasswordOtp" */ "../views/auth/ForgotPasswordOtpView.vue"
      ),
  },
  {
    path: "/forgot-password-new",
    name: "forgotPasswordNew",
    component: () =>
      import(
        /* webpackChunkName: "forgotPasswordNew" */ "../views/auth/ForgotPasswordNewView.vue"
      ),
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
