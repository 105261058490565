import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-notification success" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "my-notification error" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_notifications = _resolveComponent("notifications")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_notifications, {
      position: "bottom center",
      width: "250"
    }, {
      body: _withCtx((props) => [
        _withDirectives(_createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "error-text",
            innerHTML: props.item.text
          }, null, 8, _hoisted_2),
          _createElementVNode("button", {
            class: "close-btn",
            onClick: ($event: any) => (_ctx.$notify.close(props.item.id))
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: "fa-xmark",
              size: "lg"
            })
          ], 8, _hoisted_3)
        ], 512), [
          [_vShow, props.item.type === 'success']
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: "error-text",
            innerHTML: props.item.text
          }, null, 8, _hoisted_5),
          _createElementVNode("button", {
            class: "close-btn",
            onClick: ($event: any) => (_ctx.$notify.close(props.item.id))
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: "fa-xmark",
              size: "lg"
            })
          ], 8, _hoisted_6)
        ], 512), [
          [_vShow, props.item.type === 'error']
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_router_view, { class: "td-background" })
  ], 64))
}