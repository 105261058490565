import { DeliveryType, OrderStatus } from '../../globals/enums';
import { OrderEntity } from './order.entity';
import { FilterEntity } from '../shared/filter.entity';

export class OrderFilterEntity extends FilterEntity<OrderEntity> {
  DeliveryType: DeliveryType | null = null;
  Status: OrderStatus | null = null;
  StoreId: string | null = null;
  IsOpen: true | null = null;
  CreatedAfter: string | null = null;
  CreatedBefore: string | null = null;
  DriverId: string | null = null;
  CustomerId: string | null = null;

  constructor(data?: Partial<OrderFilterEntity>) {
    super();
    Object.assign(this, data);
  }
}
