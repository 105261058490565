import {Ref} from 'vue'
export const config = {
    mediaUrl: process.env.VUE_APP_MEDIA_URL,
    apiUrl: process.env.VUE_APP_API_URL
}

export interface SpinConfig {
    logoColor?:string
    size?:string
    bgColor?:string
    opacity?:number
}

export declare function show(targetRef: Ref<Element>, config?: SpinConfig): void

export declare function hide(targetRef: Ref<Element>): void
