import {
  defineComponent,
  ref,
  computed,
  reactive,
  toRaw,
  onMounted,
} from "vue";
import { differenceInMinutes, quartersInYear, isToday } from "date-fns";
import MobileMenu from "../../../components/MobileMenu.vue";
import { config } from "../../../plugins/config";
import {
  OrderFilterEntity,
  OrderEntity,
  PaginationEntity,
  StoreEntity,
  StoreFilterEntity,
  UserFilterEntity,
  UserEntity,
  orderservice,
  storeservice,
  userservice,
} from "@/utils";
import { useI18n } from "vue-i18n";
import LocaleSwitcher from "../../../components/Locale-Switcher.vue";
import { Helpers } from "@/helper/helpers";
import { DeliveryType } from "@/globals/enums";
import BaseAutocomplete from "@/components/base/autocomplete/AutocompleteView.vue";
import { getQueryObjKeys } from "@/helper";
import { store } from "@/store";

export default defineComponent({
  name: "DashboardView",
  components: {
    watchQuery: getQueryObjKeys(OrderFilterEntity),
    MobileMenu,
    LocaleSwitcher,
    Helpers,
    BaseAutocomplete,
  },
  data: () => {
    return {
      configMedia: config,
      helpers: Helpers,
      Pagination: PaginationEntity,
      // OrderFilter!: OrderFilterEntity,
      store: [] as Array<StoreEntity>,
      isSidebarActive: true as boolean,
      Orders: [] as Array<OrderEntity>,
      placedOrder: [] as any,
      AllOrders: [] as any,
      response: {} as OrderEntity,
    };
  },
  setup(props) {
    const { t } = useI18n();
    const orders = ref();
    const joinedByStoreId = ref();
    const joinedByCustomerId = ref();
    const menuToggleLangu = ref();
    const menuToggleNotify = ref();
    const menuToggleSetting = ref();
    const query = [] as any;
    const showL = () => {
      menuToggleLangu.value = !menuToggleLangu.value;
      menuToggleNotify.value = false;
      menuToggleSetting.value = false;
    };
    const showN = () => {
      menuToggleNotify.value = !menuToggleNotify.value;
      menuToggleLangu.value = false;
      menuToggleSetting.value = false;
    };
    const showS = () => {
      menuToggleSetting.value = !menuToggleSetting.value;
      menuToggleLangu.value = false;
      menuToggleNotify.value = false;
    };

    //filter entity

    const OrderFilter = new OrderFilterEntity({
      ...query,
      ...(!query.OrderBy
        ? { OrderBy: "CreatedAt", OrderDir: "DESC", IsOpen: true }
        : {}),
    });
    // hold the opened orders
    const response = onMounted(async () => {
      return await orderservice.getAllPlaced(OrderFilter);
    });
    orders.value = response;
    store.state.orders = orders.value;
    return {
      joinedByCustomerId,
      joinedByStoreId,
      orders,
      OrderFilter,
      Headers,
      t,
      showL,
      showN,
      showS,
      menuToggleLangu,
      menuToggleNotify,
      menuToggleSetting,
    };
  },
  mounted() {
    // const result = this.getStatus();
    // const data = this.getOpenedOrder(this.OrderFilter);
    // this.getAllOrder();
    // this.getStore();
    // this.getUser();
  },
  methods: {
    handleSidebar() {
      this.isSidebarActive = !this.isSidebarActive;
    },
    async getStatus() {
      const status = await orderservice.getDashboardOrderStatus();
      this.response = status.data;
    },
    async getAllOrder() {
      const response = await orderservice.getAll(
        new OrderFilterEntity({
          Page: 1,
          Limit: 25,
          OrderBy: "CreatedAt",
          OrderDir: "DESC",
        })
      );
      this.AllOrders = response.data;
      return response;
    },
    async getOpenedOrder(filter: any) {
      const response = await orderservice.getAllPlaced(
        new OrderFilterEntity(filter)
      );
      this.Orders = response.data.Data;
    },
    // get store
    async getStore() {
      const response = await storeservice.getAll(
        new StoreFilterEntity({
          Limit: 10000,
        })
      );
      // join store by id
      const joinedByStoreId: any = response.data.Data.reduce(
        (group: any, da: any) => {
          const { Id } = da;
          group[Id] = group[Id] ?? [];
          group[Id].push(da);

          return group;
        },
        {}
      );
      this.joinedByStoreId = joinedByStoreId;
      return joinedByStoreId;
    },
    // get users
    async getUser() {
      const response = await userservice.getAll(
        new UserFilterEntity({
          Limit: 10000,
        })
      );
      // join user by id
      const joinedByCustomerId = response.data.Data.reduce(
        (group: any, da: any) => {
          const { Id } = da;
          group[Id] = group[Id] ?? [];
          group[Id].push(da);

          return group;
        },
        {}
      );
      this.joinedByCustomerId = joinedByCustomerId;
      return joinedByCustomerId;
    },
    getDeliverTime(PlacedAt: string, DeliveredAt: string | null) {
      if (DeliveredAt) {
        return this.helpers.numToHourMinSec(
          differenceInMinutes(new Date(DeliveredAt), new Date(PlacedAt))
        );
      }

      return this.$t("na");
    },
    getDateOrAsap({
      DeliveryType: dt,
      DeliveryTime,
      PickupTime,
    }: Partial<OrderEntity>) {
      if (dt === DeliveryType.DELIVERY || dt === DeliveryType.PICKUP) {
        if (isToday(new Date(PickupTime ?? DeliveryTime!))) {
          return (
            "TODAY" +
            this.helpers.formatDate(
              new Date(PickupTime ?? DeliveryTime!),
              " • kk:mm"
            )
          );
        }
        if (PickupTime ?? DeliveryTime) {
          return this.helpers.formatDate(
            new Date(PickupTime ?? DeliveryTime!),
            "d.M.yyyy • kk:mm"
          );
        } else {
          return "AS SOON AS POSSIBLE";
        }
      } else if (PickupTime === null && DeliveryType.IN_STORE) {
        return "";
      }
    },
    updateData(event: any) {
      const obj = reactive(event);
      const row = toRaw(obj);
      // console.log(row)
      this.OrderFilter.StoreId = row.StoreId;
      this.OrderFilter.CustomerId = row.CustomerId;
      this.OrderFilter.DeliveryType = row.DeliveryType;
      this.OrderFilter.Status = row.Status;
      this.$router.replace({
        query: this.helpers.filterToQueryObj(
          new this.Pagination(),
          this.OrderFilter
        ),
      });
      this.getOpenedOrder(this.OrderFilter);
    },
  },
});
