import { Alert, AlertService } from '@/services';
import {createApp} from 'vue'

declare module 'vue' {
  interface Vue {
    $alert: Alert;
  }
  interface Context {
    $alert: Alert;
  }
}

const alert = (_:any, inject:any) => {
  inject('alert', new AlertService());
};

const app = createApp({})
app.config.globalProperties.$alert = alert
export default alert;
