export default {
    kosovo: 'Kosovë',
    email: 'Email',
    'new-email': 'New Email',
    password: 'Password',
    login: 'Login',
    home: 'Home',
    deleted: 'Deleted successfully!',
    add: 'Add',
    category: 'Category',
    categories: 'Categories',
    delete: 'Delete',
    edit: 'Edit',
    updated: 'Updated successfully!',
    added: 'Added successfully!',
    invited: 'Invited successfully!',
    type: 'Type',
    name: 'Name',
    update: 'Update',
    submit: 'Submit',
    stores: 'Stores',
    orders: 'Orders',
    earnings: 'Earnings',
    coupons: 'Coupons',
    users: 'Users',
    settings: 'Settings',
    sliders: 'Sliders',
    'push-notifications': 'Push Notifications',
    send: 'Send',
    'first-name': 'First Name',
    'last-name': 'Last Name',
    phone: 'Phone',
    discount: 'Discount',
    status: 'Status',
    na: 'N\\A',
    'delete-confirmation': 'Are you sure you want to remove this?',
    code: 'Code',
    date: 'Date',
    total: 'Total',
    option: 'Option',
    options: 'Options',
    extras: 'Extras',
    price: 'Price',
    message: 'Message',
    title: 'Title',
    'date-created': 'Date Created',
    'item-photo': 'Item Photo',
    'category-photo': 'Category Photo',
    url: 'URL',
    'item-name': 'Item Name',
    'assigned-to': 'Assigned To',
    role: 'Role',
    search: 'Search',
    address: 'Address',
    'i-address': {
      street: 'Street',
      city: 'City',
      description: 'Additional Description',
      zip: 'ZIP Code',
      country: 'Country',
      no: 'No.',
      lat: 'Latitude',
      lng: 'Longitude',
      msg: 'Latitude and Longnitude are required to show your store in the App.<br>You can get the exact coordinates from <a href="https://gps-coordinates.net" target="_blank" class="primary--text"><b>gps-coordinates.net</b></a>'
    },
    id: 'ID',
    rating: 'Rating',
    'go-back': 'Go Back',
    administration: 'Administration',
    wrong: 'Something went wrong',
    logo: 'Logo',
    'cover-photo': 'Cover Photo',
    description: 'Description',
    to: 'To',
    'profile-settings': 'Profile Settings',
    logout: 'Logout',
    yes: 'Yes',
    no: 'No',
    prev: 'Prev',
    next: 'Next',
    number: 'Number',
    dashboard: 'Home',
    asap: 'As soon as possible',
    finance: 'Finance',
    never: 'Never',
    alright: 'Alright',
    ASAP: 'AS SOON AS POSSIBLE',
    TODAY: 'TODAY',
  };
  