import { ItemStatus } from "@/globals/enums";
import { BaseEntity } from "../base.entity";
import { StoreEntity } from "../store";
import { ItemOptionExtraEntity } from "./item-option-extra.entity";

export class ItemEntity extends BaseEntity {
  id: string | null = null;
  name: string | null = null;
  ingredients: Array<string> = [];
  photo: string | null = null;
  image: string | null = null;
  isActive = false;
  productCategoryId?: string | null = null;
  categoryId?: string | null = null;
  from: string | null = null;
  to: string | null = null;
  storeId: string | null = null;
  store = new StoreEntity();
  options: Array<ItemOptionExtraEntity> = [];
  extra: Array<ItemOptionExtraEntity> = [];
  status: ItemStatus | null = null;
  // id: string | null = null;
  // name: string | null = null;
  // ingredients: Array<string> = [];
  // photo: string | null = null;
  // isActive = false;
  // categoryId?: string | null = null;
  // storeId: string | null = null;
  // store = new StoreEntity();
  // itemOptions: Array<ItemOptionExtraEntity> = [];
  // itemExtras: Array<ItemOptionExtraEntity> = [];
  // status: ItemStatus | null = null;
}
