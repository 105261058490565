import {defineComponent, ref} from 'vue'
import { orderservice, 
    AssignOrderEntity,
    storeservice, 
    userservice, 
    StoreUserFilterEntity, 
    UserEntity,
storeUser 
} from '@/utils';

export default defineComponent({
    name: 'CookView',
    data: () => {
        return {
            assign: AssignOrderEntity,
            StoreId: '' as string,
            CookId: '' as string,
            Users: [] as Array<UserEntity>,
            cook: false,
            Inputs: [] as any,
            height: null as any,
            totalHeight: null as any
         }
    },
    props: ['storeId', 'cookId'],
    mounted() {
        this.StoreId = this.storeId
        this.CookId = this.cookId
        console.log('cookId', this.CookId)
        this.loadUser()
        this.getPosition()
    },
    methods: {
        addInputes (firstname:any, lastname:any) {
            this.Inputs = []
            const fullname = firstname+' '+lastname
            this.Inputs.push(fullname)
            console.log('Input',this.Inputs)
        },
        async loadUser () {
            const Users = await storeUser.getAll(new StoreUserFilterEntity({StoreId: this.StoreId}))
            this.Users = Users.data.Data.map(({User}:any) => User);
            console.log('users', Users)
        },
        async assignOrder(cookId: any) {        
            try {
              await orderservice.assignOrder(new AssignOrderEntity({CookId: cookId}));
              const id = Date.now()
              this.$notify({
                  id,
                  type: 'success',
                  duration: 1000,
                  text: 'updated'
              })
              this.$emit('update');
            } catch (e) {
              const id = Date.now()
              this.$notify({
                id,
                type: 'error',
                duration: 1000,
                text: e as any
              })
            } finally {
                //
            }
          },
          getPosition() {
            const store = document.getElementById('cook')
            console.log('store', store)
            this.totalHeight = store?.offsetHeight as any + store?.offsetTop as any
            console.log('height', this.totalHeight)
          },
          hideCook () {
            console.log('Coook')
            this.$emit('closeCook')
          },
          onscroll (event:any) {
            this.cook = true
            this.height = event.target.offsetHeight + 411
          }
    },
})