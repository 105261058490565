
    import {defineComponent, ref, computed, PropType } from 'vue'
    import {
      orderservice,
      ChangeOrderStatusEntity 
    } from '@/utils'
    import { OrderStatus,ButtonOption, Tab, Props} from '@/globals/enums'
    import { addMinutes } from 'date-fns';
    import { Helpers } from '@/helper'
    import {store} from '@/store'
    import StepperStep from './StepperStep.vue'

    export default defineComponent({
        name: 'StepperView',
        components: {StepperStep},
        props:{
            id:{
                type: String,
                default: 'fw-' + new Date().valueOf()
            },
            customTabs: {
                type: Array,
                default: () => [
                {
                    id: 0,
                    title: 'Step 1',
                    icon: 'check'
                },
                {
                    id: 1,
                    title: 'Step 2',
                    icon: 'check'
                },
                {
                    id: 2,
                    title: 'Step 3',
                    icon: 'check'
                }
            ]
        },
        nextButton: {
            type: Object,
            default: (() => {
              return {
                //
              }
            })
        },
        backButton: {
            type: Object,
            default: (() => {
              return {
                //
              }
            })
        },
        doneButton: {
            type: Object,
            default: (() => {
              return {
                //
              }
            })
        },
        hideButtons: {
            type: Boolean,
            default: false
        },
        startIndex: {
            type: Number,
            default: 1,
            validator: (value: number) => {
            return value >= 1
            }
        },
        verticalTabs: {
            type: Boolean,
            default: true
        },
        beforeChange: {
            type: Function,
            default: () => {
                //
            },
        },
        beforeMount: {
            type: Function,
            default: () => {
                //
            }
        },
        navigableTabs: {
            type: Boolean,
            default: false
        },
        scrollableTabs: {
            type: Boolean,
            default: false
        },
        cardBackground: {
            type: Boolean,
            default: false
        },
        squaredTabs: {
            type: Boolean,
            default: false
        }
    },
        data: () => {
          return {
            orderstatus: OrderStatus,
            helpers: Helpers,
          }
        },
    setup(props) {
        let time = ref() as any;
        let order = ref({}) as any;
        let maxTabIndex = ref<number>()
        let currentTabIndex = ref<number>(0)
        let tabs = ref<Tab[]>([])
        
        order.value = store.state.order
        const Step = computed(() => {
          if (order.value.Status === OrderStatus.PLACED) {
            return 1;
          } else if (order.value.Status === OrderStatus.ACCEPTED) {
            return 2;
          } else if (order.value.Status === OrderStatus.PREPARED) {
            return 3
          } else if (order.value.Status === OrderStatus.ON_THE_WAY) {
            return 4
          } else if (order.value.Status === OrderStatus.DELIVERED) {
            return 6
          } else {
            return 2
          }
        })
        return {
            maxTabIndex,
            currentTabIndex,
            tabs,
            order,
            time,
            Step

        }
    },
    mounted(){
        this.setDefaultValues()
    },
    methods: {
          getOrderStatus2ndValue(item: string) {
              if (item === 'Accepted') {
                console.log('accepted', item)
                if (this.order.Status === OrderStatus.CANCELED) {
                  return OrderStatus.CANCELED;
                } else if (this.order.Status === OrderStatus.CANCELED_AUTOMATICALLY) {
                  return OrderStatus.CANCELED_AUTOMATICALLY;
                } else if (this.order.Status === OrderStatus.DECLINED) {
                  return OrderStatus.DECLINED;
                }
              }
              return this.helpers.slugify(item);
            },
          async changeOrderStatus () {
            try {
              const Id = this.$route.params.id as any
                const status:any = this.helpers.smallFirst(this.order.Status)
                await orderservice.changeOrderStatus(new ChangeOrderStatusEntity({Id: Id, Status: status}))
                this.$emit('update-time')
                const id = Date.now()
                this.$notify({
                  id,
                  type: 'success',
                  duration: 2000,
                  text: 'Updated'
                })

                setTimeout(() => {
                  this.setDefaultValues()
                }, 1000)
                this.updateTab()
            } catch(e) {
                const id = Date.now()
                this.$notify({
                    id,
                    type: 'error',
                    duration: 2000,
                    text: e as any
                })
            }
        },
        setDefaultValues () {
            this.tabs = this.customTabs[0] as Tab[]
            this.order = this.customTabs[1] as any

            this.order = store.state.order
            // this.getTime(this.order.Status)

            this.maxTabIndex = this.tabs.length - 1
            this.currentTabIndex = this.Step

            this.setActiveIndex()
            this.$emit('change', this.currentTabIndex)
        },
        async nextTab(){
            console.log('aaa',this.currentTabIndex, this.maxTabIndex)
            if (this.currentTabIndex === this.maxTabIndex) {
                this.completeWizard()

                return
            }

            const newTabIndex = this.currentTabIndex + 1
            const oldTabIndex = this.currentTabIndex

            this.$emit('change', newTabIndex, oldTabIndex)

            await this.beforeChange()

            this.changeTab(newTabIndex)
        },
        async updateTab() {
          if (this.currentTabIndex === this.maxTabIndex) {
            this.completeWizard()
            return 
          }

          const newTabIndex = this.Step
          this.$emit('change', newTabIndex)
          await this.beforeChange()

          this.changeTab(newTabIndex)
        },
        async prevTab(){
            if (this.currentTabIndex === 0) {
                return
            }

            const newTabIndex = this.currentTabIndex - 1
            const oldTabIndex = this.currentTabIndex

            this.$emit('change', newTabIndex, oldTabIndex)

            await this.beforeChange()

            this.changeTab(newTabIndex)
        },
        setActiveIndex()  {
            this.tabs.forEach((tab, index) =>{
              if (index === this.maxTabIndex as any + 1) {
                return
              }

              tab.checked = index < this.currentTabIndex - 1 as any
              tab.active = (this.currentTabIndex - 1) === index
            })
          
            this.$emit('updated:tabs', this.tabs, this.currentTabIndex)
        },
        completeWizard(){
            const newTabIndex = this.currentTabIndex
            const oldTabIndex = this.currentTabIndex - 1

            this.$emit('complete:wizard', newTabIndex, oldTabIndex)
        },
        async navigateToTab(index: number){
            if (!this.navigableTabs) return

            const newTabIndex = index
            const oldTabIndex = this.currentTabIndex

            this.$emit('change', newTabIndex, oldTabIndex)

            await this.beforeChange()

            this.changeTab(newTabIndex)
        },
        async changeTab(newTabIndex: number){
            this.currentTabIndex = newTabIndex

            this.setActiveIndex()

            await this.$nextTick()
            await this.beforeMount()
        },
        getIconClass(iconName: string){
            return `fa-solid fa-${iconName}`
        }
    }
})
