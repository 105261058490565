
    import { defineComponent } from 'vue';
    import { AuthService } from '@/utils/auth/auth.service';

    const apiservice = new AuthService()
    export default defineComponent ({
        name: 'EmailModal',
        data: () => {
            return {
                showModal: false,
                NewEmail: "",
                Password: "",
                hidden: 'vissible',
                passwordFieldType: "password",
                passwordRules: [
                    (v:any) => !!v || 'Password is required'
                ],
            }
        },
        computed: {
            sortHidden () {
                return this.hidden === 'vissible' ? ['fas', 'eye'] : ['fas', 'eye-slash']
            }
        },
        methods: {
            hide () {
                this.$emit('close')
            },
            async updateEmail () {
                let data = {
                    NewEmail: this.NewEmail,
                    Password: this.Password
                }
                try {
                    const response = await apiservice.updateEmail(data)
                    const id = Date.now()
                    this.$emit('close')
                    this.$notify({
                        id,
                        type: 'success',
                        duration: 10000,
                        text: 'Email Updated Successfully'
                    })

                } catch (e:any) {
                    const id = Date.now()
                    this.$notify({
                        id,
                        type: 'error',
                        duration: 10000,
                        text: e.response.data.message,
                    })
                }
            },
            switchVisibility() {
                this.hidden = this.hidden === 'vissible' ? 'hidden' : 'vissible'
                this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
            },
        }
    })
