import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-row" }
const _hoisted_2 = { class: "flex cleopatra flex-col w-full" }
const _hoisted_3 = { class: "flex flex-col gap-2 overflow-x-auto p-5" }
const _hoisted_4 = {
  key: 0,
  class: "flex gap-2 items-center justify-end p-3"
}
const _hoisted_5 = ["disabled"]
const _hoisted_6 = ["text", "onClick"]
const _hoisted_7 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.pagination.TotalPages)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", {
                elevation: "5",
                color: "white",
                onClick: _cache[0] || (_cache[0] = ($event: any) => {
              _ctx.pagination.previous();
              _ctx.$emit('update-page', _ctx.filterItems);
            }),
                disabled: _ctx.pagination.IsPreviousDisabled,
                class: "button-pagination",
                style: { 'border-radius': '10px' }
              }, [
                _createElementVNode("span", {
                  style: _normalizeStyle(
                _ctx.pagination.IsPreviousDisabled ? '' : { cursor: 'pointer' }
              )
                }, _toDisplayString(_ctx.$t("Prev")), 5)
              ], 8, _hoisted_5),
              _createElementVNode("div", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (page) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: (page as any),
                    fab: "",
                    "x-small": "",
                    class: _normalizeClass(
                page === _ctx.pagination.CurrentPage
                  ? `${'button-pagination-page-active'}`
                  : 'button-pagination-page-inactive'
              ),
                    text: page !== _ctx.pagination.CurrentPage,
                    onClick: ($event: any) => (_ctx.updatePage(page as any))
                  }, [
                    _createElementVNode("span", {
                      class: _normalizeClass({ 'black--text': page !== _ctx.pagination.CurrentPage }),
                      style: { cursor: 'pointer' }
                    }, _toDisplayString(page), 3)
                  ], 10, _hoisted_6))
                }), 128))
              ]),
              _createElementVNode("div", {
                elevation: "5",
                color: "white",
                onClick: _cache[1] || (_cache[1] = ($event: any) => {
              _ctx.pagination.next();
              _ctx.$emit('update-page', _ctx.filterItems);
            }),
                disabled: _ctx.pagination.IsNextDisabled,
                class: "button-pagination",
                style: { 'border-radius': '10px' }
              }, [
                _createElementVNode("span", {
                  class: "text-body-1 accent--text",
                  style: _normalizeStyle(_ctx.pagination.IsNextDisabled ? '' : { cursor: 'pointer' })
                }, _toDisplayString(_ctx.$t("Next")), 5)
              ], 8, _hoisted_7)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}