import {
  CategoryFilterEntity,
  categoryservice,
  UserFilterEntity,
} from "@/utils";
import { defineComponent, ref, toRaw } from "vue";
import { StoreStatus, UserRoleWithOwner } from "@/globals/enums";

export default defineComponent({
  name: "StoreAutocomplete",
  data: () => {
    return {
      isSelected: false as any,
      closeIcon: false as any,
      totalHeight: null as any,
      c: "jkjfkj" as any,
      Items: {
        CategoryId: null,
        HasPendingItems: null,
        IsActive: null,
        Limit: 25,
        Name: null,
        OrderBy: undefined,
        OrderDir: "ASC",
        Page: 1,
        Status: null,
        StoreUserId: null,
      } as any,
    };
  },
  props: {
    id: {
      type: String,
    },
    items: {
      type: Array,
    },
    Type: {
      type: String,
    },
    itemId: {
      type: String,
    },
    value: {
      type: Object,
    },
    placeholder: {
      type: String,
    },
    model: {
      type: String,
    },
    maxWidth: {
      type: String,
    },
  },
  setup(props) {
    const joinedCategoryId = ref();
    console.log("items", props.items);
    return {
      joinedCategoryId,
    };
  },
  mounted() {
    this.getGroupedData();
    this.getPosition(this.id as any);
  },
  methods: {
    toggleSelect(type: any) {
      console.log(type);
      if (type === "store") {
        this.isSelected = !this.isSelected;
      } else if (type === "category") {
        this.isSelected = !this.isSelected;
      } else if (type === "owner") {
        this.isSelected = !this.isSelected;
      } else if (type === "working") {
        this.isSelected = !this.isSelected;
      } else if (type === "pending") {
        this.isSelected = !this.isSelected;
      } else if (type === "status") {
        this.isSelected = !this.isSelected;
      } else if (type === "selling") {
        this.isSelected = !this.isSelected;
      } else if (type === "table") {
        this.isSelected = !this.isSelected;
      }
    },
    toggleIcon() {
      console.log("icon", this.closeIcon);
      this.closeIcon = !this.closeIcon;
    },
    async getGroupedData() {
      await categoryservice
        .getAll(
          new UserFilterEntity({
            Limit: 100000,
          })
        )
        .then((data: any) => {
          console.log("Data in store", data);
          const joinedCategoryId = data.data.data.reduce(
            (group: any, da: any) => {
              const { Id } = da;
              group[Id] = group[Id] ?? [];
              group[Id].push(da);

              return group;
            },
            {}
          );
          const c = Object.keys(joinedCategoryId);
          this.joinedCategoryId = c;
        });
    },
    close(type: any, e: any) {
      console.log("e", e);
      this.isSelected = false;
      this.closeIcon = false;
      this.$emit("close", e);
    },
    getPosition(id: string) {
      const store = document.getElementById(id);
      this.totalHeight = ((store?.offsetHeight as any) +
        store?.offsetTop) as any;
    },
    filterItems(type: any, event: any) {
      if (type === "store") {
        let Name = null;
        Name = event.target.value;
        this.$emit("filter", type, Name);
      }
      if (type === "category") {
        let CategoryId = null as any;
        CategoryId = event.target.value;
        const c = this.items?.find(({ Name }: any) => Name === CategoryId);
        const Category = toRaw(c) as any;
        this.$emit("filter", type, Category.Id);
      }
      if (type === "owner") {
        let Owner = null as any;
        Owner = event.target.value;
        const [first, last] = Owner.split(" ");
        console.log("first", last);
        const own = toRaw(
          this.items?.find(({ FirstName }: any) => FirstName === first)
        ) as any;
        this.$emit("filter", type, own.Id);
      }
      if (type === "working") {
        let Working = null as any;
        Working = event.target.value;
        const work = toRaw(
          this.items?.find(({ Value }: any) => Value === Working)
        ) as any;
        this.$emit("filter", type, work.Id);
      }
      if (type === "pending") {
        let Pending = null as any;
        Pending = event.target.value;
        const pending = toRaw(
          this.items?.find(({ Value }: any) => Value === Pending)
        ) as any;
        this.$emit("filter", type, pending.Id);
      }
      if (type === "status") {
        let Status = null as any;
        Status = event.target.value;
        this.$emit("filter", type, Status);
      }
      if (type === "selling") {
        let Selling = null as any;
        Selling = event.target.value;
        const selling = toRaw(
          this.items?.find(({ Value }: any) => Value === Selling)
        ) as any;
        this.$emit("filter", type, selling.Id);
      }
      if (type === "table") {
        let Serving = null as any;
        Serving = event.target.value;
        const serving = toRaw(
          this.items?.find(({ Value }: any) => Value === Serving)
        ) as any;
        console.log("Sell", serving);
        this.$emit("filter", type, serving.Id);
      }
    },
  },
});
