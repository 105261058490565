import { defineComponent } from "vue";
import StoreAutocomplete from "@/components/shared/store/autocomplete/index.vue";
import {
  tableservice,
  storeservice,
  itemservice,
  categoryservice,
  TableEntity,
} from "@/utils";
import AutoComplete from "@/components/AutoComplete.vue";

export default defineComponent({
  name: "table_add",
  components: { StoreAutocomplete, AutoComplete },
  props: {
    items: {
      type: Object,
    },
    categories: {
      type: Array,
    },
    maxWidth: {
      type: String,
    },
    id: {
      type: String,
    },
  },
  data: () => {
    return {
      totalHeight: null as any,
      inputes: [] as any,
      isSelected: false,
      SaveCategoryMode: false,
      SaveTable: new TableEntity(),
      selectCategory: "" as any,
    };
  },
  setup(props) {
    console.log("save-items", props.items);
  },
  mounted() {
    // this.getStore()
  },
  methods: {
    async getStore() {
      const respons = await (await storeservice.getAll()).data;
      this.getCategories(respons);
      return respons;
    },
    async getCategories(store: any) {
      const response = (await (
        await categoryservice.getTableCategories(
          store.CategoryId as any,
          store.Id
        )
      ).data) as any;
      let Category;
      for (let i = 0; i < response.length; i++) {
        if (response[i].Name === this.selectCategory.Name) {
          Category = response[i];
        }
      }
      this.SaveTable = new TableEntity({
        StoreId: this.$route.params.id as any,
        Category,
        CategoryId: Category.Id,
      });
      this.SaveCategoryMode = true;
    },
    async addTable() {
      this.SaveTable.CategoryId = this.selectCategory.Id;
      this.SaveTable.StoreId = this.$route.params.id as string;
      try {
        await tableservice.add(this.SaveTable as any);
        const id = Date.now();
        this.$notify({
          id,
          type: "success",
          duration: 3000,
          text: "succesfully created",
        });
        this.$router.push(`/store/${this.id}`);
      } catch (e) {
        const id = Date.now();
        this.$notify({
          id,
          type: "error",
          duration: 3000,
          text: e as any,
        });
      }
    },
    close() {
      this.$emit("close");
    },
  },
});
