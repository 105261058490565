import { CategoryType } from '@/globals/enums';
import { FilterEntity } from '../shared/filter.entity';
import { CategoryEntity } from './category.entity';

export class CategoryFilterEntity extends FilterEntity<CategoryEntity> {
  Type: CategoryType | null = null;

  constructor(data?: Partial<CategoryFilterEntity>) {
    super();
    Object.assign(this, data);
  }
}
