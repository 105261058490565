import { StoreUserRole } from '@/globals/enums';
import { FilterEntity } from '../shared/filter.entity';
import { StoreUserEntity } from './store-user.entity';

export class StoreUserFilterEntity extends FilterEntity<StoreUserEntity> {
  Role: StoreUserRole | null = null;
  StoreId: string | null = null;
  FromAdmin = true;

  constructor(data?: Partial<StoreUserFilterEntity>) {
    super(data);
    Object.assign(this, data);
  }
}
