import { ItemEntity } from "./item.entity";

export class ItemOptionExtraEntity {
  // id!: string;
  name: string | null = null;
  price: number | null = null;
  // itemId: string | null = null;
  // item!: ItemEntity;

  constructor(data?: Partial<ItemOptionExtraEntity>) {
    Object.assign(this, data);
  }
}
