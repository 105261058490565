import {
  CategoryEntity,
  PaginationEntity,
  StoreEntity,
  TableEntity,
  TableFilterEntity,
  storeservice,
  categoryservice,
  tableservice,
} from "@/utils";
import { useRoute } from "vue-router";
import { plainToClass } from "class-transformer";
import treeitem from "../../../../components/tree/index.vue";
import StoreAutocomplete from "@/components/shared/store/autocomplete/index.vue";
import tableCategory from "@/components/shared/store/category/index.vue";
import table_add from "@/components/shared/store/table/index.vue";
import { defineComponent, ref } from "vue";
import { Helpers } from "@/helper";
import { QrType } from "@/globals/enums";
import { baseAPI } from "@/plugins";
import { store } from "@/store";
import AutoComplete from "@/components/AutoComplete.vue";

export default defineComponent({
  components: {
    treeitem,
    StoreAutocomplete,
    tableCategory,
    table_add,
    AutoComplete,
  },
  props: ["loadNode"],
  data: () => {
    return {
      dataModel: [
        {
          id: "numberOrString",
          label: "Root Node",
          children: [
            { id: 1, label: "Child Node" },
            { id: "node2", label: "Second Child" },
          ],
        },
      ],
      refs: {
        tree: null as any,
      },
      nodes: [] as Array<CategoryEntity>,
      helpers: Helpers,
      SaveTableMode: false,
      SaveCategoryMode: false,
      addCategory: false as any,
      addTable: false as any,
      TableCategoriesSequenceMode: false,
      Tables: [] as Array<TableEntity>,
      inputes: [] as any,
      SaveTable: new TableEntity(),
      SaveCategory: new CategoryEntity(),
      CategoriesOpened: [] as Array<string>,
      selectedTableFilter: "" as any,
    };
  },
  setup(props) {
    const route = useRoute();
    const config = ref([]) as any;
    const id = route.params.id as any;
    let Store = new StoreEntity();
    let asyncChildCounter = 0;
    const Categories = ref([]) as any;
    let getSeq = {} as any;
    const query = [] as any;
    const TableFilter = new TableFilterEntity({ ...query, Limit: 1000000 });
    const Pagination = new PaginationEntity();
    (async () => {
      Store = (await (await storeservice.getAll()).data) as any;
      Categories.value = await (
        await categoryservice.getTableCategories(
          Store.categoryId as any,
          Store.id as any
        )
      ).data;
      getSeq = (catId: string | null) =>
        Store.tableCategoriesSequence.find(
          ({ CategoryId }: any) => CategoryId === catId
        )?.Sequence ?? 0;

      Categories.value = Categories.value.sort(
        (a: any, b: any) => +getSeq(a.Id) - +getSeq(b.Id)
      );
      Categories.value.forEach((cat: any) => {
        cat.Children = [];
        config.value.push(cat.Name);
      });
      store.state.Categories = Categories;
    })();

    const modelDefaults = ref({ loadChildrenAsync: loadChildrenCallback });
    async function loadChildrenCallback(parentModel: any) {
      asyncChildCounter++;
      const currentCounter = asyncChildCounter;
      return new Promise((resolve) =>
        setTimeout(
          resolve.bind(null, [
            {
              id: `async-child-node${currentCounter} `,
              label: `Child ${currentCounter} from parent ${parentModel.id}`,
            },
          ]),
          1000
        )
      );
    }
    return {
      Store,
      Categories,
      TableFilter,
      Pagination,
      id,
      query,
      config,
      modelDefaults,
    };
  },
  beforeMount() {
    this.Pagination = plainToClass(PaginationEntity, this.Pagination);
    // this.getStore().then((result) => {
    //   this.SaveCategory = new CategoryEntity({
    //     StoreId: result.id,
    //     ParentId: result.categoryId,
    //   });
    //   this.SaveCategoryMode = true;
    // });
  },
  mounted() {
    console.log(this.loadNodesCallback());
    // this.getStore();
  },
  methods: {
    // async getStore() {
    //   const respons = await (await storeservice.getAll(this.id)).data;
    //   this.Store = respons;
    //   return respons;
    // },
    async loadNodesCallback() {
      return new Promise((resolve) =>
        setTimeout(
          resolve.bind(null, [{ id: "async-rootNode", label: "Root Node" }]),
          1000
        )
      );
    },
    reset() {
      this.CategoriesOpened = [];
      this.helpers.resetTreeViewNodes(this.$refs.tree as any);
    },
    close() {
      this.reset();
      this.SaveCategoryMode = false;
      this.SaveTableMode = false;
    },
    pushTable(value: any) {
      this.Categories.push(value);
    },
    updateData(type: any) {
      if (type === "table") {
        this.TableFilter.IsActive = this.selectedTableFilter?.Id || null;
      }
      this.$router.replace({
        query: this.helpers.filterToQueryObj(this.Pagination, this.TableFilter),
      });
      if (this.TableFilter.CategoryId !== null) {
        this.Categories.find(
          ({ Id: id }: any) => id === this.TableFilter.CategoryId
        )!.Children = [];
      }
    },
    async getChildren({ id }: CategoryEntity) {
      this.TableFilter.CategoryId = id!;
      const { Data } = await (
        await tableservice.getAll(this.id, this.TableFilter)
      ).data;
      (await Promise.all(
        Data.map(({ Id }: any) => storeservice.getQr(QrType.TABLE, Id!))
      ).then((data) => {
        Data.forEach((table, i) => {
          table.Qr = data[i].data.Url;
        });
      })) as any;

      if (
        this.Categories.find(({ Id: id }: any) => id === id).Children.length > 0
      ) {
        this.Categories.find(({ Id: id }: any) => id === id)!.Children = [];
      } else {
        this.Categories.find(({ Id: id }: any) => id === id)!.Children = Data;
      }
    },
    expandTree(Id: any) {
      const curE: any = this.$refs["body-" + Id];
      if (curE === undefined) return true;
      if (curE.dataset.isExpanded === "true") {
        return true;
      } else {
        return false;
      }
    },
    async updateIsActive(id: string, IsActive: boolean) {
      try {
        await baseAPI.patch(`/table/${id}/update-is-active`, {
          IsActive,
        });
      } catch (err) {
        const id = Date.now();
        this.$notify({
          id,
          type: "error",
          duration: 3000,
          text: err as any,
        });
      }
    },

    async updateTableCategories() {
      try {
        await storeservice.updateCategoriesSequence(
          this.id,
          undefined,
          this.Store.tableCategoriesSequence
        );
        const id = Date.now();
        this.$notify({
          id,
          type: "error",
          duration: 3000,
          text: "updated",
        });
        this.TableCategoriesSequenceMode = false;
        this.reset();
      } catch {
        const id = Date.now();
        this.$notify({
          id,
          type: "error",
          duration: 3000,
          text: "wrong",
        });
      } finally {
        //
      }
    },
    toggleAddCategory() {
      this.addCategory = !this.addCategory;
    },
    toggleAddTable() {
      this.addTable = !this.addTable;
      console.log(this.addTable);
    },
  },
});
