import { store } from "../store/index";
import { ref } from "vue";

const token = ref(store.state.AccessToken || null);

class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    return user?.RefreshToken;
  }
  getLocalAccessToken() {
    // const user = JSON.parse(localStorage.getItem("user") || "{}");
    // return user?.AccessToken;
    return store.state.AccessToken || null;
  }
  updateLocalAccessToken(token: any) {
    const user = token;
    console.log("Update local access token", token);
    console.log("user", user);
    store.commit("setAccessToken", token);
    localStorage.setItem("user", JSON.stringify(user));
  }
  getUser() {
    return JSON.parse(localStorage.getItem("user") || "{}");
  }
  setUser(user: any) {
    console.log(JSON.stringify(user));
    localStorage.setItem("user", JSON.stringify(user));
  }
  removeUser() {
    localStorage.removeItem("user");
    localStorage.removeItem("vuex");
  }
}
export default new TokenService();
