import { baseAPI } from "@/plugins/axios";
import {
  OrderEntity,
  OrderFilterEntity,
  AssignOrderEntity,
  ChangeOrderStatusEntity,
} from "./index";
import { Helpers } from "@/helper";
import { store } from "../../store";

class OrderApi {
  async getAllPlaced(filter: OrderFilterEntity) {
    const response = await baseAPI.get(
      `order?${Helpers.filterToQueryString<OrderEntity, OrderFilterEntity>(
        filter
      )}`
    );
    return response;
  }

  getAll(filter: OrderFilterEntity) {
    const response = baseAPI.get(
      `order?${this.filterToQueryString<OrderEntity, OrderFilterEntity>(
        filter
      )}`
    );
    return response;
  }

  async getDashboardOrderStatus() {
    const response = await baseAPI.get<OrderEntity>("order/dashboard/stats");
    return response;
  }

  async getById(id: string) {
    const response = await baseAPI.get<OrderEntity>(`/order/${id}`);
    return response;
  }
  async assignOrder(data: AssignOrderEntity) {
    const response = await baseAPI.post("order/assign", data);
    return response;
  }
  async changeOrderStatus(data: ChangeOrderStatusEntity) {
    const response = await baseAPI.patch(`/order/change-status`, data);
    return response;
  }
  filterToQueryString<OrderEntity, OrderFilterEntity>(filter: any) {
    if (!filter) {
      return "";
    }

    let str = Object.entries(filter).reduce((acc, [key, val]) => {
      if (val) {
        if (Array.isArray(val)) {
          val.forEach((v, i) => {
            acc += `${key}[${[i]}]=${v}&`;
          });
        } else {
          acc += `${key}=${val}&`;
        }
      }
      return acc;
    }, "");

    str = str.slice(0, -1);
    return str;
  }
}

export const orderservice = new OrderApi();
