import { baseAPI } from "@/plugins";
import { BaseEntity } from "../base.entity";
import { DeleteMediaEntity, MediaEntity } from "./media.entity";

class MediaApi {
  upload<T extends BaseEntity>(
    entity: T,
    mediaFields: Array<keyof T>,
    { Id, Type }: MediaEntity
  ) {
    const data = new FormData();

    let upload = false;

    mediaFields.forEach((field: any) => {
      const item: File | string = (<any>entity)[field];
      if (item && typeof item === "object") {
        data.append(field as string, item);
        upload = true;
      }
    });

    if (!upload) {
      return false;
    }

    data.append("id", Id);
    data.append("type", Type);

    console.log("Image in media service", data);

    return data.toString();
    // return baseAPI.post('/media/images/upload', data);
  }

  convertToBlob(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  deleteImage<T extends BaseEntity>(
    image: string,
    deleteMedia: DeleteMediaEntity<T>
  ) {
    return baseAPI.post(`/media/images/${image}/delete`, deleteMedia);
  }
}

export const mediaservice = new MediaApi();
