import { OrderStatus } from '@/globals/enums';
import { BaseEntity } from '../base.entity';

export class ChangeOrderStatusEntity extends BaseEntity {
  Id!: string | null;
  Status: OrderStatus | null = null;

  constructor(data?: Partial<ChangeOrderStatusEntity>) {
    super();
    Object.assign(this, data);
  }
}
