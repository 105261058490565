import { FirebaseOptions, getApp, getApps, initializeApp } from 'firebase/app';
import { ConfirmationResult, getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import {createApp} from 'vue'


const initFirebase = () => {
    const firebaseConfig: FirebaseOptions = {
      apiKey: 'AIzaSyDo8hd-Ums_kBiayAdZeCXNQohLLeO9reM',
      authDomain: 'tedera.firebaseapp.com',
      projectId: 'tedera',
      storageBucket: 'tedera.appspot.com',
      messagingSenderId: '339293509104',
      appId: '1:339293509104:web:ef8ac7d93095d5b7dd3935'
    };
  
    getApps().length ? getApp() : initializeApp(firebaseConfig);
  };
initFirebase()
const auth = getAuth();
let recaptcha: RecaptchaVerifier | null = null;
class firebaseObj {
initRecaptcha(){
    if (!recaptcha) {
      if (!document.getElementById('recaptcha')) {
        console.error(`Element with id #recaptcha doesn't exist.`);
      }
      recaptcha = new RecaptchaVerifier('recaptcha', { size: 'invisible' }, auth);
    }
    return recaptcha
  }

    SMSPromise: ConfirmationResult | null = null;

   sendSMS = async(phone: string) =>{
    console.log(phone)
    console.log(recaptcha)
    if (!recaptcha) {
      throw new Error('Please call initRecaptcha first.');
    }
    this.SMSPromise = await signInWithPhoneNumber(auth, phone.replace(/\s+/g, ''), recaptcha);
  };

  verifyCode = async(code: string)=>{
    console.log('verify')
    if (!this.SMSPromise) {
      throw new Error('Please call sendSMS first.');
    }

    const { user } = await this.SMSPromise.confirm(code);
    const token = await user.getIdToken(true);

    this.SMSPromise = null;

    return token;
  }

  getUser() {
    auth.currentUser;
  }
}
export const firebase = new firebaseObj()
