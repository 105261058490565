import { BaseEntity } from '../base.entity';
import { StoreEntity } from '../store';
import { CategoryEntity } from '../category';

export class TableEntity extends BaseEntity {
  Id: string | null = null;
  Name: string | null = null;
  IsActive = false;
  CategoryId: string | null = null;
  StoreId: string | null = null;
  Category = new CategoryEntity();
  Store = new StoreEntity();
  Qr!: string;

  constructor(data?: Partial<TableEntity>) {
    super();
    Object.assign(this, data);
  }
}
