
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import editModal from '@/components/shared/store/tablemodal/edit.vue'
import deleteModal from '@/components/shared/store/tablemodal/delete.vue'
import { defineComponent, ref } from 'vue'
import { Helpers } from '@/helper'
import { baseAPI } from '@/plugins'

import ArrowRight from './Icons/ArrowRight.vue'
import ArrowDown from './Icons/ArrowDown.vue'
import LoadingArrow from './Icons/LoadingArrow.vue'

import Draggable from 'vuedraggable'
import { categoryservice } from '@/utils'

export default defineComponent({
    name: 'treeitem',
    components: {
        Menu, MenuButton, MenuItems, MenuItem, editModal, deleteModal, ArrowRight, ArrowDown, LoadingArrow,
        Draggable
    },
    props: {
        nodes: {
            type: Array
        },
        labels: {
            type: Array
        }
    },
    data: () => {
        return {
            helpers: Helpers,
            lists: [] as any,
            isOpen: false,
            id: {} as any,
            isExpandable: false as any,
            editCategory: false as any,
            deleteCategory: false as any,
            toggleIcon: false,
            clicked: 0,
            dragging: false,
            deleteData: {
                Id: null,
                type: 'category'
            } as any,
            editData: {
                Id: null,
                type: 'category'
            } as any,
        }
    },
    watch: {
        labels: function(val) {
            this.lists = val
        }
    },
    methods: {
        async endDragging(){
            this.dragging = false
            const data = await categoryservice.sequence({
                Id: this.$route.params.id,
                Categories: this.lists.map((list: any, index: number) => ({
                                CategoryId: list.Id,
                                Sequence: index + 1,
                            }))
            })
            const id = Date.now()
            this.$notify({
                id,
                type: 'success',
                duration: 3000,
                text: 'succesfully reordered!'
            })
        },
        loadData(label: string, index: string) {
            this.$emit('loadNode', label)

            let idhtml: any = document.getElementById(`icon-${index}`)?.getAttribute('id')?.split('-')

            // this.toggleIcon = !this.toggleIcon
            if (idhtml[1] == index) {
                let iconCollection: any = document.getElementById(`icon-${index}`)?.children[0].children
                iconCollection[0].classList.toggle('icon-hidden')
                iconCollection[2].classList.toggle('icon-hidden')
            }
        },
        async updateIsActive(index: string, IsActive: boolean) {
            try {
                await baseAPI.patch(`/table/${index}/update-is-active`, {
                    IsActive
                });
                const id = Date.now()
                this.$notify({
                    id,
                    type: 'success',
                    duration: 3000,
                    text: 'Updated'
                })
            } catch (err) {
                const id = Date.now()
                this.$notify({
                    id,
                    type: 'error',
                    duration: 3000,
                    text: err as any
                })
            }
        },
        toggleEditModal(val?: any, type?: any) {
            if(this.editData && this.editData.Id) this.lists = this.labels?.filter((label: any) => label.Id !== this.editData.Id)
            this.editData = val
            if (type) this.editData.type = type
            this.editCategory = !this.editCategory
        },
        toggleDeleteModal(val?: any, type?: any) {
            if(this.deleteData && this.deleteData.Id) this.lists = this.labels?.filter((label: any) => label.Id !== this.deleteData.Id)
            this.deleteData = val
            if (type) this.deleteData.type = type
            this.deleteCategory = !this.deleteCategory
        },
        checkMove: function(e: string | any) {
            console.log("Future index: " + e.draggedContext.futureIndex)
        }
    },
})
