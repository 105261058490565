
    import SideBar from '../views/index.vue'
    import MobileMenu from './MobileMenu.vue'
    import {OtpService} from '@/services'
    import PhoneOtp from './PhoneOtp.vue'
    import AppBar from './AppBar.vue'
    import {defineComponent} from 'vue'

    export  default defineComponent ({
        components: {
            SideBar,
            AppBar
        },
        data: () => {
            return {
                isOtp: false,
                OtpSrv: new OtpService()
            }
        },
        mounted (){
            // console.log(this.OtpSrv.Mode)
        },
        methods: {
            // open() {
            //     this.isOtp = !this.isOtp
            // },
            // close () {
            //     // this.Mode = false
            // }
        },
    })
