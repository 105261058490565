
    import { defineComponent, ref, h} from 'vue';
    import {OtpService} from '@/services'
    import Loading from 'vue-loading-overlay'

    export default defineComponent({
        name: 'PhoneOtp',
        components: {Loading},
        data(){
            return {
               OTP: '',
               OtpSrv: new OtpService(),
               timer: 0,
               fullPage: true,
            }
        },
        mounted() {
                window.onstorage = () => {
                    // When local storage changes, dump the list to
                    // the console.
                    const data:any = localStorage.getItem('timer')
                    this.timer  = parseInt(data)
                    };
        }
    })
