
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "SideBar",
  data: () => {
    return {
      isSidebarActive: true,
      activeTitle: true,
      clicked: false,
    };
  },
  methods: {
    handleSidebar() {
      this.isSidebarActive = !this.isSidebarActive;
    },
    isClicked() {
      return this.clicked ? "#ed5151" : "#515151";
    },
  },

  setup() {
    const selected = ref(1);
    const changeSelected = (i: any) => {
      selected.value = i;
    };
    return {
      selected,
      changeSelected,
    };
  },
});
