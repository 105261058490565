
import { categoryservice, tableservice } from "@/utils";
import { defineComponent } from "vue";

export default defineComponent({
  name: "deleteModal",
  props: {
    item: {
      type: Object,
    },
  },
  data: () => {
    return {
      categoryName: "" as any,
    };
  },
  methods: {
    async onDelete() {
      console.log(this.item);
      if (this.item?.type == "category") {
        const respons = await (
          await categoryservice.delete(this.item?.Id as any)
        ).data;
      } else {
        const respons = await (
          await tableservice.delete(this.item?.Id as any)
        ).data;
      }
      const id = Date.now();
      this.$notify({
        id,
        type: "success",
        duration: 3000,
        text: "succesfully deleted!",
      });
      this.$emit("submit");
      this.close();
      this.$router.push("/stores/" + this.item?.StoreId);
    },
    close() {
      this.$emit("close");
    },
  },
});
