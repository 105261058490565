import { defineComponent, ref} from 'vue'
import { Otp, OtpService, SuccessService, AlertService } from '@/services'
import PhoneOtp from '../PhoneOtp.vue'
import { AuthService } from '@/utils'
import { firebase } from '@/plugins'

interface IOtp {
    $otp?: Otp;
  }
const alert = new AlertService()
const success = new SuccessService()
const otp = new OtpService()
const authservice = new AuthService()
export default defineComponent({
    name: 'PhoneModal',
    components: {PhoneOtp},
    data: () => {
        return {
            NewPhone!: null as string | null,
            selected: 1,
            options: [
                {code: '+383', id: 1}
            ],
            showOtp: true,
            fullPage: true,
        }
    },
    setup () {
        const targetRef = ref<HTMLElement | undefined>(undefined)

    },
    methods: {
        async changePhone () {
            try {
                const token = await otp.show(
                    this.options[0].code+' ' +this.NewPhone!,
                    'phone',
                    'You will receive shortly a PIN Number. Please enter that in order to continue with your new password.',
                    (phone) => firebase.sendSMS(phone),
                    (code) => firebase.verifyCode(code)
                );

                if (!token) {
                    return this.close(false)
                }

                await authservice.updatePhone(token, this.options[0].code+ ' '+this.NewPhone!);
                const success = true
                this.$emit('success', success)
                const id = Date.now()
                this.$notify({
                    id,
                    type: 'success',
                    duration: 10000,
                    text: 'Congratulations, You can proceed with your new credentials'
                })
                this.close()

            } catch (e) {
                const id = Date.now()
                this.$notify({
                    id,
                    type: 'error',
                    duration: 10000,
                    text: e as any
                })
            } finally {
                otp.Loading = false
                const loading = otp.Loading
                this.$emit('close', loading)
            }
        },
        close(success = true) {
            this.$emit('close', success ? this.NewPhone : undefined);
            this.NewPhone = null;
          },
        hide () {
            this.$emit('phoneclose')
        },
        show () {
            this.showOtp = !this.showOtp
        },
        loadLoading() {
            this.$emit('load')
        },
        updatePhone () {
            this.changePhone()
            this.hide()
            this.loadLoading()
            // this.show()
        }
    }
})