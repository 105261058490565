import { AddressEntity } from "../common/address.entity";
import { UserRole, Status } from "@/globals/enums";
import { BaseEntity } from "../base.entity";
import { StoreUserEntity } from "../store";

// export class UserEntity extends BaseEntity{
//   id: string | null = null;
//   // UniqueId: string | null = null;
//   firstName: string | null = null;
//   lastName: string | null = null;
//   email: string | null = null;
//   phone: string | null = null;
//   // Password: string | null = null;
//   isPhoneVerified = false;
//   isEmailVerified = false;
//   role: UserRole | null = null;
//   Status: Status | null = null;
//   BlockedStores: Array<{ StoreId: string }> = [];
//   Earnings: number | null = null;
//   TotalOrders: number | null = null;
//   TotalOrdersCost: number | null = null;
//   Address: Array<AddressEntity>= [];
//   StoreUsers: Array<StoreUserEntity> = [];
//   Street: string | null = null;
//   No: string | null = null;
//   Country: string | null = null;
//   Zipcode: number | null = null;
//   State: string | null = null;
//   Ycoordinate: number | null = null;
//   Xcoordinate: number | null = null;

// }
export class UserEntity extends BaseEntity {
  id: string | null = null;
  firstName: string | null = null;
  lastName: string | null = null;
  email: string | null = null;
  phone: string | null = null;
  isPhoneVerified = false;
  isEmailVerified = false;
  role: UserRole | null = null;
  status: Status | null = null;
  blockedStores: Array<{ StoreId: string }> = [];
  earnings: number | null = null;
  totalOrders: number | null = null;
  totalOrdersCost: number | null = null;
  address: Array<AddressEntity> = [];
  storeUsers: Array<StoreUserEntity> = [];
  street: string | null = null;
  no: string | null = null;
  country: string | null = null;
  zipcode: number | null = null;
  state: string | null = null;
  yCoordinate: number | null = null;
  XCoordinate: number | null = null;
}
