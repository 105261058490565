import axios from "axios";
import { authAPI, baseAPI } from "./axios";
import TokenService from "./token.service";
import stores from "@/views/stores";

const setup = (store: any) => {
  baseAPI.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        console.log("Token available", token);
        config.headers = {
          Authorization: "Bearer" + " " + token,
          "access-token": token,
        };
      } else console.log("No Token available");
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  baseAPI.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (originalConfig.url !== "/admin" && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            const rs = await authAPI.delete("admin/logout", {});
            store.commit("logout");
            window.location.reload();
            // const token  = rs.data;
            // store.dispatch('auth/refresh-access-token', token);
            // TokenService.updateLocalAccessToken(token);
            // return baseAPI(originalConfig);
          } catch (_error) {
            store.commit("logout");
            return Promise.reject(_error);
          }
        }
      }
      return Promise.reject(err);
    }
  );
};
export default setup;
