import {defineComponent, ref} from 'vue'
import { orderservice, 
    AssignOrderEntity,
    storeservice, 
    userservice, 
    StoreUserFilterEntity, 
    UserEntity,
storeUser 
} from '@/utils';

export default defineComponent({
    name: 'DriverView',
    data: () => {
        return {
            assign: AssignOrderEntity,
            StoreId: '' as string,
            DriverId: '' as string,
            Users: [] as Array<UserEntity>,
            driver: false,
            Inputs: [] as any,
            totalHeight: null as any
        }
    },
    props: ['storeId', 'driverId'],
    mounted() {
        this.StoreId = this.storeId
        this.DriverId = this.driverId
        this.loadUser()
        this.getPosition()
    },
    methods: {
        getPosition() {
            const store = document.getElementById('driver')
            this.totalHeight = store?.offsetHeight as any + store?.offsetTop as any
          },
        addInputes (firstname:any, lastname:any) {
            this.Inputs = []
            const fullname = firstname+' '+lastname
            this.Inputs.push(fullname)
        },
        async loadUser () {
            const Users = await storeUser.getAll(new StoreUserFilterEntity({StoreId: this.StoreId}))
            this.Users = Users.data.Data.map(({User}:any) => User);
        },
        async assignOrder(driverId: any) {        
            try {
              await orderservice.assignOrder(new AssignOrderEntity({DriverId: driverId}));
              const id = Date.now()
              this.$notify({
                  id,
                  type: 'success',
                  duration: 1000,
                  text: 'updated'
              })
              this.$emit('update');
            } catch (e) {
              const id = Date.now()
              this.$notify({
                id,
                type: 'error',
                duration: 1000,
                text: e as any
              })
            } finally {
                //
            }
          },
          hideDriver() {
            console.log('Driver')
            this.$emit('closeDriver')
          }
    },
})