export default {
    email: 'Email',
    password: 'Fjalëkalimi',
    login: 'Identifikohu',
    home: 'Shtëpi',
    deleted: 'U fshi me sukses!',
    add: 'Shtoni',
    category: 'Kategoria',
    categories: 'Kategoritë',
    delete: 'Fshije',
    edit: 'Redakto',
    updated: 'Përditësuar me sukses!',
    added: 'U shtua me sukses!',
    invited: 'I ftuar me sukses!',
    type: 'Lloji',
    name: 'Emri',
    update: 'Përditëso',
    submit: 'Paraqisni',
    stores: 'Dyqane',
    orders: 'Porositë',
    earnings: 'Fitimet',
    coupons: 'Kuponët',
    users: 'Përdoruesit',
    settings: 'Cilësimet',
    sliders: 'Rrëshqitës',
    'push-notifications': 'Njoftimet me shtytje',
    send: 'Dërgo',
    'first-name': 'Emri',
    'last-name': 'Mbiemri',
    phone: 'Telefoni',
    discount: 'Zbritje',
    status: 'Statusi',
    na: 'N\\A',
    'delete-confirmation': 'Je i sigurt që dëshiron ta heqësh këtë?',
    code: 'Kodi',
    date: 'Data',
    total: 'Total',
    option: 'Opsioni',
    options: 'Opsione',
    extras: 'Shtesa',
    price: 'Çmimi',
    message: 'Mesazh',
    title: 'Titulli',
    'date-created': 'Data e krijimit',
    'item-photo': 'Foto e artikullit',
    'category-photo': 'Foto e kategorisë',
    url: 'URL',
    'item-name': 'Emri i artikullit',
    'assigned-to': 'Caktuar për',
    role: 'Roli',
    search: 'Kërko',
    address: 'Adresë',
    'i-address': {
      street: 'Rruga',
      city: 'Qyteti',
      description: 'Përshkrimi shtesë',
      zip: 'Kodi Postal',
      country: 'Vendi',
      no: 'Nr.',
      lat: 'Gjerësia gjeografike',
      lng: 'Gjatësia gjeografike',
      msg: `Gjerësia dhe gjatësia janë të nevojshme për të shfaqur dyqanin tuaj në aplikacion.<br>Mund t'i merrni koordinatat e sakta nga <a href="https://gps-coordinates.net" target="_blank" class="primary--text "><b>gps-coordinates.net</b></a>`
    },
    id: 'ID',
    rating: 'Vlerësimi',
    'go-back': 'Kthehu mbrapa',
    administration: 'Administrata',
    wrong: 'Dicka shkoi keq',
    logo: 'Logo',
    'cover-photo': 'Foto e kopertinës',
    description: 'Përshkrim',
    to: 'për të',
    'profile-settings': 'Cilësimet e profilit',
    logout: 'Shkyç',
    yes: 'po',
    no: 'Nr',
    prev: 'E mëparshme',
    next: 'Tjetra',
    number: 'Numri',
    dashboard: 'Shtëpi',
    asap: 'Sa me shpejt te jete e mundur',
    finance: 'Financa',
    never: 'kurrë'
  };
  