<template>
  <div class="flex flex-row">
    <div class="flex cleopatra flex-col w-full">
      <div
        class="container flex gap-2 justify-between max-w-5xl mx-auto overflow-x-auto overflow-y-visible p-5 pb-3 xl:flex-row min-h-fit"
      >
        <div class="flex gap-2 items-center">
          <span class="h1">{{ Store?.name }}</span>
          <svg
            width="6"
            height="10"
            viewBox="0 0 6 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="1"
              y1="9.29289"
              x2="5.29289"
              y2="5"
              stroke="#222B45"
              stroke-linecap="round"
            />
            <line
              x1="5.29289"
              y1="5"
              x2="1"
              y2="0.707107"
              stroke="#222B45"
              stroke-linecap="round"
            />
          </svg>
          <span class="h1">Products</span>
        </div>
      </div>
      <div
        class="container flex flex-col sm:flex-row gap-2 justify-between max-w-5xl mx-auto overflow-x-auto px-5 py-3"
      >
        <div class="flex gap-2 cursor-pointer">
          <div
            class="button-back"
            @click="$router.push(`/store/${$route.params.id}`)"
          >
            <svg
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="5"
                y1="0.707107"
                x2="0.707106"
                y2="5"
                stroke="#222B45"
                stroke-linecap="round"
              />
              <line
                x1="0.707107"
                y1="5"
                x2="5"
                y2="9.29289"
                stroke="#222B45"
                stroke-linecap="round"
              />
            </svg>
            <span>Back</span>
          </div>
          <div
            class="button"
            @click="
              $router.push(`/store/${$route.params.id}/product/add-product`)
            "
          >
            <span>+ Add Product</span>
          </div>
        </div>

        <div class="flex gap-2 sm:absolute right-16 z-10">
          <AutoComplete
            v-model="ItemFilter.IsActive"
            return-by="value"
            :items="[
              { id: 'true', value: 'Selling' },
              { id: 'false', value: 'Not Selling' },
            ]"
            track-by="value"
            filter-by="value"
            show-by="value"
            placeholder="Selling"
            :show-close="true"
          />
          <AutoComplete
            v-model="ItemFilter.Status"
            return-by="'value'"
            :items="ItemStatuses"
            track-by="value"
            filter-by="value"
            show-by="value"
            placeholder="Status"
            :show-close="true"
          />
        </div>
      </div>
      <div
        class="container flex flex-col gap-2 max-w-5xl mx-auto overflow-x-auto p-5"
      >
        <div class="box-head justify-between">
          <div class="flex gap-4 items-center">
            <div class="w-14"></div>
            <div class="w-32"><span class="text-order">Name</span></div>
            <div class="flex justify-center w-32">
              <span class="text-order">Price</span>
            </div>
            <div class="flex justify-center w-32">
              <span class="text-order">SKU</span>
            </div>
            <div class="flex justify-center w-20">
              <span class="text-order">Selling</span>
            </div>
          </div>
        </div>
        <div v-for="(product, idx) in Store?.product" :key="idx">
          <div
            :id="`header-${idx}`"
            class="box justify-between min-w-max cursor-pointer mb-2"
            :ref="'header-' + idx"
            @click="toggleCollapsable(name)"
          >
            <div class="flex gap-2 items-center">
              <div class="w-4">
                <svg
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="0.707107"
                    y1="1"
                    x2="7"
                    y2="7.29289"
                    stroke="#9E9E9E"
                    stroke-linecap="round"
                  />
                  <line
                    x1="7"
                    y1="7.29289"
                    x2="13.2929"
                    y2="1"
                    stroke="#9E9E9E"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <div>
                <p class="text-title">{{ product?.name }}</p>
              </div>
            </div>
            <div class="flex justify-end">
              <div class="button-coupons">
                <span>12</span>
              </div>
            </div>
          </div>
          <div
            class="box justify-between min-w-max mb-2"
            :ref="'body-' + idx"
            :id="'body-' + idx"
          >
            <!-- v-show="expandElement(idx)" -->
            <div class="flex gap-4 items-center">
              <div class="w-14">
                <img
                  :src="product.photo"
                  :alt="product.name"
                  class="store-icon"
                />
              </div>
              <div class="w-32">
                <p class="text-title">{{ product.name }}</p>
              </div>
              <div class="flex justify-center w-32">
                <span class="text">{{ getPrice(product.options) }}</span>
              </div>
              <div class="flex justify-center w-32">
                <span class="text">SMA-6793</span>
              </div>
              <div class="flex justify-center w-20">
                <div class="flex items-center">
                  <input
                    type="checkbox"
                    :id="`table-${product.id}`"
                    class="hidden"
                    :checked="!product.isActive"
                    @change="updateIsActive(product.id, !product.isActive)"
                    :disabled="product.status !== 'verified'"
                  />
                  <label
                    :for="`table-${product.id}`"
                    id="toggle"
                    class="bg-[#D1E8B4] cursor-pointer h-5 relative rounded-full w-9"
                  ></label>
                </div>
              </div>
            </div>
            <div class="flex gap-4 items-center">
              <!-- <div
                :id="`select-${product.id}`"
                class="flex flex-col items-start justify-between sm:flex-row"
              >
                <vselect
                  :id="`select-${product.id}`"
                  :items="ItemStatuses"
                  :item="product.status"
                  @change="updateStatus(product.id, $event)"
                />
              </div> -->
              <AutoComplete
                v-model="product.status"
                return-by="'value'"
                :items="ItemStatuses"
                track-by="value"
                filter-by="value"
                show-by="value"
                :placeholder="product.status || 'Status'"
                @submit="updateStatus(product.id, $event)"
              />

              <div class="flex justify-end w-9">
                <action
                  :index="product.id"
                  :Id="product.id"
                  @updated="fetchProducts"
                  @deleted="fetchProducts"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./index.ts"></script>

<style>
.cls-1 {
  fill: #ec5251;
}
.cls-2 {
  fill: #eb5351;
}
</style>
