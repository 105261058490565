import { createApp } from "vue";
import App from "./App.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import * as ValidationProvider from "vee-validate";
import {
  faEye,
  faEyeSlash,
  faXmark,
  faCheck,
  fa1,
  fa2,
  fa3,
  fa4,
  fa5,
  faTrash,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import Notifications from "@kyvg/vue3-notification";
import VueLoading from "vue-loading-overlay";
import Multiselect from "vue-multiselect";
import vSelect from "vue-select";
// import "vue-select/dist/vue-select.css";
import "./registerServiceWorker";
import router from "./router";
import { store } from "./store";
import "./plugins/axios";
import "./assets/css/tailwind.css";
import "./assets/css/styles.scss";
import "./assets/css/styles.css";
import setupInterceptor from "./plugins/setupInterceptors";
import i18n from "./plugins/i18n";
import VOtpInput from "vue3-otp-input";

library.add(
  faEyeSlash,
  faEye,
  faXmark,
  faCheck,
  fa1,
  fa2,
  fa3,
  fa4,
  fa5,
  faTrash,
  faPenToSquare
);
setupInterceptor(store);
createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("vue-validation", ValidationProvider)
  .component("v-otp-input", VOtpInput)
  .component("v-select", vSelect)
  .component("multiselect", Multiselect)
  .use(i18n)
  .use(Notifications)
  .use(VueLoading)
  .use(store)
  .use(router)

  .mount("#app");
