
    import { defineComponent, onMounted, ref } from 'vue';
    import { AuthService } from '@/utils/auth/auth.service';
    import {apiProfile} from '@/utils/profile/profile.service'
    import {store} from '../store'
import { baseAPI } from '@/plugins/axios';

    const apiservice = new AuthService()
    const profile = ref([]) as any
    export default defineComponent ({
        name: 'PasswordModal',
        data: () => {
            return {
                Email: "",
                Phone: "",
                Password: "",
                OldPassword: "",
                EmailOtp: null,
                FirebaseJwt: "",
                RepeatPassword: "",
                hidden: 'vissible',
                hiddenB: 'vissible',
                hiddenC: 'vissible',
                oldpassword: 'password',
                password: 'password',
                newpassword: 'password',
                res: {} as any
            }
        },
         setup() {
            onMounted(async() => {
                const response = await baseAPI.get(
                    '/auth/profile',
                    {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${store.state.user.AccessToken}`
                    }
            }
                )
                profile.value= response.data
            });
            return profile
        },
        computed: {
            sortHiddenA () {
                return this.hidden === 'vissible' ? ['fas', 'eye'] : ['fas', 'eye-slash']
            },
            sortHiddenB () {
                return this.hiddenB === 'vissible' ? ['fas', 'eye'] : ['fas', 'eye-slash']
            },
            sortHiddenC () {
                return this.hiddenC === 'vissible' ? ['fas', 'eye'] : ['fas', 'eye-slash']
            }
        },
        methods: {
            hide () {
                this.$emit('Passclose')
            },
            async updatePassword () {
                console.log('hello', profile)
                let data = {
                    Email: profile.value.Email,
                    Phone: "",
                    Password: this.Password,
                    OldPassword: this.OldPassword,
                    EmailOtp: null,
                    FirebaseJwt: store.state.user.AccessToken,
                    RepeatPassword: this.RepeatPassword
                }
                console.log('data', data)
                try {
                    const response = await apiservice.updatePassword(data)
                    if (this.Password != this.RepeatPassword) {
                        const id = Date.now()
                        this.$notify({
                            id,
                            type: 'error',
                            duration: 10000,
                            text: 'Password Not Matched',
                        })                       
                    }
                    const id = Date.now()
                    this.$emit('Passclose')
                    this.$notify({
                        id,
                        type: 'success',
                        duration: 10000,
                        text: 'Password Updated Successfully'
                    })

                } catch (e:any) {
                    const id = Date.now()
                    this.$notify({
                        id,
                        type: 'error',
                        duration: 10000,
                        text: e.response.data.message,
                    })
                }
            },
            switchVisibility(type:any) {
                if (type === 'old') {
                    console.log('old')
                    this.hidden = this.hidden === 'vissible' ? 'hidden' : 'vissible'
                    this.oldpassword = this.oldpassword === "password" ? "text" : "password";
                } else if (type === 'current'){
                    this.hiddenB = this.hiddenB === 'vissible' ? 'hidden' : 'vissible'
                    this.password = this.password === "password" ? "text" : "password";
                } else if (type === 'new') {
                    this.hiddenC = this.hiddenC === 'vissible' ? 'hidden' : 'vissible'
                    this.newpassword = this.newpassword === "password" ? "text" : "password";
                }
            },
        },
    })
