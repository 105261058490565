import { baseAPI, authAPI } from "@/plugins/axios";
import { store } from "../../store";
import { UpdateEmailEntity } from "./update-email.entity";
import { UpdatePasswordEntity } from "./update-password.entity";
import { UpdatePhoneEntity } from "./update-phone.entity";

export class AuthService {
  async updateEmail(data: UpdateEmailEntity) {
    const response = await baseAPI.patch("/auth/change-email", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${store.state.user.AccessToken}`,
      },
    });
  }

  async updatePassword(data: UpdatePasswordEntity) {
    const response = await baseAPI.patch("/auth/update-password", data, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.state.user.AccessToken}`,
      },
    });
  }

  async updatePhone(FirebaseJwt: string, NewPhone: string) {
    const response = await baseAPI.post("/auth/verify-phone?isNew=true", {
      FirebaseJwt,
      NewPhone,
    });
  }

  async logout() {
    const response = await baseAPI.delete("/admin/logout");
    return response;
  }
}
