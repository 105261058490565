
import { authAPI } from "@/plugins/axios";
import { defineComponent, ref } from "vue";
import MobileMenu from "./MobileMenu.vue";
import LocaleSwitcher from "./Locale-Switcher.vue";
import { AuthService } from "@/utils/auth/auth.service";
import tokenService from "@/plugins/token.service";
import { apiProfile } from "../utils/profile/profile.service";
import { UserEntity } from "@/utils/user/user.entity";
import { store } from "@/store";

const authservice = new AuthService();
export default defineComponent({
  name: "AppBar",
  components: { MobileMenu },
  data: () => {
    return {
      response: {} as any,
      firstL: "",
      lastL: "",
      UserEntity,
    };
  },
  setup() {
    const menuToggleLangu = ref();
    const menuToggleNotify = ref();
    const menuToggleSetting = ref();
    const mobileMenu = ref();
    const showL = () => {
      menuToggleLangu.value = !menuToggleLangu.value;
      menuToggleNotify.value = false;
      menuToggleSetting.value = false;
    };
    const showN = () => {
      menuToggleNotify.value = !menuToggleNotify.value;
      menuToggleLangu.value = false;
      menuToggleSetting.value = false;
    };
    const showS = () => {
      menuToggleSetting.value = !menuToggleSetting.value;
      menuToggleLangu.value = false;
      menuToggleNotify.value = false;
    };
    const showMobileMenu = () => {
      mobileMenu.value = !mobileMenu.value;
      menuToggleSetting.value = false;
      menuToggleLangu.value = false;
      menuToggleNotify.value = false;
    };
    return {
      showL,
      showN,
      showS,
      showMobileMenu,
      menuToggleLangu,
      menuToggleNotify,
      menuToggleSetting,
      mobileMenu,
    };
  },
  methods: {
    async logout() {
      try {
        const respons = await authservice.logout();
        tokenService.removeUser();
        this.$router.push("/");
      } catch (e: any) {
        const id = Date.now();
        this.$notify({
          id,
          type: "error",
          duration: 10000,
          text: e.response.data.message,
        });
      }
    },
    async getProfile() {
      const response = await apiProfile.getProfile();
      this.response = response.data.data;
      console.log("User profile", response);
      // const userInLocalStorage: any = JSON.parse(localStorage.getItem("user"));
      // console.log("User", userInLocalStorage);
      //this.response = response.data.filter((user:UserEntity) => user.id === userInLocalStorage.)
    },
    getFirstLetter(string: any) {
      const str = new String(string);
      const firstL = str.charAt(0);
      return firstL;
    },
    closeMobileMenu() {
      this.mobileMenu = false;
    },
    close(e: any) {
      if (!this.$el.contains(e.target)) {
        this.menuToggleLangu = false;
        this.menuToggleNotify = false;
        this.menuToggleSetting = false;
        this.mobileMenu = false;
      }
    },
  },
  mounted() {
    this.getProfile();
    document.addEventListener("click", this.close);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.close);
  },
});
