import { baseAPI } from "@/plugins/axios";
import {
  OrderEntity,
  OrderFilterEntity,
  orderservice,
  StoreEntity,
  storeservice,
  StoreFilterEntity,
  PaginationEntity,
  userservice,
  UserFilterEntity,
  UserEntity,
} from "@/utils";
import { differenceInMinutes, quartersInYear } from "date-fns";
import { Helpers } from "@/helper";
import {
  PropType,
  defineComponent,
  ref,
  reactive,
  toRaw,
  onMounted,
  computed,
} from "vue";
import { store } from "@/store";
import Pagination from "@/components/shared/pagination/PaginationView.vue";
import BaseAutocomplete from "@/components/base/autocomplete/AutocompleteView.vue";
import { UserRoleWithOwner, OrderStatus, DeliveryType } from "@/globals/enums";
import { plainToClass } from "class-transformer";

export default defineComponent({
  name: "OrderView",
  components: { Helpers, BaseAutocomplete, Pagination },

  data: () => {
    return {
      helpers: Helpers,
      Pagination: {} as PaginationEntity,
      orders: [] as Array<OrderEntity>,
      store: [] as Array<StoreEntity>,
    };
  },

  setup(props) {
    const query = [] as any;
    const datas = ref([]);
    const orders = ref();
    const Total = null as any;
    const joinedByStoreId = ref();
    const Users = ref();
    const joinedByCustomerId = ref();

    const OrderFilter = new OrderFilterEntity({
      ...query,
      ...(!query.OrderBy ? { OrderDir: "DESC", OrderBy: "CreatedAt" } : {}),
    });
    // hold the opened orders
    (async () => {
      const [{ data: datas, data: Total }] = await Promise.all([
        orderservice.getAll(new OrderFilterEntity(OrderFilter)),
      ]);
      datas.value = datas;
      console.log("orders", datas.value);
    })();

    orders.value = datas;
    return {
      query,
      datas,
      OrderFilter,
      joinedByStoreId,
      joinedByCustomerId,
      Users,
    };
  },
  beforeMount() {
    const res = this.getOrders(this.OrderFilter);
    res.then((result) => {
      this.Pagination.TotalItems = result.Total as any;
      this.Pagination.CurrentPage = this.query.Page ? +this.query.Page : 1;
    });
    this.Pagination = plainToClass(PaginationEntity, this.Pagination);
    console.log("pa", this.Pagination);
  },
  mounted() {
    // this.getOrders(this.OrderFilter);
    // this.getStore();
    this.getUser();
  },
  methods: {
    async getOrders(filter: any) {
      const response = await orderservice.getAll(
        new OrderFilterEntity(this.OrderFilter)
      );
      this.orders = response.data.Data;
      // this.Pagination['TotalItems'] = response.data.Data.Total
      store.state.orders = this.orders;
      return response.data;
    },
    //    async getUsers() {
    //     const Users = await userservice.getAll(new UserFilterEntity({Limit:1000, Role: UserRoleWithOwner.CUSTOMER}))
    //     this.Users = Users.data
    //     return this.Users
    //    },
    getDeliverTime(PlacedAt: string, DeliveredAt: string | null) {
      if (DeliveredAt) {
        return this.helpers.numToHourMinSec(
          differenceInMinutes(new Date(DeliveredAt), new Date(PlacedAt))
        );
      }

      return this.$t("");
    },
    async getStore() {
      const response = await storeservice.getAll(
        new StoreFilterEntity({
          Limit: 10000,
        })
      );
      // join store by id
      const joinedByStoreId: any = response.data.Data.reduce(
        (group: any, da: any) => {
          const { Id } = da;
          group[Id] = group[Id] ?? [];
          group[Id].push(da);

          return group;
        },
        {}
      );
      this.joinedByStoreId = joinedByStoreId;
      return joinedByStoreId;
    },
    async getUser() {
      const response = await userservice.getAll(
        new UserFilterEntity({
          Limit: 10000,
          Role: UserRoleWithOwner.CUSTOMER,
        })
      );
      // join user by id
      const joinedByCustomerId = response.data.Data.reduce(
        (group: any, da: any) => {
          const { Id } = da;
          group[Id] = group[Id] ?? [];
          group[Id].push(da);
          return group;
        },
        {}
      );
      this.joinedByCustomerId = joinedByCustomerId;
      return joinedByCustomerId;
    },
    updateData(event: any) {
      const obj = reactive(event);
      const row = toRaw(obj);
      this.OrderFilter.StoreId = row.StoreId;
      this.OrderFilter.CustomerId = row.CustomerId;
      this.OrderFilter.DeliveryType = row.DeliveryType;
      this.OrderFilter.Status = row.Status;
      this.OrderFilter.Page = this.Pagination.CurrentPage;
      this.$router.replace({
        query: this.helpers.filterToQueryObj(
          this.Pagination as any,
          this.OrderFilter
        ),
      });
      this.getOrders(this.OrderFilter);
    },
  },
});
