
    import {defineComponent, computed, ref} from 'vue'
    import { OrderStatus } from '@/globals/enums'
    import { Helpers } from '@/helper'
    import { addMinutes } from 'date-fns';

    export default defineComponent({
        name: 'StepperStep',
        data: () => {
          return {
            helpers: Helpers,
            orderstatus: OrderStatus
          }
        },
        props: {
            tab: {
                type: Object,
                default: () => {
                    //
                }
            },
            item: {
              type: Object,
              default: () => {
                return {}
              }
            },
            time: {
              type: String,
            },
            index: {
                type: Number,
                default: 0
            },
            currentIndex: {
                type: Number,
                default: 0
            },
            squaredTab: {
                type: Boolean,
                default: false
            }
        },
        setup(props) {
            const progressActive = computed(() => props.currentIndex > props.index)
            const iconClass = computed(() => `fa-solid fa-${props.tab.icon}`)
            return {
                progressActive,
                iconClass
            }
        },
        methods: {
          getOrderStatus2ndValue(item: string) {
              if (item === 'Accepted') {
                console.log('accepted', item)
                if (this.tab.Status === OrderStatus.CANCELED) {
                  return OrderStatus.CANCELED;
                } else if (this.tab.Status === OrderStatus.CANCELED_AUTOMATICALLY) {
                  return OrderStatus.CANCELED_AUTOMATICALLY;
                } else if (this.tab.Status === OrderStatus.DECLINED) {
                  return OrderStatus.DECLINED;
                }
              }
              return this.helpers.slugify(item);
            },
            getTime(type: string) {
            const date = (this.item)[`${type.replace(/\s+/g, '')}At`] as string | null;
            const format = (d: string) => this.helpers.formatDate(new Date(d), 'kk:mm');
        
            if (type.toLowerCase() === OrderStatus.DELIVERED && !date && this.item.Status !== OrderStatus.CANCELED) {
              return `${this.$t('About')} ${format(
                addMinutes(new Date(), this.item.Store.DeliveryTime! as any).toISOString()
              )}`;
            }
            return date ? format(date) : ''
          },
        }
    })
