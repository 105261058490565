import { baseAPI } from "@/plugins";
import { defineComponent, ref, computed, toRaw } from "vue";
import { StoreUserRole, StoreStatus } from "@/globals/enums";
import { plainToClass } from "class-transformer";
import vSelect from "../../../../components/v-select/index.vue";
import action from "@/components/base/action/index.vue";
import storeAutocomplete from "@/components/shared/store/autocomplete/index.vue";
import memberModal from "@/components/shared/store/member/index.vue";
import AutoComplete from "@/components/AutoComplete.vue";
import {
  storeUser,
  StoreUserFilterEntity,
  PaginationEntity,
  StoreEntity,
  StoreUserEntity,
} from "@/utils";
import { Helpers } from "@/helper";
import { useRoute } from "vue-router";
import { store } from "../../../../store";

export default defineComponent({
  name: "member",
  components: { vSelect, action, memberModal, storeAutocomplete, AutoComplete },
  data: () => {
    return {
      SaveStoreUserMode: false,
      StoreUsers: [] as Array<StoreUserEntity>,
      SaveStoreUser: new StoreUserEntity(),
      Pagination: {} as PaginationEntity,
      stores: [] as Array<StoreEntity>,
      helpers: Helpers,
      totalHeight: null as any,
      isSelected: false as any,
      addMember: false as any,
      inputes: [] as any,
      closeIcon: false as any,
      selectedRole: "" as any,
      status: "" as any,
      selling: "" as any,
      store,
    };
  },
  setup() {
    const route = useRoute();
    const id = route.params.id;
    const query = [] as any;
    const StoreUser = ref();
    const saveStoreUser = ref();
    const total = ref();
    const Roles = ref([]) as any;
    const StoreUserFilter = new StoreUserFilterEntity({
      ...query,
      Limit: 100000,
      StoreId: id,
      ...(query.Roles ? { Roles: [query.Roles as StoreUserRole] } : {}),
    });

    const savestoreUser = new StoreUserFilterEntity({
      ...query,
      Limit: 1000,
      ...(query.Roles ? { Roles: [query.Roles as StoreUserRole] } : {}),
    });

    (async () => {
      // const [{ Data, Total }]: any = await Promise.all([
      //   (await storeUser.getAll(StoreUserFilter)).data,
      // ]);
      StoreUser.value = store.state.storeDetails.member;
      console.log("Store user", StoreUser.value);
      total.value = StoreUser.value.length;
    })();

    const StoreStatuses = computed(() => {
      return Helpers.enumToItems(StoreStatus);
    });

    Roles.value = Helpers.enumToItems(StoreUserRole);
    return {
      StoreUserFilter,
      query,
      id,
      StoreUser,
      total,
      StoreStatuses,
      StoreUserRole,
      savestoreUser,
      saveStoreUser,
      Roles,
    };
  },
  methods: {
    async getAllStoreUser(filtr: any) {
      const response = await (await storeUser.getAll(this.savestoreUser)).data;
      this.saveStoreUser = response;
    },
    async getStoreUser(filter: any) {
      const response = await (
        await storeUser.getAll(new StoreUserFilterEntity(filter))
      ).data;
      this.StoreUser = response.Data;
      return response;
    },
    addStoreUser(val: any) {
      this.StoreUser.push(val);
    },
    deleteItem(id: any) {
      this.StoreUser = this.StoreUser.filter((data: any) => data.Id !== id);
    },
    updateItem({ Id, Role }: any) {
      const id = this.StoreUser.findIndex((data: any) => data.Id == Id);
      console.log(id);
      this.StoreUser[id].Role = Role;
    },
    async editStoreUser(id: string) {
      try {
        this.SaveStoreUser = (await storeUser.getOne(id)) as any;
        this.SaveStoreUserMode = true;
      } catch (e) {
        const id = Date.now();
        this.$notify({
          id,
          type: "error",
          duration: 3000,
          text: e as any,
        });
      }
    },
    async deleteStoreUser(index: string) {
      try {
        await storeUser.delete(index);
        const id = Date.now();
        this.$notify({
          id,
          type: "success",
          duration: 3000,
          text: "deleted",
        });
      } catch (e) {
        const id = Date.now();
        this.$notify({
          id,
          type: "error",
          duration: 3000,
          text: e as any,
        });
      }
    },
    async sendInvitationAgain(index: string) {
      try {
        await storeUser.sendInvitationAgain(index);
        const id = Date.now();
        this.$notify({
          id,
          type: "success",
          duration: 3000,
          text: "Successfully sent!",
        });
      } catch (e) {
        const id = Date.now();
        this.$notify({
          id,
          type: "success",
          duration: 3000,
          text: "Successfully sent!",
        });
      }
    },
    async updateStatus(index: string, Status: StoreStatus) {
      const str = [] as any;
      let Stores: any;
      for (let i = 0; i < this.StoreUser.length; i++) {
        console.log("str", this.StoreUser[i]);
        str.push(toRaw(this.StoreUser[i].Store));
      }
      console.log("str2", str);
      const idx = str.find(({ Id }: any) => Id === index);
      console.log("idx", idx);
      try {
        await baseAPI.patch(`/admin/store/member/update/${index}`, { Status });
        if (idx > -1) {
          this.StoreUser[idx].Status = Status;
        }
        const id = Date.now();
        this.$notify({
          id,
          type: "success",
          duration: 3000,
          text: "updated",
        });
      } catch (err) {
        const id = Date.now();
        this.$notify({
          id,
          type: "error",
          duration: 3000,
          text: err as any,
        });
      }
    },
    toggleAddMember() {
      this.addMember = !this.addMember;
    },
    async close() {
      this.SaveStoreUserMode = false;
      this.closeIcon = !this.closeIcon;
    },
    updateData(val: any) {
      this.StoreUserFilter.Role = val?.id;
      this.$router.replace({
        query: this.helpers.filterToQueryObj(
          this.Pagination as any,
          this.StoreUserFilter
        ),
      });
      console.log(this.getStoreUser(this.StoreUserFilter));
    },
    getPosition() {
      const store = document.getElementById("member");
      this.totalHeight = ((store?.offsetHeight as any) +
        store?.offsetTop) as any;
    },
    addInputes(value: any) {
      this.inputes = [];
      this.inputes.push(value);
    },
    toggleSelect() {
      this.isSelected = !this.isSelected;
    },
  },
});
