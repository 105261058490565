import {Context} from 'vue'
import { singleton } from '@/helper';
import {Alert, AlertService} from './alert.service'
import {firebase} from '@/plugins'
import {notify} from "@kyvg/vue3-notification";

type Cb<T = void> = (value: string) => Promise<T>;
type Type = 'phone' | 'email';
const alert = new AlertService()
export type Otp = {
  show(value: string, type: Type, msg: string, sendOtpCb: Cb, verifyCb: Cb<string>): Promise<string | false>;
  resend(value: string): Promise<void>;
  verify(msg: string): Promise<void>;

  Seconds: number;
  Loading: boolean;
};

@singleton
export class OtpService implements Otp {

  context!: Context
  Message = '';
  Seconds = 0;
  Loading = false;

  private Type!: Type;
  private Value!: string;
  private sendOtpCb!: Cb;
  private verifyCb!: Cb<string>;
  private Countdown!: NodeJS.Timer;
  private _Mode = false;
  private resolve!: (val: string | false) => void;

  get Mode() {
    return this._Mode;
  }

  private async sendOtp(): Promise<boolean> {
    if (!this.Value) {
      return false;
    }

    this.Loading = true;
    try {
      await this.sendOtpCb(this.Value);
      const id = Date.now()
      notify({
        id,
        type: 'success',
        duration: 10000,
        text: `OTP sent to your ${this.Type === 'phone' ? 'phone number' : 'email'}`
      })
      console.log('before', this.Seconds)
      this.initCountdown()
      console.log('after', this.Seconds)
      return true;
    } catch (e) {
      const id = Date.now()
      notify({
        id,
        type: 'error',
        duration: 10000,
        text: (e as any)?.toString()?.replace('FirebaseError: Firebase: ', '')
      })
      this._Mode = false;
      return false;
    } finally {
      this.Loading = false;
      console.log(this.Loading)
    }
  }

  async show(value: string, type: Type, msg: string, sendOtpCb: Cb, verifyCb: Cb<string>) {
    this.Message = msg;
    this.Value = value;
    this.Type = type;
    this.sendOtpCb = sendOtpCb;
    this.verifyCb = verifyCb;

    type === 'phone' && firebase.initRecaptcha();
    this._Mode = true;
    console.log(this.Mode)
    const sent = await this.sendOtp();

    return new Promise<string | false>((resolve) => {
      this.resolve = resolve;
      !sent || !value ? this.resolve(false) : undefined;
    });
  }

  async verify(code: string) {
    this.Loading = true;

    try {
      console.log('verify')
      const token = await this.verifyCb(code);
      this.resolve(token);
      this._Mode = false;
    } catch (e) {
        const id = Date.now()
        notify({
          id,
          type: 'error',
          duration: 10000,
          text: (e as any).message?.includes('No user') ? (e as any) : 'OTP is wrong'
        })
    } finally {
      this.Loading = false;
      console.log(this.Loading)
    }
  }

  async resend() {
    this.Seconds;
    await this.sendOtp();
  }

  initCountdown() {
    this.Seconds = 120;
    this.Countdown = setInterval(() => {
      this.Seconds > 0 ? this.Seconds-- : clearInterval(this.Countdown);
      localStorage.setItem('timer', this.Seconds.toString())
      window.dispatchEvent(new Event("storage"));
    }, 1000);
  }

  cancel() {
    clearInterval(this.Countdown);
    this._Mode = false;
    console.log(this.Mode)
    // this.resolve(false);
  }
}
