import { defineComponent, onMounted, ref, onBeforeMount, computed } from "vue";
import { useRoute } from "vue-router";
import {
  OrderEntity,
  OrderFilterEntity,
  orderservice,
  OrderItemOptionEntity,
  OrderItemExtraEntity,
  ItemOptionExtraEntity,
  UserEntity,
  ChangeOrderStatusEntity,
} from "@/utils";
import { addMinutes } from "date-fns";
import { OrderStatus, Tab } from "@/globals/enums";
import CookView from "../../../components/shared/order/assign/cook/CookView.vue";
import DriverView from "../../../components/shared/order/assign/driver/DriverView.vue";
import StepperView from "../../../components/stepper/StepperView.vue";
import { Helpers } from "@/helper";
import { store } from "@/store";

export default defineComponent({
  name: "OrderDetailView",
  components: { Helpers, CookView, DriverView, StepperView },
  data: () => {
    return {
      orderstatus: OrderStatus,
      Order: {} as OrderEntity,
      Users: [] as Array<UserEntity>,
      Name: null || undefined,
      // Address: null || undefined,
      helpers: Helpers,
      assignCook: false,
      assignDriver: false,
      data: [] as Array<OrderEntity>,
      // currentTabIndex: 0 as any,
      extraIntity: {
        ItemExtraId: null as any,
        OrderItemId: null as any,
        Price: null as any,
      },
    };
  },
  props: {
    customTabs: {
      type: Array,
      default: () => [
        {
          id: 1,
          title: "Placed",
          icon: "check",
          checked: true,
        },
        {
          id: 2,
          title: "Accepted",
          icon: "check",
        },
        {
          id: 3,
          title: "Prepared",
          icon: "check",
        },
        {
          id: 4,
          title: "On the way",
          icon: "check",
        },
        {
          id: 5,
          title: "Delivered",
          icon: "check",
        },
      ],
    },
    startIndex: {
      type: Number,
      default: 0,
      validator: (value: number) => {
        return value >= 0;
      },
    },
    squaredTab: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const route = useRoute();
    const id = route.params.id as any;
    const result = ref({}) as any;

    (async () => {
      const [{ data: order, data: Total }] = await Promise.all([
        orderservice.getById(id),
      ]);
      store.state.order = order;
      result.value = order;
    })();

    return {
      id,
      result,
    };
  },
  computed: {
    Address(): any {
      const address = this.Order.Customer.address.find(
        ({ IsDefault }: any) => IsDefault
      );
      return address ? this.helpers.getAddress(address) : this.$t("na");
    },
    Street() {
      const address = this.Order.Customer.address.find(
        ({ IsDefault }: any) => IsDefault
      );
      return address ? this.helpers.getStreet(address) : this.$t("na");
    },
  },
  beforeMount() {
    this.getDetail();
  },
  mounted() {
    this.getDetail();
    this.data = store.state.orders;
  },
  methods: {
    getTime(type: string) {
      const date = (this.Order as any)[`${type.replace(/\s+/g, "")}At`] as
        | string
        | null;

      const format = (d: string) =>
        this.helpers.formatDate(new Date(d), "kk:mm");

      if (
        type.toLowerCase() === OrderStatus.DELIVERED &&
        !date &&
        this.Order.Status !== OrderStatus.CANCELED
      ) {
        return `${this.$t("i-order.about")} ${format(
          addMinutes(new Date(), this.Order.Store.deliveryTime!).toISOString()
        )}`;
      }
      return date ? format(date) : "";
    },
    getOrderStatusandValue(item: any) {
      if (item === "Accepted") {
        if (this.Order.Status === OrderStatus.CANCELED) {
          return OrderStatus.CANCELED;
        } else if (this.Order.Status === OrderStatus.CANCELED_AUTOMATICALLY) {
          return OrderStatus.CANCELED_AUTOMATICALLY;
        } else if (this.Order.Status === OrderStatus.DECLINED) {
          return OrderStatus.DECLINED;
        }
      }

      return this.helpers.slugify(item);
    },
    async getDetail() {
      const response = await orderservice.getById(this.id);
      store.state.order = response.data;
      this.result = store.state.order;
      this.Order = response.data;
    },
    // getItemOption(
    //   ItemOptions: Array<ItemOptionExtraEntity>,
    //   { ItemOptionId }: OrderItemOptionEntity
    // ) {
    //   return (
    //     ItemOptions.find(({ id }) => id === ItemOptionId)?.name + `${" "}` ?? ""
    //   );
    // },
    // getItemExtras(
    //   ItemExtras: Array<ItemOptionExtraEntity>,
    //   OrderItemExtras: Array<OrderItemExtraEntity>
    // ) {
    //   return OrderItemExtras.reduce((html, { ItemExtraId, Price }) => {
    //     const { name } = ItemExtras.find(({ id }) => id === ItemExtraId)!;
    //     html += `${"+"}${name}${"  "}(${this.helpers.toCurrency(
    //       Price!
    //     )})${","}${"     "}`;
    //     return html;
    //   }, "").slice(0, -6);
    // },
    closeCook() {
      this.assignCook = !this.assignCook;
    },
    closeDriver() {
      this.assignDriver = !this.assignDriver;
    },
    async changeOrderStatus() {
      try {
        const status: any = this.helpers.smallFirst(this.Order.Status);
        await orderservice.changeOrderStatus(
          new ChangeOrderStatusEntity({ Id: this.id, Status: status })
        );
        const id = Date.now();
        this.$notify({
          id,
          type: "success",
          duration: 2000,
          text: "Updated",
        });
      } catch (e) {
        const id = Date.now();
        this.$notify({
          id,
          type: "error",
          duration: 2000,
          text: e as any,
        });
      }
    },
    getTotal(option: any, extra: Array<OrderItemExtraEntity>) {
      const itemOption = option;
      let total = itemOption;
      for (let i = 0; i < extra.length; i++) {
        total += extra[i].Price;
      }
      return total;
    },
  },
});
