import { baseAPI } from "@/plugins";
import { defineComponent, ref, computed, toRaw } from "vue";
import { Component, Vue } from "vue-property-decorator";
import { plainToClass } from "class-transformer";
import { StoreStatus, ItemStatus } from "@/globals/enums";
import vselect from "@/components/v-select/index.vue";
import StoreAutocomplete from "@/components/shared/store/autocomplete/index.vue";
import AutoComplete from "@/components/AutoComplete.vue";
import {
  itemservice,
  ItemFilterEntity,
  ItemEntity,
  storeservice,
  StoreEntity,
  ItemOptionExtraEntity,
  PaginationEntity,
} from "@/utils";
import { useRoute } from "vue-router";
import { store } from "@/store";
import { Helpers } from "@/helper";
import { getSignedUrl } from "@/services/shared/image.service";
import action from "@/components/base/product_action/index.vue";

export default defineComponent({
  name: "Product",
  components: { vselect, StoreAutocomplete, AutoComplete, action },
  data: () => {
    return {
      lists: [] as any,
      helpers: Helpers,
      CollapsableItems: {} as any,
      collapsableHeight: false as any,
      displayCollapsable: "none" as any,
      Pagination: {} as PaginationEntity,
      refs: {
        collapsableRef: HTMLInputElement as any,
      },
    };
  },
  setup(props) {
    const route = useRoute();
    const Item = [] as Array<ItemEntity>;
    const query = [] as any;
    let Items = [] as Array<ItemEntity>;
    const Store = ref() as any;
    const id = route.params.id as any;
    const ItemFilter = new ItemFilterEntity({ ...query, Limit: 100000 });
    const productImage = ref() as any;
    (async () => {
      const [Store, { data: Data, Total }]: any = await Promise.all([
        storeservice.getAll(),
        itemservice.getAll(id, ItemFilter),
      ]);
      console.log("Items", Data);
      console.log("Store", Store);
      Store.value = Store;
      Items = Data;
      store.state.Store = Store.value;
    })();

    const StoreStatuses = computed(() => {
      console.log("storestatus", Helpers.enumToItems(StoreStatus));
      return Helpers.enumToItems(StoreStatus);
    });

    const ItemStatuses = computed(() => {
      return Helpers.enumToItems(ItemStatus);
    });
    return {
      id,
      Items,
      Store,
      ItemFilter,
      StoreStatuses,
      ItemStatuses,
      query,
      getSignedUrl,
    };
  },
  beforeMount() {
    this.Pagination = plainToClass(PaginationEntity, this.Pagination);
  },
  async mounted() {
    // this.Collapsable();
    // this.Store = store.state.storeDetails;
    // this.Store.product.forEach(async (element: any) => {
    //   console.log("Element image: ", element.photo);
    //   element.photo = await this.getSignedUrl(element.photo);
    //   console.log("Signed Element image: ", element.photo);
    // });
    await this.fetchProducts();
    console.log("Store details in product", this.Store);
  },
  methods: {
    getPrice(options: Array<ItemOptionExtraEntity>) {
      console.log("Options", options);
      if (options !== undefined) {
        const prices = options.map(({ price }) => price!);
        if (prices.length < 2) {
          return this.helpers.toCurrency(prices[0]);
        }
        return `${this.helpers.toCurrency(
          Math.min(...prices)
        )} - ${this.helpers.toCurrency(Math.max(...prices))}`;
      } else return 0;
    },
    async Collapsable() {
      const a = store.state.storeDetails.product;
      const items: Array<ItemEntity> = toRaw(a);

      this.CollapsableItems = items;
    },
    getCollapsableHeight() {
      this.collapsableHeight = !this.collapsableHeight;
      return this.collapsableHeight;
    },
    toggleCollapsable(itemIndex: string) {
      // const curE = [] as any;
      // let counter = 0;
      const element = [] as any;
      console.log("Collapsable items in toggle", this.Store.product);
      // Object.entries(this.CollapsableItems).map((e: any) => {
      //   for (let i = 0; i < toRaw(e[1].length); i++) {
      //     curE.push(this.$refs["body-" + toRaw(e[1][i].Id)]);
      //     console.log("Cure in loop", curE);
      //     element.push(document.getElementById(`body-${toRaw(e[1][i].Id)}`));
      //   }
      //   if (e[0] === listItem) {
      //     for (let i = counter; i < curE.length; i++) {
      //       counter += 1;
      //       if (element[i].style.display === "flex") {
      //         element[i].style.display = "none";
      //       } else {
      //         element[i].style.display = "flex";
      //       }
      //     }
      //   } else {
      //     for (let i = counter; i < curE.length; i++) {
      //       counter += 1;
      //       element[i].style.display = "none";
      //     }
      //   }
      // }, this);
    },
    async fetchProducts() {
      await storeservice.getAll().then((res) => {
        this.Store = res.data.data.find(
          (store: any) => store.id === this.$route.params.id
        );
      });
      console.log("store found", this.Store);
      this.Store.product.forEach(async (element: any) => {
        console.log("Element image: ", element.photo);
        element.photo = await this.getSignedUrl(element.photo);
        console.log("Signed Element image: ", element.photo);
      });
    },
    expandElement(listItem: any) {
      console.log("curE in expandElement");
      const curE: any = this.$refs["body-" + toRaw(listItem).Id];
      console.log("curE in expandElement", curE);
      if (curE === undefined) return false;
      if (curE.dataset?.isExpanded === "true") {
        return true;
      } else {
        return false;
      }
    },
    async updateStatus(index: string, Status: StoreStatus) {
      try {
        await baseAPI.patch(`/item/${index}/administration`, { Status });
        const id = Date.now();
        this.$notify({
          id,
          type: "success",
          duration: 3000,
          text: "updated",
        });
      } catch (err) {
        const id = Date.now();
        this.$notify({
          id,
          type: "error",
          duration: 3000,
          text: err as any,
        });
      }
    },
    updateData(type: any, event: any) {
      if (type === "selling") {
        this.ItemFilter.IsActive = event;
      } else if (type === "status") {
        this.ItemFilter.Status = event;
      }
      if (event === null || event === undefined) {
        this.ItemFilter.IsActive = event;
        this.ItemFilter.Status = event;
      }
      this.$router.replace({
        query: this.helpers.filterToQueryObj(
          this.Pagination as any,
          this.ItemFilter
        ),
      });
      // this.getStoreItems(this.id, this.ItemFilter);
      this.Collapsable();
    },
    async updateIsActive(index: string, IsActive: boolean) {
      const idx = this.Items.findIndex(({ id }) => id === index);
      try {
        await baseAPI.patch(`/item/${index}/update-is-active`, {
          IsActive,
        });
        if (idx > -1) {
          this.Items[idx].isActive = IsActive;
        }
        const id = Date.now();
        this.$notify({
          id,
          type: "success",
          duration: 3000,
          text: "Update",
        });
      } catch (err) {
        const id = Date.now();
        this.$notify({
          id,
          type: "error",
          duration: 3000,
          text: err as any,
        });
      }
    },
  },
});
