import { QrType } from "@/globals/enums";
import { baseAPI } from "@/plugins/axios";
import {
  CategoryEntity,
  PaginationResEntity,
  StoreEntity,
  StoreFilterEntity,
} from "@/utils";
import { Helpers } from "@/helper";
import { store } from "@/store";

class StoreApi {
  async getAll(filter?: StoreFilterEntity) {
    const respons = await baseAPI.get<PaginationResEntity<StoreEntity>>(
      `/admin/store/all?FromDashboard=true&${Helpers.filterToQueryString<
        StoreEntity,
        StoreFilterEntity
      >(filter)}`
    );
    return respons;
  }

  // async getById(id: string) {
  //   const response = await baseAPI.get<StoreEntity>(`/store/${id}`);
  //   return response;
  // }

  async updateCommission(id: string, commission: StoreEntity["commission"]) {
    console.log("Commission", commission);
    const { delivery, inStore, pickup } = commission;
    const res = await baseAPI.put(`/admin/store/commission/update/${id}`, {
      delivery,
      inStore,
      pickup,
    });

    console.log("Update commission response", res);

    return res;
  }

  async getQr(type: QrType, id: string) {
    return await baseAPI.get<{ Url: string }>(`qr/store/${id}`);
  }
  updateCategoriesSequence(
    id: string,
    ItemCategoriesSequence?: StoreEntity["itemCategoriesSequence"],
    TableCategoriesSequence?: StoreEntity["tableCategoriesSequence"]
  ) {
    return baseAPI.patch(`/store/${id}/categories-sequence`, {
      ItemCategoriesSequence,
      TableCategoriesSequence,
    });
  }
  update(cat: StoreEntity) {
    return baseAPI.put<StoreEntity>(`/admin/category/update/${cat.id}`, cat);
  }
  updateStore(store: StoreEntity | FormData | any, id: string) {
    return baseAPI.put<StoreEntity>(`/admin/store/update/${id}`, store);
  }
  add(newStore: FormData | any) {
    console.log("Create store", newStore);
    console.log("Create store ...", { ...newStore });
    return baseAPI.post<StoreEntity>(`/admin/store/create`, newStore);
  }
  addSlider(slider: FormData) {
    return baseAPI.post<StoreEntity>(`/admin/slider/create`, slider);
  }
}

export const storeservice = new StoreApi();
