import axios from "axios";
import { config } from "./config";
import VueAxios from "vue-axios";
import { createApp } from "vue";
import { store } from "../store";

const app = createApp({});
app.use(VueAxios, axios);

let token = "" as any;

if (store.state.user) {
  token = store.state.AccessToken;
}

export const authAPI = axios.create({
  baseURL: config.apiUrl,
});

export const baseAPI = axios.create({
  baseURL: config.apiUrl,
});
token = JSON.parse(localStorage.getItem("user") || "{}");
if (token === "{}") {
  token = store.state.AccessToken;
}

authAPI.defaults.headers.common["Content-Type"] = "application/json";
// authAPI.defaults.headers.common["access-token"] = store.state.user?.AccessToken;
authAPI.defaults.headers.common["access-token"] = token?.data?.accessToken;

baseAPI.defaults.headers.common["Content-Type"] = "application/json";
// baseAPI.defaults.headers.common["access-token"] = store.state.user?.AccessToken;
baseAPI.defaults.headers.common["access-token"] = token?.data?.accessToken;

app.config.globalProperties.$basicAPI = baseAPI;

app.config.globalProperties.$authAPI = authAPI;
