import { FilterEntity } from '../shared/filter.entity';
import { TableEntity } from './table.entity';

export class TableFilterEntity extends FilterEntity<TableEntity> {
  Name: string | null = null;
  IsActive: boolean | null = null;
  CategoryId: string | null = null;

  constructor(data?: Partial<TableFilterEntity>) {
    super();
    Object.assign(this, data);
  }
}
