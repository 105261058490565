export const user = {
    'creation-date': 'Creation Date',
    add: 'Add User',
    edit: 'Edit User',
    assignment: 'Assignment | Assignments',
    'add-assignment': 'Add Assignment',
    'blocked-stores': 'Blocked Stores',
    'change-phone-number': 'Change Phone Number',
    'change-email': 'Change Email',
    'change-email-text': 'After you change your Email, next time you need to login with the new one.'
  };
  