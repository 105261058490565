export class ResetPasswordEntity {
    Phone: string | null = null;
    Email: string | null = null;
    EmailOtp: string | null = null;
    FirebaseJwt: string | null = null;
  
    constructor(data?: Partial<ResetPasswordEntity>) {
      Object.assign(this, data);
    }
  }
  
  export class UpdatePasswordEntity extends ResetPasswordEntity {
    Password: string | null = null;
    OldPassword: string | null = null;
  
    constructor(data?: Partial<UpdatePasswordEntity>) {
      super(data);
      Object.assign(this, data);
    }
  }