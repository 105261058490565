import { ItemStatus } from '@/globals/enums';
import { FilterEntity } from '../shared/filter.entity';
import { ItemEntity } from './item.entity';

export class ItemFilterEntity extends FilterEntity<ItemEntity> {
  Name: string | null = null;
  IsActive: boolean | null = null;
  CategoryId: string | null = null;
  Status: ItemStatus | null = null;

  constructor(data?: Partial<ItemFilterEntity>) {
    super();
    Object.assign(this, data);
  }
}
