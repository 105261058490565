import { Component, Prop, Vue } from "vue-property-decorator";
import { defineComponent, computed, PropType, ref } from "vue";
import { MediaType } from "@/globals/enums";
import { config } from "@/plugins";
import BaseInput from "../input";
import { AnyObject } from "@/helper";
import { DeleteMediaEntity, mediaservice } from "@/utils";

export default defineComponent({
  extends: BaseInput,
  data: () => {
    return {
      refs: {
        fileInputRef: HTMLInputElement as any,
      },
      ImageLink: null as any,
      totalHeight: [] as any,
    };
  },
  props: {
    value: {
      type: String,
    },
    cover: {
      type: String,
    },
    fieldName: {
      type: String as PropType<string>,
      required: true,
    },
    mediaType: {
      type: String as PropType<MediaType>,
      required: true,
    },
    entityId: {
      type: String,
    },
  },
  setup(props) {
    const link = ref();
    return {
      link,
    };
  },
  mounted() {
    this.getImageLink(this.value);
  },
  methods: {
    getImageLink(value: any) {
      const file: File | string | null = value as any;
      this.link = value;
      if (!file) {
        this.ImageLink = null;
        return null;
      } else if (typeof file === "string") {
        this.ImageLink = `${config.mediaUrl}/${file}`;
        console.log(this.ImageLink);
        return `${config.mediaUrl}/${file}`;
      }
      console.log("Image link", this.ImageLink);
      console.log("File", file);
      console.log("URL", URL.createObjectURL(file));
      this.$emit("input", URL.createObjectURL(file));
      return URL.createObjectURL(file);
    },
    onUpload(e: AnyObject) {
      console.log("On upload", e);
      this.getImageLink(e.target.files[0]);
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.ImageLink = URL.createObjectURL(files[0]);
      this.$emit("input", files[0]);
      this.refs.fileInputRef.value = "";
    },
    async deleteImage() {
      const file: File | string | null = this.link;
      if (typeof file === "string") {
        try {
          await mediaservice.deleteImage(
            file as any,
            new DeleteMediaEntity<any>({
              Id: this.entityId as any,
              Type: this.mediaType,
              FieldName: this.fieldName,
            })
          );
          setTimeout(() => {
            this.$emit("input", null);
          }, 1000);
          const id = Date.now();
          this.$notify({
            id,
            type: "success",
            duration: 3000,
            text: "Deleted",
          });
          this.ImageLink = null;
        } catch (e) {
          const id = Date.now();
          this.$notify({
            id,
            type: "error",
            duration: 3000,
            text: e as any,
          });
        }
      } else {
        console.log("input");
        setTimeout(() => {
          this.$emit("input", null);
        }, 1000);
        this.getImageLink(null);
      }
    },
    getFile() {
      const fileUpload = document.getElementById("fileUpload");
      if (fileUpload !== null) {
        fileUpload.click();
      }
    },
  },
});
