import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fw-list-wrapper" }
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "fw-step-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("li", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["fw-list-progress", { 'fw-list-progress-active': _ctx.progressActive }])
    }, null, 2),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        id: `step-${_ctx.tab.id}`,
        class: _normalizeClass(["text-small fw-list-wrapper-icon", [
          {
            'fw-step-active': _ctx.tab.active,
            'fw-step-checked': _ctx.tab.checked,
            'fw-squared-tab': _ctx.squaredTab
          }
        ]]),
        role: "tab",
        style: _normalizeStyle(_ctx.tab.active || _ctx.tab.checked ? {'background':'#ed5151'} : {'background': '#e2e2e2'})
      }, [
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "active-step", {}, () => [
            (_ctx.tab.icon)
              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                  key: 0,
                  icon: `${_ctx.tab.checked ? _ctx.iconClass : _ctx.tab.id}`
                }, null, 8, ["icon"]))
              : (_openBlock(), _createBlock(_component_font_awesome_icon, { key: 1 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.index + 1), 1)
                  ]),
                  _: 1
                }))
          ])
        ])
      ], 14, _hoisted_2),
      _renderSlot(_ctx.$slots, "title", {}, () => [
        _createElementVNode("span", {
          class: _normalizeClass(["fw-step-title", {
            active: _ctx.tab.active
          }])
        }, _toDisplayString(_ctx.tab.title), 3)
      ])
    ]),
    _createElementVNode("div", null, _toDisplayString(_ctx.getTime(_ctx.tab.title)), 1)
  ]))
}